import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '..';

export const allInvestorSelector = createSelector(
  ({ allInvestors }: ApplicationState) => allInvestors,
  allInvestors =>
    of(allInvestors)
      .map(({ investors, loading }) => ({
        loading,
        investors: investors.map(investor => ({ value: investor }))
      }))
      .orElse(() => ({ loading: false, investors: [] }))
);
