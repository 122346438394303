import { dateDifferenceString } from '@rategravity/frontend/modules/date-difference';
import { currency } from '@rategravity/frontend/modules/numbers';
import fecha from 'fecha';
import { Advisors } from '../../redux/advisors/state';
import { RateQuote } from '../rate-quote/types';

export const computeSummary = (rqr: RateQuote['rateQuoteRequest'], advisors: Advisors) => {
  const { purpose, requester } = rqr;

  const summary = [['Type', purpose === 'purchase' ? 'Purchase' : 'Refinance']];

  switch (rqr.purpose) {
    case 'purchase':
      const { closeDate } = rqr;
      const propertyValue = rqr.purchasePrice;
      const acceptedOffer = rqr.acceptedOfferId;

      summary.push(
        ['Accepted Offer', acceptedOffer ? 'Yes' : 'No'],
        [
          'Close Date',
          closeDate
            ? `${fecha.format(new Date(closeDate), 'MM/DD/YY')} (${dateDifferenceString(
                Date.now(),
                closeDate
              )})`
            : ''
        ],
        ['Purchase Price', currency(propertyValue, 0)]
      );
      break;
    case 'refinance':
      const existingLoans = rqr.productSet[0].existingLoans;
      const primaryLoan = existingLoans.find(loan => loan.existingLoanType === 'Primary') || {
        amount: 0
      };
      summary.push([
        'Existing Primary',
        currency('amount' in primaryLoan ? primaryLoan.amount : 0, 0)
      ]);
      break;
  }

  summary.push([
    'Deal Owner',
    requester ? (advisors[requester] ? advisors[requester].name.full : requester) : ''
  ]);

  return summary;
};
