import { currency } from '@rategravity/frontend/modules/numbers';
import { RateQuote } from '../rate-quote/types';

export const computeAssumptions = (rqr: RateQuote['rateQuoteRequest']) => {
  const {
    property: {
      street,
      unit,
      city,
      state,
      zip,
      county,
      propertyType,
      warrantable,
      numberOfFloors,
      numberOfUnits,
      propertyTax,
      condoFee,
      insurance,
      occupancy
    }
  } = rqr;

  const address = street
    ? `${street}${unit ? ` ${unit}` : ''}, ${city}, ${state}${zip ? ` ${zip}` : ''}`
    : city
    ? `${city}, ${state}`
    : state;

  const isCondo = (propType: string) =>
    propType === 'Condo' ||
    propType === 'Condotel' ||
    propType === 'Coop' ||
    propType === 'PUD' ||
    propType === 'Townhome';

  let propertyValue = 0;

  switch (rqr.purpose) {
    case 'purchase':
      propertyValue = rqr.purchasePrice;
      break;
    case 'refinance':
      propertyValue = rqr.propertyValue;
      break;
  }

  return [
    ['Address', address],
    ['County', county],
    [
      'Type',
      `${
        propertyType === 'SingleFamily'
          ? 'Single Family'
          : propertyType === 'MultiFamily'
          ? 'Multi Family'
          : propertyType
      }`
    ],
    ...(isCondo(propertyType) ? [['Warrantable', !!warrantable ? 'True' : 'False']] : []),
    ...(propertyType === 'MultiFamily'
      ? [
          ['# of Floors', Number(numberOfFloors).toString()],
          ['# of Units', Number(numberOfUnits).toString()]
        ]
      : []),
    [
      'Occupancy',
      `${
        occupancy === 'PrimaryResidence'
          ? 'Primary Residence'
          : occupancy === 'SecondHome'
          ? 'Second Home'
          : 'Investment Property'
      }`
    ],
    ['Value', currency(propertyValue, 0)],
    ['RE Tax', currency(propertyTax, 0)],
    ['Insurance', currency(insurance, 0)],
    ['Condo/HOA', currency(condoFee, 0)]
  ];
};
