import * as idb from 'idb';

/**
 * Name of the IndexedDB used to store our data.
 *
 * Can be broken out in the future if needed, but one database and multiple storage
 *   areas is sufficient for current needs.
 */
export const DATABASE_NAME = 'OfferEntryDB';

// Fake-DB does not support versioning so we must override with version 1.
// Note: Make sure to increment the latter number when updating the schema
const DATABASE_VERSION: number = 6;

/**
 * Collection of IndexedDB storage area names utilized in the application.
 */
export enum LocalStoreType {
  OfferMerchandising = 'OfferMerchandising',
  OfferModals = 'OfferModals',
  ProductDetails = 'ProductDetails'
}

/**
 * Initializes all `LocalStoreType` stores when necessary.
 */
const initializeDataStores = (db: idb.IDBPDatabase) => {
  Object.values(LocalStoreType).forEach(x => {
    // Create any stores that don't already exist.
    if (!db.objectStoreNames.contains(x)) {
      db.createObjectStore(x);
      console.log(`Created ObjectStore: ${x}`);
    }
  });
};

/**
 * Attempts to retrieve a created IndexedDB storage area.
 *
 * @param storeType `LocalStoreType` to get.
 */
export const beginTransaction = () =>
  idb.openDB(DATABASE_NAME, DATABASE_VERSION, {
    upgrade: initializeDataStores
  });
