import { KIND, Notification } from 'baseui/notification';
import React from 'react';
import { OfferTableProps } from '.';

export const StatusNotification = ({ status }: Pick<OfferTableProps, 'status'>) => (
  <Notification
    overrides={{
      Body: {
        style: {
          minWidth: '35px',
          maxWidth: '70px',
          height: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          borderRadius: '3px'
        }
      }
    }}
    kind={status === 'Complete' ? KIND.positive : status === 'Stale' ? KIND.warning : KIND.negative}
  >
    {status}
  </Notification>
);
