import { of } from '@rategravity/core-lib/optional';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { AllQuotesDetailsModal } from '../components/offer-details/fee-modal/all-quotes-modal';
import { ApplicationState } from '../redux';
import { closeOfferDetails } from '../redux/offer-details/actions';
import {
  monthlyPaymentSelector,
  offerDetailsAttributesSelector,
  offerDetailsRateInfoSelector,
  offerDetailsUISelector
} from '../selectors/offer-details';

const allQuotesOfferNotesSelector = createSelector(
  ({ offerDetails: { modalOffer } }: ApplicationState) => modalOffer,
  modalOffer =>
    of(modalOffer)
      .map(({ notesAndAdvisories }) => notesAndAdvisories || [])
      .orElse(() => [])
);

const allQuotesOfferAdjustmentsSelector = createSelector(
  ({ offerDetails: { modalOffer } }: ApplicationState) => modalOffer,
  modalOffer =>
    of(modalOffer)
      .map(({ adjustments }) => adjustments || [])
      .orElse(() => [])
);

const allQuotesOfferDetailsLoadingSelector = createSelector(
  ({ allQuotes: { loadingProductDetails } }: ApplicationState) => loadingProductDetails,
  loadingProductDetails => loadingProductDetails
);

export const AllQuotesOfferDetailsModal = () => {
  const dispatch = useDispatch();
  const { isOpenAllQuotes, loading } = useSelector(offerDetailsUISelector);
  const loadingProductDetails = useSelector(allQuotesOfferDetailsLoadingSelector);
  const onClose = () => dispatch(closeOfferDetails());
  const fullAttributes = useSelector(offerDetailsAttributesSelector);
  const productDetails = useSelector(offerDetailsRateInfoSelector);
  const notesAndAdvisories = useSelector(allQuotesOfferNotesSelector);
  const adjustments = useSelector(allQuotesOfferAdjustmentsSelector);
  const monthlyPaymentData = useSelector(monthlyPaymentSelector);
  return (
    <AllQuotesDetailsModal
      isOpen={isOpenAllQuotes}
      loading={loading}
      loadingProductDetails={loadingProductDetails}
      onClose={onClose}
      fullAttributes={fullAttributes}
      productDetails={productDetails}
      notesAndAdvisories={notesAndAdvisories}
      adjustments={adjustments}
      feeTotal={fullAttributes.closingCost || 0}
      monthlyPaymentData={monthlyPaymentData}
      showPMI={false}
    />
  );
};
