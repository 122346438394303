import { toaster } from 'baseui/toast';
import {
  all,
  call,
  CallEffect,
  delay,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery
} from 'redux-saga/effects';
import {
  findLockBySource,
  FindLockBySourceResponse
} from '../../../modules/find-locked-offer-by-source';
import { synchWithMerchandisingCache } from '../../../modules/offer-details/local-storage';
import { fetchRateQuote, FetchRateQuoteResult } from '../../../modules/rate-quote';
import {
  MerchandisingPayload,
  queueMerchandisingSave,
  saveMerchandising
} from '../../../modules/rate-quote/merchandising';
import { StandardizedResponse } from '../../../modules/standardize';
import { merchandisingPayloadSelector } from '../../../selectors/offer';
import { rateQuoteIdSelector } from '../../../selectors/rate-quote/rate-quote';
import { rateQuoteTitleSelector } from '../../../selectors/rate-quote/rate-quote-title';
import { getAllInvestors } from '../../all-investors/slice';
import { fetchDeals } from '../../deals/actions';
import {
  clearMerchandisingFromCache,
  FetchLockedOfferBySourceAction,
  FetchLockedOfferBySourceActionType,
  fetchLockedOfferBySourceFailure,
  fetchLockedOfferBySourceSuccess,
  FetchRateQuoteActionType,
  fetchRateQuoteError,
  FetchRateQuoteFailureAction,
  FetchRateQuoteFailureActionType,
  FetchRateQuoteRequestAction,
  fetchRateQuoteSuccess,
  FetchRateQuoteSuccessActionType,
  SaveMerchandisingAction,
  SaveMerchandisingActionType,
  saveMerchandisingError,
  SaveMerchandisingErrorAction,
  SaveMerchandisingErrorActionType,
  saveMerchandisingSuccess,
  synchedMerchandisingFromCache
} from '../actions';

const MAX_RETRIES = 49;
const INITIAL_DELAY = 500;
const MAX_DELAY = 20000;

export function* handleFetchRequest({
  payload: { rateQuoteId, fetchAllRates }
}: FetchRateQuoteRequestAction): Generator<CallEffect | PutEffect> {
  for (let i = 1; i <= MAX_RETRIES; i++) {
    try {
      const res = yield call(fetchRateQuote, rateQuoteId);
      const { success, error, json, code } = res as FetchRateQuoteResult;
      if (success) {
        // Check if there's cached data that wants to be restored.
        const cacheRes = yield call(synchWithMerchandisingCache, json!);
        const [rateQuote, mergeFromCache] = cacheRes as Iterable<any>;
        // Trigger action if merged from cache.
        if (mergeFromCache) {
          yield put(synchedMerchandisingFromCache());
        } else {
          yield put(clearMerchandisingFromCache(rateQuote.rateQuoteId));
        }
        yield put(fetchRateQuoteSuccess(rateQuote));
        yield put(fetchDeals(rateQuote.rateQuoteRequest.sourceId));
        if (rateQuote.status === 'Loading') {
          if (i === MAX_RETRIES) {
            yield put(
              fetchRateQuoteError(
                'Error retrieving automated rates. Please re-load the page and try again',
                504 // timeout
              )
            );
            break;
          }
          yield delay(Math.min(INITIAL_DELAY * Math.pow(2, i), MAX_DELAY));
        } else {
          if (fetchAllRates) {
            yield put(getAllInvestors({ rateQuoteRequestId: rateQuote.rateQuoteRequestId }));
          }
          break;
        }
      } else {
        yield put(fetchRateQuoteError(error!, code));
      }
    } catch (err) {
      yield put(fetchRateQuoteError(err.message, 500));
    }
  }
}

export function* handleFetchRequestSuccess(): Generator<SelectEffect> {
  const title = yield select(rateQuoteTitleSelector);

  // Update the document title.
  (window.document.title as unknown) = title;
}

function* handleFetchRequestFailure({ payload: { message } }: FetchRateQuoteFailureAction) {
  yield call(() => toaster.negative(`Error fetching rate quote: ${message}`, { closeable: true }));
}

function* handleSaveMerchandising({ payload: saveImmediately }: SaveMerchandisingAction) {
  if (saveImmediately) {
    const rateQuoteId: string = yield select(rateQuoteIdSelector);
    const merchandisingSet: MerchandisingPayload = yield select(merchandisingPayloadSelector);
    const { success, error, json } = yield call(saveMerchandising, rateQuoteId, merchandisingSet);
    if (success) {
      yield put(saveMerchandisingSuccess(json!));
    } else {
      yield put(saveMerchandisingError(error!));
    }
  } else {
    yield call(queueMerchandisingSave);
  }
}

function* handleSaveMerchandisingError({ payload }: SaveMerchandisingErrorAction) {
  yield call(() =>
    toaster.negative(`Error saving merchandising to server: ${payload}`, { autoHideDuration: 5000 })
  );
}

function* handleFetchLockedOfferBySource({
  payload: { sourceId, history }
}: FetchLockedOfferBySourceAction): Generator<CallEffect | PutEffect> {
  try {
    const lockedOffer = yield call(findLockBySource, sourceId);
    const { success, json, error, code } = lockedOffer as StandardizedResponse<
      FindLockBySourceResponse
    >;
    if (success && json) {
      if (json.hasLock && json.rateQuoteId) {
        yield put(
          fetchLockedOfferBySourceSuccess({
            status: 'completed',
            rateQuoteId: json.rateQuoteId
          })
        );
        history.push(`${json.rateQuoteId}`);
        return;
      }
      if (!json.hasLock) {
        yield put(
          fetchLockedOfferBySourceFailure({
            status: 'failed',
            error:
              'The current RateQuote has a locked offer that does not exist in any RateQuote on this source'
          })
        );
        return;
      }
    }
    if (code) {
      yield put(
        fetchLockedOfferBySourceFailure({
          status: 'failed',
          error,
          code
        })
      );
      return;
    }
  } catch (error) {
    yield put(
      fetchLockedOfferBySourceFailure({
        status: 'failed',
        error
      })
    );
  }
}

export const rateQuotesSaga = function*() {
  yield all([
    takeEvery(FetchRateQuoteActionType, handleFetchRequest),
    takeEvery(FetchRateQuoteSuccessActionType, handleFetchRequestSuccess),
    takeEvery(FetchRateQuoteFailureActionType, handleFetchRequestFailure),
    takeEvery(SaveMerchandisingActionType, handleSaveMerchandising),
    takeEvery(SaveMerchandisingErrorActionType, handleSaveMerchandisingError),
    takeEvery(FetchLockedOfferBySourceActionType, handleFetchLockedOfferBySource)
  ]);
};
