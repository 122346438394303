import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../redux';

export const rateQuoteStatusLoading = createSelector(
  ({ rateQuote }: ApplicationState) => rateQuote,
  quote =>
    of(quote)
      .map(({ rateQuote }) => !!rateQuote && rateQuote.status === 'Loading')
      .orElse(() => false)
);
