import { currency } from '@rategravity/frontend/modules/numbers';
import React from 'react';
import { EditableOffer, Errorable } from '../../../redux/offer-details/state';
import { NumberInput } from '../../forms/input';
import { CopyPMIButton } from '../fee-modal/copy-pmi-button';
import { BaseCard } from './shared';

interface MonthlyPaymentCardParams {
  data: {
    principalAndInterest: number;
    monthlyInsurance: number;
    monthlyTaxes: number;
    miReadOnly: boolean;
  } & Errorable<{ pmi?: number }>;
  onChange?: (payload: EditableOffer['monthlyPayment']) => void;
  showPMI: boolean;
  setCopyPMI?: () => void;
}

export const MonthlyPaymentCard = ({
  data: { principalAndInterest, monthlyInsurance, monthlyTaxes, pmi, miReadOnly },
  onChange,
  showPMI,
  setCopyPMI
}: MonthlyPaymentCardParams) => (
  <BaseCard
    title="Monthly payment"
    subtitle={currency(
      principalAndInterest + monthlyInsurance + monthlyTaxes + ((pmi && pmi.value) || 0),
      0
    )}
  >
    <NumberInput key="P&I" label="P&I" inputType="money" disabled value={principalAndInterest} />
    <NumberInput
      key="Monthly Insurance"
      label="Monthly Insurance"
      inputType="money"
      disabled
      value={monthlyInsurance}
    />
    <NumberInput
      key="Monthly Taxes"
      label="Monthly Taxes"
      inputType="money"
      disabled
      value={monthlyTaxes}
    />
    {showPMI ? (
      <NumberInput
        key="PMI"
        // show MI when calculated on backend for FHA loans
        label={miReadOnly ? 'MI' : 'PMI'}
        inputType="money"
        disabled={miReadOnly}
        onChange={({ value }) => onChange && onChange({ pmi: value })}
        value={pmi && pmi.value}
        error={pmi && pmi.error}
      />
    ) : null}
    {showPMI && setCopyPMI ? <CopyPMIButton setCopyPMI={setCopyPMI} /> : null}
  </BaseCard>
);
