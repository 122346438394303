import Conditional from '@rategravity/frontend/components/conditional';
import { styled, withStyle } from 'baseui';
import { ModalBody } from 'baseui/modal';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import {
  EditableFees,
  EditableOffer,
  Errorable,
  OfferDetailsState
} from '../../../redux/offer-details/state';
import { PrimaryButton, SecondaryButton } from '../../buttons';
import { Modal } from '../../modal';
import { Notice } from '../../notice';
import { FeeCard } from '../card/fee-card';
import { MonthlyPaymentCard } from '../card/monthly-payment-card';
import { OfferDetailsRateInfo, RateInfoCard } from '../card/rate-info-card';
import { AttributesPanel, FullAttributes } from './attributes-panel';
import { CopyPMINotice } from './copy-pmi-notice';

const Flex = withStyle(ModalBody, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  marginTop: '10px',
  marginBottom: '10px',
  marginLeft: '10px',
  marginRight: '10px'
});

const FeesContainer = styled('div', {
  width: '100%',
  overflow: 'hidden'
});

export interface DetailsModalProps extends Omit<OfferDetailsState, 'modalOffer'> {
  closingCost: Errorable<EditableFees>;
  prepaid: Errorable<EditableFees>;
  other: Errorable<EditableFees>;
  validationError: boolean;
  fullAttributes: FullAttributes;
  updateFee: (payload: { id: string; value: number | null | undefined }) => void;
  addOffer: () => void;
  productDetails: OfferDetailsRateInfo;
  updateRateInfo: (payload: Partial<EditableOffer['productDetails']>) => void;
  monthlyPaymentData: {
    principalAndInterest: number;
    monthlyInsurance: number;
    monthlyTaxes: number;
    miReadOnly: boolean;
  };
  updateMonthlyPayment: (payload: EditableOffer['monthlyPayment']) => void;
  showPMI: boolean;
  onClose: () => void;
  canEdit: boolean;
  sellerConcessions?: number | null;
  updatePriceField: (payload: { price: number | undefined }) => void;
  setCopyPMIValue: () => void;
  copyPMIValue: boolean;
  numManualPMIOffers: number;
}

export const DetailsModal = ({
  closingCost,
  prepaid,
  other,
  fullAttributes,
  validationError,
  networkError,
  isOpen,
  loading,
  updateFee,
  addOffer,
  productDetails,
  updateRateInfo,
  monthlyPaymentData,
  updateMonthlyPayment,
  showPMI,
  onClose,
  canEdit,
  sellerConcessions,
  updatePriceField,
  setCopyPMIValue,
  copyPMIValue,
  numManualPMIOffers
}: DetailsModalProps) => {
  const [modalRef, setModalRef] = useState(null);
  const [scrollToTop, setScroll] = useState(true);
  const setModalRefCallback = useCallback(ref => {
    setModalRef(ref);
  }, []);

  useLayoutEffect(() => {
    // Scroll modal to top if errors were found or modal is opened
    if (isOpen && modalRef) {
      let parentModal: any = modalRef;
      while (!parentModal.dataset.baseweb) {
        parentModal = parentModal.parentNode;
      }
      parentModal.scrollTop = 0;
      setScroll(false);
    }
  }, [isOpen, validationError, modalRef, scrollToTop]);

  return (
    <Modal
      isOpen={isOpen}
      title="Offer details"
      loading={loading}
      onClose={() => onClose()}
      buttons={[
        <SecondaryButton key={1} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>,
        <PrimaryButton
          key={2}
          disabled={!canEdit}
          onClick={() => {
            // TODO: typing from mergeProps is wrong
            if (!(addOffer() as any)) {
              setScroll(true);
            }
          }}
        >
          Done
        </PrimaryButton>
      ]}
      layout={'Regular'}
    >
      <div ref={setModalRefCallback} />
      <Flex>
        <AttributesPanel fullAttributes={fullAttributes} />
        <FeesContainer>
          <Conditional show={validationError}>
            <Notice message={'Please complete the highlighted fields below.'} />
          </Conditional>
          <Conditional show={networkError !== null}>
            <Notice message={'Network Error. Contact Administrator'} />
          </Conditional>
          <RateInfoCard
            isFixed={fullAttributes.product.rate === 'fixed'}
            data={productDetails}
            onChange={updateRateInfo}
            updatePriceField={updatePriceField}
          />
          <MonthlyPaymentCard
            data={monthlyPaymentData}
            onChange={updateMonthlyPayment}
            showPMI={showPMI}
            setCopyPMI={setCopyPMIValue}
          />
          <FeeCard
            title="Closing costs"
            fees={closingCost}
            updateFee={updateFee}
            sellerConcessions={sellerConcessions}
          />
          <FeeCard title="Other fees" fees={other} updateFee={updateFee} />
          <FeeCard title="Prepaids" fees={prepaid} updateFee={updateFee} />
          <CopyPMINotice numOfOffers={numManualPMIOffers} copyPMIValue={copyPMIValue} />
        </FeesContainer>
      </Flex>
    </Modal>
  );
};
