import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  FetchDealsAction,
  FetchDealsActionType,
  FetchDealsFailureAction,
  FetchDealsFailureActionType,
  FetchDealsSuccessAction,
  FetchDealsSuccessActionType
} from './actions';
import { DealsState, DefaultDealsState } from './state';

const handleFetchDealsAction = (state: DealsState, _: FetchDealsAction): DealsState => ({
  ...state,
  loading: true,
  error: null
});

const handleFetchDealsSuccessAction = (
  state: DealsState,
  { payload }: FetchDealsSuccessAction
): DealsState => ({ deals: payload, loading: false, error: null });

const handleFetchDealsFailureAction = (
  state: DealsState,
  { payload }: FetchDealsFailureAction
): DealsState => ({
  ...state,
  loading: false,
  error: `Error fetching deals: ${payload}`
});

export const dealsReducer = composeReducers(
  withDefault(DefaultDealsState),
  ofType(FetchDealsSuccessActionType, handleFetchDealsSuccessAction),
  ofType(FetchDealsFailureActionType, handleFetchDealsFailureAction),
  ofType(FetchDealsActionType, handleFetchDealsAction)
);
