import { styled } from 'baseui';
import { Spinner } from 'baseui/spinner';
import React from 'react';

import { H3 } from '../headers';

export interface ProgressiveLoadProps {
  loading: boolean;
}

const Container = styled('div', () => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  'z-index': 1000,
  'background-color': '#ffffff80'
}));

export const ProgressiveLoadOverlay = ({ loading }: ProgressiveLoadProps) => {
  if (loading) {
    return (
      <Container>
        <div>
          <H3>Waiting for AutoQuotes...</H3>
          <Spinner size={192} />
        </div>
      </Container>
    );
  }
  return null;
};
