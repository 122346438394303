import { AllQuotesOffer, Offer, OfferPermutation } from '../../../modules/rate-quote/types';

export const CreateOfferActionType = 'RATE_QUOTE__CREATE_OFFER_REQUEST_ACTION' as const;
export const createOfferRequest = (payload: {
  rateQuoteId: string;
  offer: Offer | AllQuotesOffer;
  copyPMIValue: boolean;
}) => ({
  type: CreateOfferActionType,
  payload
});
export type CreateOfferRequestAction = ReturnType<typeof createOfferRequest>;

export const CreateOfferSuccessActionType = 'RATE_QUOTE__CREATE_OFFER_SUCCESS_ACTION' as const;
export const createOfferSuccess = (offer: Offer, offerPermutations: OfferPermutation[]) => ({
  type: CreateOfferSuccessActionType,
  payload: {
    offer,
    offerPermutations
  }
});
export type CreateOfferSuccessAction = ReturnType<typeof createOfferSuccess>;

export const CreateOfferFailureActionType = 'RATE_QUOTE__CREATE_OFFER_FAILURE_ACTION' as const;
export const createOfferError = (payload: string) => ({
  type: CreateOfferFailureActionType,
  payload
});
export type CreateOfferFailureAction = ReturnType<typeof createOfferError>;

export const ReorderOffersActionType = 'RATE_QUOTE__REORDER_OFFER_ACTION' as const;
export const reorderOffers = (oldIndex: number, newIndex: number) => ({
  type: ReorderOffersActionType,
  payload: {
    oldIndex,
    newIndex
  }
});
export type ReorderOffersAction = ReturnType<typeof reorderOffers>;

export const MerchandiseOfferActionType = 'RATE_QUOTE__MERCHANDISE_OFFER_ACTION' as const;
export const merchandiseOffer = (payload: string) => ({
  type: MerchandiseOfferActionType,
  payload
});
export type MerchandiseOfferAction = ReturnType<typeof merchandiseOffer>;

export const UnmerchandiseOfferActionType = 'RATE_QUOTE__UNMERCHANDISE_OFFER_ACTION' as const;
export const unmerchandiseOffer = (offerId: string) => ({
  type: UnmerchandiseOfferActionType,
  payload: offerId
});
export type UnmerchandiseOfferAction = ReturnType<typeof unmerchandiseOffer>;

export const UpdateMerchandisingActionType = 'RATE_QUOTE__UPDATE_MERCHANDISING_ACTION' as const;
export const updateMerchandising = (payload: string[]) => ({
  type: UpdateMerchandisingActionType,
  payload
});
export type UpdateMerchandisingAction = ReturnType<typeof updateMerchandising>;

export const SynchronizeMerchandisingCacheActionType = 'RATE_QUOTE__SYNCHRONIZE_MERCHANDISING_CACHE_ACTION' as const;
export const synchronizeMerchandisingCache = () => ({
  type: SynchronizeMerchandisingCacheActionType
});
export type SynchronizeMerchandisingCacheAction = ReturnType<typeof synchronizeMerchandisingCache>;

export const ClearMerchandisingFromCacheActionType = 'RATE_QUOTE__CLEAR_MERCHANDISING_FROM_CACHE_ACTION' as const;
export const clearMerchandisingFromCache = (rateQuoteId: string) => ({
  type: SynchronizedMerchandisingFromCacheActionType,
  payload: rateQuoteId
});
export type ClearMerchandisingFromCacheAction = ReturnType<typeof clearMerchandisingFromCache>;

export const SynchronizedMerchandisingFromCacheActionType = 'RATE_QUOTE__SYNCHRONIZED_MERCHANDISING_FROM_CACHE_ACTION' as const;
export const synchedMerchandisingFromCache = () => ({
  type: SynchronizedMerchandisingFromCacheActionType
});
export type SynchronizedMerchandisingFromCacheAction = ReturnType<
  typeof synchedMerchandisingFromCache
>;

export const StoreOfferActionType = 'RATE_QUOTE__STORE_OFFER_ACTION' as const;

export const storeOffer = (offerId: string, offerData: Partial<Offer>) => ({
  type: StoreOfferActionType,
  payload: {
    offerId,
    offerData
  }
});
export type StoreOfferAction = ReturnType<typeof storeOffer>;

export const SetLockedOfferActionType = 'RATE_QUOTE__SET_LOCKED_OFFER_ACTION' as const;
export const setLockedOffer = (offerId: string, locked: boolean) => ({
  type: SetLockedOfferActionType,
  payload: {
    offerId,
    locked
  }
});
export type SetLockedOfferAction = ReturnType<typeof setLockedOffer>;

export const UpdateOfferActionType = 'RATE_QUOTE__UPDATE_OFFER_ACTION' as const;

export const updateOffer = (
  rateQuoteId: string,
  offerData: Partial<Offer>,
  copyPMIValue: boolean
) => ({
  type: UpdateOfferActionType,
  payload: {
    rateQuoteId,
    offerData,
    copyPMIValue
  }
});
export type UpdateOfferAction = ReturnType<typeof updateOffer>;

export const UpdateOfferSuccessActionType = 'RATE_QUOTE__UPDATE_OFFER_SUCCESS_ACTION' as const;
export const updateOfferSuccess = () => ({
  type: UpdateOfferSuccessActionType
});
export type UpdateOfferSuccessAction = ReturnType<typeof updateOfferSuccess>;

export const UpdateOfferFailureActionType = 'RATE_QUOTE__UPDATE_OFFER_FAILURE_ACTION' as const;
export const updateOfferError = (payload: string) => ({
  type: UpdateOfferFailureActionType,
  payload
});
export type UpdateOfferFailureAction = ReturnType<typeof updateOfferError>;
