"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function monthlyPMT(rate, payments, presentValue, futureValue = 0) {
    return PMT(rate / 1200, payments * 12, presentValue, futureValue);
}
exports.monthlyPMT = monthlyPMT;
function PMT(rate, payments, presentValue, futureValue) {
    const rExp = Math.pow(1 + rate, payments);
    return (rate * (futureValue + presentValue * rExp)) / (rExp - 1);
}
exports.PMT = PMT;
function monthlyFV(rate, payments, presentValue, nper) {
    return FV(rate / 1200, payments, presentValue, nper * 12);
}
exports.monthlyFV = monthlyFV;
function FV(rate, pmt, pv, nper) {
    const pow = Math.pow(1 + rate, nper);
    if (rate) {
        return (pmt * (1 - pow)) / rate + pv * pow;
    }
    return pv - pmt * nper;
}
exports.FV = FV;
