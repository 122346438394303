import { none, Optional, some } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { getFromAdditionalContext } from '../../modules/compute-rate-quote-request-display';
import { rateQuoteRequestSelector } from '../rate-quote-request';

export const rateQuoteTitleSelector = createSelector(rateQuoteRequestSelector, request =>
  request
    .flatMap(
      ({ additionalContext }): Optional<string> => {
        const contextValue = getFromAdditionalContext(additionalContext, 'name');
        if (contextValue) {
          return some(contextValue);
        }
        return none();
      }
    )
    .orElse(() => 'Unknown Scenario')
);
