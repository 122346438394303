import { Card } from 'baseui/card';
import { Table } from 'baseui/table';
import React from 'react';
import { Spinner } from '../../spinner';
import { cardOverrides, CardText, CardTitle, DetailsContainer } from './shared';

export type NotesAndAdvisories = string[];

export type Adjustments = {
  reason: string;
  adjustor: string;
  type: string;
}[];

export const NotesAdvisoriesAdjustmentsCard = ({
  loading,
  notesAndAdvisories,
  adjustments
}: {
  loading: boolean;
  notesAndAdvisories: NotesAndAdvisories;
  adjustments: Adjustments;
}) => {
  const title = 'Notes, Advisories, and Adjustments';
  const adjustmentsTableData = adjustments.map(({ type, adjustor, reason }) => [
    type,
    adjustor,
    reason
  ]);
  return (
    <Card
      title={title}
      overrides={{
        Title: {
          component: CardTitle,
          props: { title }
        },
        ...cardOverrides
      }}
    >
      <DetailsContainer>
        {loading ? (
          <Spinner size={60} centerVertically={false} />
        ) : (
          <>
            {notesAndAdvisories.length ? (
              <ul>
                {notesAndAdvisories.map((n, i) => (
                  <li key={i}>
                    <CardText>{n}</CardText>
                  </li>
                ))}
              </ul>
            ) : (
              <CardText>No Notes & Advisories</CardText>
            )}
            {adjustments.length ? (
              <Table data={adjustmentsTableData} columns={['Type', 'Adjustor', 'Reason']} />
            ) : (
              <CardText>No Adjustments</CardText>
            )}
          </>
        )}
      </DetailsContainer>
    </Card>
  );
};
