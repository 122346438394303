import { Offer } from '../../modules/rate-quote/types';
import { Product } from '../../modules/value-types/quote';

export interface LockData {
  loanId: string;
  rgTitle: boolean | undefined;
  attorney?: string;
  provider?: string;
  notes: string;
  lockId: string;
  float: boolean;
}

export type OfferData = Pick<Offer, 'offerId' | 'lender' | 'fees' | 'locked'> & {
  rate: number;
  product: Product;
};

export interface LockModalState {
  modalLock: LockData | null;
  offer: OfferData | null;
  isOpen: boolean;
  link: string | null;
  loading: boolean;
  networkError: string | null;
  updatedHubspot: boolean;
}

export const DefaultLockModalState: LockModalState = {
  modalLock: null,
  offer: null,
  isOpen: false,
  link: null,
  loading: false,
  networkError: null,
  updatedHubspot: false
};
