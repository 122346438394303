import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  FetchAdvisorsFailureAction,
  FetchAdvisorsFailureActionType,
  FetchAdvisorsSuccessAction,
  FetchAdvisorsSuccessActionType
} from './actions';
import { AdvisorState } from './state';

const handleFetchAdvisorsSuccess = (
  state: AdvisorState,
  { payload }: FetchAdvisorsSuccessAction
): AdvisorState => ({
  ...state,
  advisors: payload,
  loading: false,
  error: null
});

const handleFetchAdvisorsFailure = (
  state: AdvisorState,
  { error }: FetchAdvisorsFailureAction
): AdvisorState => ({
  ...state,
  loading: false,
  error
});

export const advisorsReducer = composeReducers(
  withDefault({ advisors: {}, loading: true, error: null } as AdvisorState),
  ofType(FetchAdvisorsSuccessActionType, handleFetchAdvisorsSuccess),
  ofType(FetchAdvisorsFailureActionType, handleFetchAdvisorsFailure)
);
