// Optionals
// Optionals present an "array like" interface (map, filter, find, some) however
// they effectively have either 1 (Some) or 0 (None) elements.
// Optionals are highly useful to express the return type of functions that may not return.
// Specifically search / lookup operations work great with Optionals.
// The 2 advantages they have over using a type union with undefined is that.
// A) You don't need to check if a value is undefined when chaining operations.
// B) You can express a successful operation that returns undefined vs. a failure
// Combinators (all, coalesce) are provided to make it easier to work with Optionals.
// lazy constructors can be used to create Optionals that only evaluate when their data is needed.
// especially in combination with coalesce this allows defining an order of
// functions that should be executed to fetch a value. eg:
// coalesce([lazy(() => store.getA()), lazy(() => store.getB())]).map(v => ....);

/**
 * FlattenTypes, When calling flat() the result is to recursively flatten up-to depth
 *  However it's not possible to express that in Typescript because Recursive Types don't work
 *  Using conditional types we can express that each level either unwraps or doesn't
 */
export type FlattenOptional1<T> = T extends Some<infer V>
  ? Some<V>
  : T extends None<infer V1>
  ? None<V1>
  : T extends Optional<infer V2>
  ? Optional<V2>
  : Optional<T>;
export type FlattenSome1<T> = T extends Some<infer V>
  ? Some<V>
  : T extends None<infer V1>
  ? None<V1>
  : T extends Optional<infer V2>
  ? Optional<V2>
  : Some<T>;
export type FlattenNone1<T> = T extends Some<infer V>
  ? None<V>
  : T extends None<infer V1>
  ? None<V1>
  : T extends Optional<infer V2>
  ? None<V2>
  : None<T>;
export type FlattenOptional2<T> = T extends Some<infer V>
  ? FlattenSome1<V>
  : T extends None<infer V1>
  ? FlattenNone1<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional1<V2>
  : Optional<T>;
export type FlattenSome2<T> = T extends Some<infer V>
  ? FlattenSome1<V>
  : T extends None<infer V1>
  ? FlattenNone1<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional1<V2>
  : Some<T>;
export type FlattenNone2<T> = T extends Some<infer V>
  ? FlattenNone1<V>
  : T extends None<infer V1>
  ? FlattenNone1<V1>
  : T extends Optional<infer V2>
  ? FlattenNone1<V2>
  : None<T>;
export type FlattenOptional3<T> = T extends Some<infer V>
  ? FlattenSome2<V>
  : T extends None<infer V1>
  ? FlattenNone2<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional2<V2>
  : Optional<T>;
export type FlattenSome3<T> = T extends Some<infer V>
  ? FlattenSome2<V>
  : T extends None<infer V1>
  ? FlattenNone2<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional2<V2>
  : Some<T>;
export type FlattenNone3<T> = T extends Some<infer V>
  ? FlattenNone2<V>
  : T extends None<infer V1>
  ? FlattenNone2<V1>
  : T extends Optional<infer V2>
  ? FlattenNone2<V2>
  : None<T>;
export type FlattenOptional4<T> = T extends Some<infer V>
  ? FlattenSome3<V>
  : T extends None<infer V1>
  ? FlattenNone3<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional3<V2>
  : Some<T>;
export type FlattenSome4<T> = T extends Some<infer V>
  ? FlattenSome3<V>
  : T extends None<infer V1>
  ? FlattenNone3<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional3<V2>
  : Some<T>;
export type FlattenNone4<T> = T extends Some<infer V>
  ? FlattenNone3<V>
  : T extends None<infer V1>
  ? FlattenNone3<V1>
  : T extends Optional<infer V2>
  ? FlattenNone3<V2>
  : None<T>;
export type FlattenSome5<T> = T extends Some<infer V>
  ? FlattenSome4<V>
  : T extends None<infer V1>
  ? FlattenNone4<V1>
  : T extends Optional<infer V2>
  ? FlattenOptional4<V2>
  : Some<T>;
export type FlattenNone5<T> = T extends Some<infer V>
  ? FlattenNone4<V>
  : T extends None<infer V1>
  ? FlattenNone4<V1>
  : T extends Optional<infer V2>
  ? FlattenNone4<V2>
  : None<T>;

/**
 * Some<T> represents the presence of something.
 * Roughly Matches the interface of the Array
 */
export interface Some<T> {
  readonly value: T;
  some(): true;
  some(predicate: (v: T) => boolean): boolean;
  filter(predicate: (v: T) => boolean): Optional<T>;
  map<V>(selector: (v: T) => V): Optional<V>;
  flatMap<V>(selector: (v: T) => Optional<V>): Optional<V>;
  flat(depth?: 1): FlattenSome1<T>;
  flat(depth: 2): FlattenSome2<T>;
  flat(depth: 3): FlattenSome3<T>;
  flat(depth: 4): FlattenSome4<T>;
  flat(depth: 5): FlattenSome5<T>;
  flat(depth?: number): Optional<any>;
  orElse(defaultSelector: () => T): T;
  find(): T;
  find(predicate: (v: T) => boolean): T | undefined;
  run(code: (v: T) => void): void;
  isSome(): this is Some<T>;
  toString(): string;
}

/**
 * None<T> represents the lack of presence of something.
 */
export interface None<T> {
  readonly value: undefined;
  some(predicate?: (v: T) => boolean): false;
  filter(predicate: (v: T) => boolean): None<T>;
  map<V>(selector: (v: T) => V): Optional<V>;
  flatMap<V>(selector: (v: T) => Optional<V>): Optional<V>;
  flat(depth?: 1): FlattenNone1<T>;
  flat(depth: 2): FlattenNone2<T>;
  flat(depth: 3): FlattenNone3<T>;
  flat(depth: 4): FlattenNone4<T>;
  flat(depth: 5): FlattenNone5<T>;
  flat(depth?: number): None<any>;
  orElse(defaultSelector: () => T): T;
  find(predicate?: (v: T) => boolean): undefined;
  run(code: (v: T) => void): void;
  isSome(): this is Some<T>;
  toString(): string;
}

/**
 * Optional<T> is either Some<T> or None<T>;
 */
export type Optional<T> = Some<T> | None<T>;

/**
 * Check if the value is an Optional
 * @param value anything
 */
export const isOptional = (value: unknown): value is Optional<unknown> =>
  value != null &&
  typeof value === 'object' &&
  'value' in value! &&
  'isSome' in value! &&
  'orElse' in value! &&
  'run' in value! &&
  'some' in value! &&
  'filter' in value! &&
  'map' in value! &&
  'flatMap' in value! &&
  'flat' in value! &&
  'find' in value!;

/**
 * Given a value return a Some capturing that value.
 * If the value is undefined the result will be a Some<undefined>
 */
export const some = <T>(value: T): Some<T> =>
  Object.defineProperties(
    {
      some: (predicate: (v: T) => boolean = () => true) => predicate(value),
      filter: (predicate: (v: T) => boolean) => (predicate(value) ? some(value) : none<T>()),
      map: <V>(selector: (v: T) => V) => some(selector(value)),
      flatMap: <V>(selector: (v: T) => Optional<V>) => selector(value),
      flat: (depth: number = 1) => {
        let r: Optional<unknown> = some(value);
        for (let i = 0; i < depth; i++) {
          // none is always none
          if (!r.isSome()) {
            break;
          }
          // grab the value inside the some
          const v = r.find();
          // check if the value is an optional
          if (isOptional(v)) {
            r = v as Optional<unknown>;
          } else {
            // no further flattening
            break;
          }
        }
        return r;
      },
      orElse: (_: () => T) => value,
      find: (predicate: (v: T) => boolean = () => true) => (predicate(value) ? value : undefined),
      run: (code: (v: T) => void) => {
        code(value);
      },
      isSome: () => true,
      toString: () => `Some[${String(value)}]`
    },
    {
      value: {
        configurable: false,
        enumerable: true,
        value,
        writable: false
      }
    }
  ) as Some<T>;

// constant NONE since the behavior isn't type specific
const NONE: None<any> = Object.defineProperties(
  {
    some: () => false,
    filter: () => NONE,
    map: <V>(_: (v: any) => V) => NONE,
    flatMap: <V>(_: (v: any) => Optional<V>) => NONE,
    flat: () => none<any>(),
    orElse: (defaultSelector: () => any) => defaultSelector(),
    find: () => undefined,
    run: () => {
      // noop
    },
    isSome: () => false,
    toString: () => 'None'
  },
  {
    value: {
      configurable: false,
      enumerable: true,
      value: undefined,
      writable: false
    }
  }
);

/**
 * create a None, the function
 * returns a cached constant but is used
 * to enable the type of the Optional to be specified
 */
export const none = <T>(): None<T> => NONE;

export interface All {
  <V1>(options: [Optional<V1>]): Optional<[V1]>;
  <V1, V2>(options: [Optional<V1>, Optional<V2>]): Optional<[V1, V2]>;
  <V1, V2, V3>(options: [Optional<V1>, Optional<V2>, Optional<V3>]): Optional<[V1, V2, V3]>;
  <V1, V2, V3, V4>(options: [Optional<V1>, Optional<V2>, Optional<V3>, Optional<V4>]): Optional<
    [V1, V2, V3, V4]
  >;
  <V1, V2, V3, V4, V5>(
    options: [Optional<V1>, Optional<V2>, Optional<V3>, Optional<V4>, Optional<V5>]
  ): Optional<[V1, V2, V3, V4, V5]>;
  <V1, V2, V3, V4, V5, V>(
    options: [
      Optional<V1>,
      Optional<V2>,
      Optional<V3>,
      Optional<V4>,
      Optional<V5>,
      ...Array<Optional<V>>
    ]
  ): Optional<[V1, V2, V3, V4, V5, ...V[]]>;
}

/**
 * Given an array of Optionals return an Optional of an array.
 * The returned optional will be Some if all the optionals are Some
 * The returned optional will be None if any of the optionals are None
 */
export const all: All = ((options: Array<Optional<any>>) =>
  options.every(o => o.isSome())
    ? some(options.map(o => (o as Some<any>).find()))
    : none<any>()) as any; // this doesn't typecheck cleanly but it's fine...

/**
 * Given an array of Optionals return a Optional with the first
 * present value. If all of the optionals are None then the returned value will be None
 * Otherwise the returned value will be the first Some
 */
export const coalesce = <V>(options: [Optional<V>, ...Array<Optional<V>>]): Optional<V> =>
  options.find(o => o.isSome()) || none<V>();

export const coallesce = coalesce;

/**
 * Given a value that is potentially null or undefined return an Optional
 * @param value A value, possibly null or undefined
 * The returned Optional will be None if the value is null or undefined otherwise it will be Some
 */
export const of = <V>(value: V | undefined | null): Optional<V> =>
  value === undefined || value === null ? none<V>() : some<V>(value);

/**
 * Given a generator function create an optional
 * @param generator A function to generate an Optional
 * The returned Optional chains operations until a terminal operation is invoked
 * then invokes the generator once to determine the result.
 * A Terminal operation is anything that doesn't return an Optional.
 */
export const lazy = <T>(generator: () => Optional<T>): Optional<T> => {
  let cachingGenerator = () => {
    const v = generator();
    cachingGenerator = () => v;
    return v;
  };
  return Object.defineProperties(
    {
      some: (predicate?: (v: T) => boolean) => cachingGenerator().some(predicate),
      filter: (predicate: (v: T) => boolean) => lazy(() => cachingGenerator().filter(predicate)),
      map: <V>(selector: (v: T) => V): Optional<V> =>
        lazy<V>(() => cachingGenerator().map(selector)),
      flatMap: <V>(selector: (v: T) => Optional<V>) =>
        lazy<V>(() => cachingGenerator().flatMap(selector)),
      flat: (depth?: number) => lazy(() => cachingGenerator().flat(depth)),
      orElse: (defaultSelector: () => T) => cachingGenerator().orElse(defaultSelector),
      find: (predicate?: (v: T) => boolean) => cachingGenerator().find(predicate),
      run: (code: (v: T) => void) => cachingGenerator().run(code),
      isSome: () => cachingGenerator().isSome(),
      toString: () => cachingGenerator().toString()
    },
    {
      value: {
        configurable: false,
        enumerable: true,
        get: () => cachingGenerator().value
      }
    }
  ) as Optional<T>;
};

/**
 * Given a generator that may return null or undefined returns
 * a lazy Optional where the value will be None if the generator returned null or undefined.
 */
export const lazyOf = <V>(generator: () => V | undefined | null): Optional<V> =>
  lazy(() => of(generator()));
