import Conditional from '@rategravity/frontend/components/conditional';
import { styled, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import React from 'react';
import { StyletronComponent } from 'styletron-react';
import { SecondaryButton } from '../buttons';
import { H5 } from '../headers';
import { ArrowIcon } from '../icons';
import { Modal } from '../modal';
import { Notice } from '../notice';

// The `StyledLink` element is exported as a React Component but it is indeed a
//  StyletronComponent. We perform this cast so the 'withStyle' type signature
//  checks out, as just using 'styled' throws a warning on render.
const ArrowLink = withStyle(StyledLink as StyletronComponent<any>, ({ $theme }) => ({
  borderLeft: `1px solid ${$theme.colors!.primary200}`
}));

const NoPaddingSecondaryButton = styled(SecondaryButton, {
  padding: '0px'
});

const IconWrapper = styled('div', {
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingLeft: '15px',
  paddingRight: '15px'
});

const CopyButton = withStyle(NoPaddingSecondaryButton, {});

const EmptyElement = styled('div', {});

export const LinkModal = ({
  isOpen,
  onClose,
  title,
  message,
  loading,
  link,
  networkError,
  copyToClipboard
}: {
  isOpen: boolean;
  onClose: () => void;
  title: string | null;
  message: string | null;
  loading: boolean;
  link: string | null;
  networkError: string | null;
  copyToClipboard: (link: string) => void;
}) => (
  <Modal
    isOpen={isOpen}
    onClose={() => onClose()}
    title={title}
    message={message}
    loading={loading}
    link={link}
    layout={'Centered'}
    networkError={networkError}
    buttons={[
      loading ? (
        <EmptyElement />
      ) : networkError ? (
        <SecondaryButton width={'Long'} onClick={() => onClose()}>
          Return to Offer Entry
        </SecondaryButton>
      ) : !!link ? (
        <NoPaddingSecondaryButton width={'Long'} disabled={loading}>
          <CopyButton
            width={'Long'}
            borderColor={'mono100'}
            onClick={() => copyToClipboard(link || '')}
          >
            Copy Link
          </CopyButton>
          <ArrowLink href={link} target="_blank">
            <IconWrapper>
              <ArrowIcon />
            </IconWrapper>
          </ArrowLink>
        </NoPaddingSecondaryButton>
      ) : (
        <React.Fragment />
      )
    ]}
  >
    <Conditional show={networkError !== null}>
      <Notice message={'Network Error. Contact Administrator'} />
    </Conditional>
    <H5>{message}</H5>
  </Modal>
);
