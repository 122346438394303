import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterOptions, GetProductDetailsResult } from '../../modules/rate-quote';
import { AllQuotesOffer } from '../../modules/rate-quote/types';
import { AllQuotesState } from './state';

const defaultAllQuotesState: AllQuotesState = {
  loadingProductDetails: false,
  loading: false,
  error: null,
  rates: {},
  includeOfferLoading: null,
  isSameOfferModalOpen: false
};

export const allQuotesSlice = createSlice({
  name: 'allQuotes',
  initialState: defaultAllQuotesState,
  reducers: {
    getAllQuotes: (
      state,
      _: PayloadAction<{
        rateQuoteRequestId: string;
        filterOptions: FilterOptions;
      }>
    ) => ({
      ...state,
      loading: true,
      error: null,
      rates: {}
    }),
    getAllQuotesSuccess: (
      state,
      { payload: { offers } }: PayloadAction<{ offers: AllQuotesOffer[] }>
    ) => ({
      ...state,
      rates: {
        ...state.rates,
        ...offers.reduce(
          (acc, { offerId, ...restOffer }) => ({ ...acc, [offerId]: { offerId, ...restOffer } }),
          {}
        )
      },
      error: null
    }),
    getAllQuotesError: (state, { payload: { error } }: PayloadAction<{ error: string }>) => ({
      ...state,
      error
    }),
    getAllQuotesComplete: state => ({
      ...state,
      loading: false
    }),
    getProductDetails: (state, _: PayloadAction<{ productId: number; searchId: string }>) => ({
      ...state,
      loadingProductDetails: true
    }),
    getProductDetailsSuccess: (state, _: PayloadAction<GetProductDetailsResult>) => ({
      ...state,
      loadingProductDetails: false
    }),
    getProductDetailsFailure: state => ({
      ...state,
      loadingProductDetails: false
    }),
    addOfferToRQ: (state, { payload: { offerId } }: PayloadAction<{ offerId: string }>) => ({
      ...state,
      includeOfferLoading: offerId
    }),
    addOfferToRQComplete: state => ({
      ...state,
      includeOfferLoading: null
    }),
    toggleSameOfferModal: (state, { payload: { isOpen } }: PayloadAction<{ isOpen: boolean }>) => ({
      ...state,
      isSameOfferModalOpen: isOpen
    })
  }
});

export const {
  getAllQuotes,
  getAllQuotesSuccess,
  getAllQuotesError,
  getAllQuotesComplete,
  getProductDetails,
  getProductDetailsSuccess,
  getProductDetailsFailure,
  addOfferToRQ,
  addOfferToRQComplete,
  toggleSameOfferModal
} = allQuotesSlice.actions;

export const reducer = allQuotesSlice.reducer;
