import { strict as assert } from 'assert';
import { AllQuotesOffer, RateQuote } from '../rate-quote/types';

export const isSameOffer = (offers: RateQuote['offers'], o: AllQuotesOffer) =>
  Object.values(offers).find(
    ({
      attributes: { product, lender, price },
      productDetails: { rate, manualRateLockDays },
      investor
    }) =>
      o.attributes.price === price &&
      o.productDetails.rate === rate &&
      isSameObject(o.attributes.product, product) &&
      o.productDetails.manualRateLockDays === manualRateLockDays &&
      o.investor === investor &&
      o.attributes.lender === lender
  );

const isSameObject = (obj1: object, obj2: object): boolean => {
  try {
    assert.deepEqual(obj1, obj2);
    return true;
  } catch (e) {
    return false;
  }
};
