import { decode } from 'jsonwebtoken';
import { useMemo, useState } from 'react';

export interface UserInfo {
  email: string;
  name: string;
  group: string[];
}

export const useGetUserInfo = (): UserInfo | null => {
  const [token] = useState(() => localStorage.getItem('id_token'));
  const decoded = useMemo(
    () => (token == null ? null : (decode(token!, { complete: true }) as { [key: string]: any })),
    [token]
  );

  if (decoded) {
    return {
      email: decoded.payload.email,
      name: decoded.payload.name,
      group: decoded.payload['https://auth.ownup.com/groups']
    };
  }

  return null;
};
