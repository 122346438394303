import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';
import { App } from './app';
import { ThemeProvider } from './components/theme-provider';
import { ToasterContainer } from './components/toaster';
import { store } from './redux';
import { SplitProvider } from './split';

render(
  <Provider store={store}>
    <ThemeProvider>
      <ToasterContainer />
      <SplitProvider>
        <Router>
          <Switch>
            <Route path="/:rateQuoteId?">
              <App />
            </Route>
          </Switch>
        </Router>
      </SplitProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
