import { EditableOffer } from '../../redux/offer-details/state';
import { deleteItem, fetchItem, LocalStoreType, storeItem } from '../local-storage';
import { MerchandisingPayload } from '../rate-quote/merchandising';
import { RateQuote } from '../rate-quote/types';

//////////////////////////////////////////////////////////////////////////////////////
// Merchandising Cache
//////////////////////////////////////////////////////////////////////////////////////

const MERCHANDISING_CACHE_LONGEVITY = 30 * 60 * 1000; // 30 minutes
/**
 * Stores a merchandising set in the local cache.
 *
 * @param rateQuoteId Id of RateQuote this offer belongs to.
 * @param merchandising Merchandising set to sync.
 * @param timestamp Timestamp to mark the offer as. Leave empty to use now.
 */
export const storeMerchandisingCacheState = async (
  rateQuoteId: string,
  merchandising: MerchandisingPayload,
  timestamp?: Date | number
) => storeItem(rateQuoteId, merchandising, LocalStoreType.OfferMerchandising, timestamp);

/**
 * Remove a merchandising set from the local cache.
 *
 * @param rateQuoteId Id of RateQuote this offer belongs to.
 */
export const clearMerchandisingCacheState = async (rateQuoteId: string) =>
  deleteItem(rateQuoteId, LocalStoreType.OfferMerchandising);

/**
 * Retrieves the local merchandising cache and compares the timestamp of
 *   the rate quote to see if the merchandising cache should take precedence.
 *
 * Compares the Cached Timestamp + `MERCHANDISING_CACHE_LONGEVITY` to `RateQuote.lastModifiedTime`
 *   and merges the cached merchandising info into the Rate Quote if true.
 *
 * Returns a tuple containing the rateQuote and whether or not the cache was applied.
 *
 * @param rateQuote Rate Quote to check.
 */
export const synchWithMerchandisingCache = async (
  rateQuote: RateQuote
): Promise<[RateQuote, boolean]> => {
  let restored = false;
  // Fetch the Merchandising Cache
  const cacheSet = await fetchItem<MerchandisingPayload>(
    rateQuote.rateQuoteId,
    LocalStoreType.OfferMerchandising
  );

  // Check if the cache set should take precedence.
  restored = cacheSet
    .map(set => set.timestamp + MERCHANDISING_CACHE_LONGEVITY > rateQuote.lastModifiedTime)
    .orElse(() => false);

  if (restored) {
    const { merchandising, starred } = cacheSet.value!.item;
    const starSet = new Set(starred);
    // Merge the merchandising cache into the Rate Quote.
    rateQuote.merchandising = merchandising;
    Object.entries(rateQuote.offers).forEach(([offerId, offer]) => {
      offer.starred = starSet.has(offerId);
    });
  }
  return [rateQuote, restored];
};

//////////////////////////////////////////////////////////////////////////////////////
// Modal Cache
//////////////////////////////////////////////////////////////////////////////////////

/**
 * Creates a cache key for an offer with id `offerId` belonging to rate
 *   quote with id `rateQuoteId`
 *
 * Returns in format `rateQuoteId___offerId` or `rateQuoteId___WIP`
 *   if no offerId is specified.
 *
 * @param offerId
 * @param rateQuoteId
 */
export const createModalCacheKey = (offerId: string | undefined | null, rateQuoteId: string) =>
  `${rateQuoteId}___${offerId || 'WIP'}`;

/**
 * Returns the locally cached state of the modal if it exists and the RateQuote
 *   has not been updated since last manipulating it.
 *
 * @param rateQuoteId Id of the currently focused rate quote.
 * @param rateQuoteTimestamp Timestamp fo the currently focused rate quote.
 */
export const getModalCacheState = async (
  rateQuoteId: string,
  rateQuoteTimestamp: Date | number
) => {
  const modalState = await fetchItem(rateQuoteId, LocalStoreType.OfferModals);
  return modalState
    .map(state => (state.timestamp > rateQuoteTimestamp ? state.item : undefined))
    .orElse(() => undefined);
};

/**
 * Stores the current state of the modal for the given rateQuoteId in the cache.
 *
 * @param rateQuoteId Id of the currently focused rate quote.
 * @param modalState State of the modal to store.
 */
export const storeModalCacheState = (
  rateQuoteId: string,
  modalState: { offer: EditableOffer; allQuotes: boolean }
) => storeItem(rateQuoteId, modalState, LocalStoreType.OfferModals);

/**
 * Removes the modal state from the local cache.
 *
 * @param rateQuoteId Id of the currently focused rate quote.
 */
export const clearModalCacheState = async (rateQuoteId: string) =>
  deleteItem(rateQuoteId, LocalStoreType.OfferModals);
