import { Deal } from '../../modules/deals/types';

export const FetchDealsActionType = 'DEALS__FETCH' as const;
export const fetchDeals = (accountId: string) => ({
  type: FetchDealsActionType,
  payload: accountId
});
export type FetchDealsAction = ReturnType<typeof fetchDeals>;

export const FetchDealsSuccessActionType = 'DEALS__FETCH_SUCCESS' as const;
export const fetchDealsSuccess = (payload: Record<string, Deal>) => ({
  type: FetchDealsSuccessActionType,
  payload
});
export type FetchDealsSuccessAction = ReturnType<typeof fetchDealsSuccess>;

export const FetchDealsFailureActionType = 'DEALS__FETCH_FAILURE' as const;
export const fetchDealsError = (payload: string) => ({
  type: FetchDealsFailureActionType,
  payload
});
export type FetchDealsFailureAction = ReturnType<typeof fetchDealsError>;
