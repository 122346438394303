import { all, call, delay, put, race, take, takeEvery } from 'redux-saga/effects';
import { deleteLock, editLock, getLockData, lockOffer } from '../../modules/lock-offer';
import { setLockedOffer } from '../rate-quote/actions';
import {
  closeLockModal,
  CloseLockModalActionType,
  DeleteLockAction,
  DeleteLockActionType,
  deleteLockFailure,
  deleteLockSuccess,
  EditLockAction,
  EditLockActionType,
  GetLockAction,
  GetLockActionType,
  getLockFailure,
  getLockRequest,
  getLockSuccess,
  LockOfferAction,
  LockOfferActionType,
  lockOfferFailure,
  lockOfferSuccess
} from './actions';

export function* handleLockOffer({ payload }: LockOfferAction) {
  try {
    const { success, json, error } = yield call(lockOffer, payload);
    if (success) {
      yield put(lockOfferSuccess(json!));
      yield put(setLockedOffer(payload.offerId, true));
      yield put(getLockRequest({ rateQuoteId: payload.rateQuoteId, offer: payload.offer }));
    } else {
      yield put(lockOfferFailure(error!));
    }
  } catch (err) {
    yield put(lockOfferFailure(err.message));
  }
}

export function* handleGetLock({ payload: { rateQuoteId, offer } }: GetLockAction) {
  yield race([
    // If the modal is closed shutdown this whole saga, we don't need the data.
    take(CloseLockModalActionType),
    // poll the getLockData endpoint till a non-pending
    // result is returned from the data.
    call(function*() {
      try {
        while (true) {
          const { success, json, error } = yield call(getLockData, {
            rateQuoteId,
            offerId: offer.offerId
          });
          if (success) {
            yield put(getLockSuccess(json));
            // poll
            if (!json.pending) {
              break;
            }
            // wait a second before re-polling;
            yield delay(1000);
          } else {
            yield put(getLockFailure(error!));
            break;
          }
        }
      } catch (err) {
        yield put(getLockFailure(err.message));
      }
    })
  ]);
}

export function* handleDeleteLock({ payload }: DeleteLockAction) {
  try {
    const { success, error } = yield call(deleteLock, payload);
    if (success) {
      yield put(deleteLockSuccess());
      yield put(setLockedOffer(payload.offerId, false));
      yield put(closeLockModal());
    } else {
      yield put(deleteLockFailure(error!));
    }
  } catch (err) {
    yield put(deleteLockFailure(err.message));
  }
}

export function* handleEditLock({ payload }: EditLockAction) {
  try {
    const { success, json, error } = yield call(editLock, payload);
    if (success) {
      yield put(lockOfferSuccess(json!));
      yield put(getLockRequest({ rateQuoteId: payload.rateQuoteId, offer: payload.offer }));
    } else {
      yield put(lockOfferFailure(error!));
    }
  } catch (err) {
    yield put(lockOfferFailure(err.message));
  }
}

export const lockModalSaga = function*() {
  yield all([
    takeEvery(LockOfferActionType, handleLockOffer),
    takeEvery(GetLockActionType, handleGetLock),
    takeEvery(DeleteLockActionType, handleDeleteLock),
    takeEvery(EditLockActionType, handleEditLock)
  ]);
};
