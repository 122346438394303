import { Logo } from '@rategravity/frontend/components/icons/svg-definitions';
import * as Sentry from '@sentry/browser';
import { styled } from 'baseui';
import React from 'react';
import { useGetUserInfo } from '../../hooks/use-get-user';
import { BugReportButton } from '../buttons/bug-report-button';
import { Container } from '../container';

const OwnupHeaderWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  'align-items': 'center',
  width: '100%',
  height: '120px',
  backgroundColor: $theme.colors!.primary300
}));

export const OwnUpHeader = () => {
  const userInfo = useGetUserInfo();

  const handleSentryError = () => {
    const eventId = Sentry.captureMessage('Bug Report', Sentry.Severity.Log);
    Sentry.showReportDialog({
      eventId,
      user: userInfo!,
      title: 'Report a Bug',
      subtitle: 'Please tell us what happened.',
      subtitle2: '',
      labelSubmit: 'Submit Bug Report',
      successMessage: 'Your bug has been submitted'
    });
  };

  return (
    <OwnupHeaderWrapper className="rg-header-wrapper">
      <Container>
        <svg width="194px" height="45px">
          <Logo />
        </svg>
        <BugReportButton onClick={handleSentryError}>Report a Bug</BugReportButton>
      </Container>
    </OwnupHeaderWrapper>
  );
};
