import * as t from 'type-shift';

export interface ArmCaps {
  initial: number;
  periodic: number;
  lifetime: number;
}

export const armCaps = t.strict<ArmCaps>({
  initial: t.number,
  periodic: t.number,
  lifetime: t.number
});

export type FeeCategory = 'ClosingCost' | 'Prepaid' | 'Other';

export const feeCategory = t.oneOf<FeeCategory>(['ClosingCost', 'Prepaid', 'Other']);

export interface FeeCalculationAttributes {
  apr: boolean;
  category: FeeCategory;
  estimate: boolean;
  optional: boolean;
  credit: boolean;
}

export const feeCalculationAttributes = t.strict<FeeCalculationAttributes>({
  apr: t.boolean,
  category: feeCategory,
  estimate: t.boolean,
  optional: t.boolean,
  credit: t.boolean
});

export type FeeValue = number | 'NotIncluded' | 'ManualEntry';

export const feeValue = t.number.or(
  t.oneOf<FeeValue>(['NotIncluded', 'ManualEntry'])
);

export interface Fee {
  id: string;
  name: string;
  calculationAttributes: FeeCalculationAttributes;
  value: FeeValue;
}

export const fee = t.strict<Fee>({
  id: t.string,
  name: t.string,
  calculationAttributes: feeCalculationAttributes,
  value: feeValue
});

export type Index = 'UST' | 'LIBOR' | 'SOFR';

export const index = t.oneOf(['UST', 'LIBOR', 'SOFR']);

export interface IndexRate {
  defaultIndex: Index | 'ManualEntry';
  margins: {
    [I in Index]: number | 'ManualEntry';
  };
}

export const indexRate = t.strict<IndexRate>({
  defaultIndex: index.or(t.literal('ManualEntry')),
  margins: t.strict({
    UST: t.number.or(t.literal('ManualEntry')),
    LIBOR: t.number.or(t.literal('ManualEntry')),
    SOFR: t.number.or(t.literal('ManualEntry'))
  })
});

export interface DefaultFees {
  id: string;
  name: string;
  url: string;
  nmls: string;
  rateLockDays: number;
  fees: Fee[];
  armCaps: ArmCaps | null;
  mi: {
    readonly: boolean;
    value?: number;
    required: boolean;
  };
  amtToFinance: number;
  indexRate: IndexRate;
  upFrontMip?: number;
}

export const defaultFees = t.strict<DefaultFees>({
  id: t.string,
  name: t.string,
  url: t.string,
  nmls: t.string,
  rateLockDays: t.number,
  fees: t.array(fee),
  armCaps: armCaps.or(t.null),
  mi: t.strict({
    readonly: t.boolean,
    value: t.optional(t.number),
    required: t.boolean
  }),
  amtToFinance: t.number,
  indexRate,
  upFrontMip: t.number.or(t.undefined)
});
