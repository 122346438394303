import React from 'react';
import { Header } from '../components/headers';
import { Tabs } from '../components/tabs';
import { AllQuotes } from './all-quotes';
import { OfferTable } from './offer-table';

export const RateQuotePageTabs = ({ showAllQuotes }: { showAllQuotes: boolean }) => (
  <div>
    <Header>Offers</Header>
    {showAllQuotes ? (
      <Tabs
        tabs={[
          { label: 'Publishable', value: 'Publishable', children: <OfferTable /> },
          { value: 'AllQuotes', label: 'All Quotes', children: <AllQuotes /> }
        ]}
        defaultValue="Publishable"
      />
    ) : (
      <OfferTable />
    )}
  </div>
);
