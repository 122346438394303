import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  previewRateQuote,
  PreviewRqResult,
  publishRateQuote,
  PublishRqResult,
  submitRateQuote,
  SubmitRqResult
} from '../../modules/rate-quote';
import { MerchandisingPayload } from '../../modules/rate-quote/merchandising';
import { merchandisingPayloadSelector } from '../../selectors/offer';
import {
  PreviewRateQuoteAction,
  PreviewRateQuoteActionType,
  previewRateQuoteFailure,
  previewRateQuoteSuccess,
  PublishRateQuoteActionType,
  publishRateQuoteFailure,
  PublishRateQuoteRequestAction,
  publishRateQuoteSuccess,
  SubmitRateQuoteAction,
  SubmitRateQuoteActionType,
  submitRateQuoteFailure,
  submitRateQuoteSuccess
} from './actions';

export function* handlePublishRequest({ payload }: PublishRateQuoteRequestAction) {
  try {
    const merchandisingSet: MerchandisingPayload = yield select(merchandisingPayloadSelector);
    const { success, error, json }: PublishRqResult = yield call(
      publishRateQuote,
      payload,
      merchandisingSet
    );
    if (success) {
      yield put(publishRateQuoteSuccess(json!));
    } else {
      yield put(publishRateQuoteFailure(error!));
    }
  } catch (err) {
    yield put(publishRateQuoteFailure(err.message));
  }
}

export function* handlePreviewRequest({ payload }: PreviewRateQuoteAction) {
  try {
    const merchandisingSet: MerchandisingPayload = yield select(merchandisingPayloadSelector);
    const { success, json, error }: PreviewRqResult = yield call(
      previewRateQuote,
      payload,
      merchandisingSet
    );
    if (success) {
      yield put(previewRateQuoteSuccess(json!.link));
    } else {
      yield put(previewRateQuoteFailure(error!));
    }
  } catch (err) {
    yield put(previewRateQuoteFailure(err.message));
  }
}

export function* handleSubmitRequest({ payload }: SubmitRateQuoteAction) {
  try {
    const { success, error }: SubmitRqResult = yield call(submitRateQuote, payload);
    if (success) {
      yield put(submitRateQuoteSuccess());
    } else {
      yield put(submitRateQuoteFailure(error!));
    }
  } catch (err) {
    yield put(submitRateQuoteFailure(err.message));
  }
}

export const linkModalSaga = function*() {
  yield all([
    takeEvery(PublishRateQuoteActionType, handlePublishRequest),
    takeEvery(PreviewRateQuoteActionType, handlePreviewRequest),
    takeEvery(SubmitRateQuoteActionType, handleSubmitRequest)
  ]);
};
