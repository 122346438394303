import { styled } from 'baseui';
import { Card } from 'baseui/card';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React, { PropsWithChildren } from 'react';
import { Label2, Label3, LabelWithSublabel } from '../../labels';

export const DetailsContainer = styled('div', {
  marginTop: 0,
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
  paddingTop: '20px',
  paddingBottom: '20px'
});

export const flexGridItemOverrides = {
  Block: {
    style: {
      display: 'flex',
      height: '40px',
      alignItems: 'stretch',
      justifyContent: 'center'
    }
  }
};

export const cardOverrides = {
  Root: {
    style: {
      backgroundColor: 'white',
      marginTop: 0,
      marginBottom: '10px',
      marginLeft: 0,
      marginRight: 0,
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      borderBottomLeftRadius: '3px',
      paddingBottom: '48px'
    }
  },
  Body: {
    style: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0
    }
  },
  Contents: {
    style: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      paddingTop: '24px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '0px'
    }
  }
};

export const CardTitle = ({ title, subtitle }: { title: string; subtitle?: string }) =>
  subtitle ? <LabelWithSublabel title={title} subtitle={subtitle} /> : <Label3>{title}</Label3>;

export const BaseCard = ({
  title,
  subtitle,
  children
}: {
  title: string;
  subtitle?: string;
  children: any[];
}) => (
  <Card
    overrides={{
      Title: {
        component: CardTitle,
        props: { title, subtitle }
      },
      ...cardOverrides
    }}
    title
  >
    <DetailsContainer>
      <FlexGrid flexGridColumnCount={3} flexGridColumnGap="scale700" flexGridRowGap="scale1200">
        {React.Children.map(
          React.Children.toArray(children).filter(i => i.props.show === undefined || i.props.show),
          (child, index) => (
            <FlexGridItem key={index}>{child}</FlexGridItem>
          )
        )}
      </FlexGrid>
    </DetailsContainer>
  </Card>
);

export const CardText = ({ children }: PropsWithChildren<{}>) => (
  <div style={{ marginBottom: '12px' }}>
    <Label2>{children}</Label2>
  </div>
);
