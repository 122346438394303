import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllInvestorsState } from './state';

const initialState: AllInvestorsState = {
  investors: [],
  loading: false
};

export const allInvestorsSlice = createSlice({
  name: 'allInvestors',
  initialState,
  reducers: {
    getAllInvestors: (_, __: PayloadAction<{ rateQuoteRequestId: string }>) => ({
      investors: [],
      loading: true
    }),
    getAllInvestorsFailed: (_, { payload: { error } }: PayloadAction<{ error?: string }>) => ({
      investors: [],
      loading: false,
      error
    }),
    getAllInvestorsSucceeded: (
      _,
      { payload: { investors } }: PayloadAction<{ investors: string[] }>
    ) => ({
      investors,
      loading: false
    })
  }
});

export const {
  getAllInvestors,
  getAllInvestorsFailed,
  getAllInvestorsSucceeded
} = allInvestorsSlice.actions;

export const reducer = allInvestorsSlice.reducer;
