import * as t from 'type-shift';
import { ArmCaps, armCaps, IndexRate, indexRate } from '../../default-fees/types';
import { ExistingLoan, existingLoanConverter } from './existing-loans';
import { BaseAttributes, baseAttributes, Offer } from './offer';

interface AllQuotesBaseAttributes extends BaseAttributes {
  closingCost: number;
}

interface PurchaseAttributes extends AllQuotesBaseAttributes {
  price: number;
  downPayment: number;
}

interface RefinanceAttributes extends AllQuotesBaseAttributes {
  price: number;
  totalCashOut: number;
  cashOut: number;
  existingLoans: ExistingLoan[];
  irrrlEligible: boolean;
}

export type AllQuotesOffer = Omit<
  Offer,
  | 'lastModifiedTime'
  | 'fees'
  | 'manualFees'
  | 'lender'
  | 'computedProductDetails'
  | 'monthlyPayment'
> & {
  engineDetails: {
    productId?: number;
    searchId?: string;
  };
  productName?: string;
  manualFees: unknown[];
  fees: unknown[];
  lender: {
    name: string;
    url: string;
  };
  computedProductDetails:
    | {
        armCaps: ArmCaps | null;
        indexRate: IndexRate;
        apr: number;
        dti: number;
      }
    | {
        apr: number;
        dti: number;
      };
  monthlyPayment: {
    mi: { value?: number; required: boolean };
    pAndI: number;
  };
  attributes: PurchaseAttributes | RefinanceAttributes;
  productDetails: {
    label: string;
    rate: number;
    manualRateLockDays: number;
    armCaps: unknown;
    indexType: unknown;
    margin: unknown;
  };
  sellerConcessions?: number | null;
};

const allQuotesBaseAttributes = t.strict<AllQuotesBaseAttributes>({
  ...baseAttributes.converters,
  closingCost: t.number
});

const purchaseAttributes = t.strict<PurchaseAttributes>({
  ...allQuotesBaseAttributes.converters,
  price: t.number,
  downPayment: t.number
});

const refinanceAttributes = t.strict<RefinanceAttributes>({
  ...allQuotesBaseAttributes.converters,
  price: t.number,
  totalCashOut: t.number,
  cashOut: t.number,
  existingLoans: t.array(existingLoanConverter),
  irrrlEligible: t.boolean
});

export const allQuotesConverter = t.array(
  t.strict<AllQuotesOffer>({
    type: t.oneOf(['purchase', 'refinance']),
    offerId: t.string,
    method: t.oneOf(['Manual', 'Automated', 'AllQuotes']),
    investor: t.string,
    expired: t.boolean,
    starred: t.boolean,
    locked: t.boolean,
    productName: t.string,
    manualFees: t.array(t.unknown),
    fees: t.array(t.unknown),
    monthlyPayment: t.strict({
      mi: t.strict({
        value: t.optional(t.number),
        required: t.boolean
      }),
      pAndI: t.number
    }),
    productDetails: t.strict({
      label: t.string,
      rate: t.number,
      manualRateLockDays: t.number,
      armCaps: t.unknown,
      indexType: t.unknown,
      margin: t.unknown
    }),
    lender: t.strict({
      name: t.string,
      url: t.string
    }),
    attributes: t.select<PurchaseAttributes | RefinanceAttributes>((type: any) =>
      type.downPayment !== undefined ? purchaseAttributes : refinanceAttributes
    ),
    computedProductDetails: t.select((type: any) =>
      type.armCaps !== undefined
        ? t.strict({
            apr: t.number,
            dti: t.number,
            armCaps: armCaps.or(t.null),
            indexRate
          })
        : t.strict({
            apr: t.number,
            dti: t.number
          })
    ),
    engineDetails: t.strict({
      productId: t.optional(t.number),
      searchId: t.optional(t.string)
    }),
    sellerConcessions: t.optional(t.number.or(t.null))
  })
);
