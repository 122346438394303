import * as auth0 from 'auth0-js';

const webAuthOptions = {
  domain: 'ownup.auth0.com',
  clientID: process.env.AUTH_0_CLIENT_ID || '',
  responseType: 'token id_token',
  redirectUri: window.location.origin + '/auth'
};

const ERROR_TOKEN_EMPTY = 'ERROR_TOKEN_EMPTY';

const ID_TOKEN_KEY = 'id_token';
const LAST_UNAUTHORIZED_KEY = 'last_unauthorized_time';

const webAuth = new auth0.WebAuth(webAuthOptions);

let myToken: string | null = null;

// Redirects user to login with auth0.
export function login(options: any = {}) {
  options.state = encodeURIComponent(
    btoa(
      JSON.stringify({
        redirect_uri: window.location.href.replace(window.location.origin, '') // Only stores the relative path.
      })
    )
  );

  webAuth.authorize({
    ...webAuthOptions,
    ...options
  });
}

// Fetches id_token from memory or localStorage.
export function getIdToken(): string | null {
  if (myToken === null) {
    myToken = localStorage.getItem(ID_TOKEN_KEY);
  }
  return myToken;
}

// Sets or clears id_token in memory and localStorage.
export function setIdToken(token: string | null) {
  if (token) {
    localStorage.setItem(ID_TOKEN_KEY, token as string);
  } else {
    localStorage.removeItem(ID_TOKEN_KEY);
  }
  myToken = token;
}

// Attempts to asynchronusly parse the authentication token that should be
//   included in the hash provided by an auth redirect.
export function parseHash(): Promise<auth0.Auth0DecodedHash> {
  const promise = new Promise<auth0.Auth0DecodedHash>((resolve, reject) => {
    webAuth.parseHash({ hash: window.location.hash }, (err, parseResult) => {
      if (err) {
        reject(err);
      } else if (parseResult) {
        resolve(parseResult);
      } else {
        reject(new Error(ERROR_TOKEN_EMPTY));
      }
    });
  });
  return promise;
}

// Attempts to parse and save the id_token in the uri hash.
export async function handleLoginCallback() {
  const hashResults = await parseHash();
  if (hashResults.idToken) {
    setIdToken(hashResults.idToken);
  } else {
    throw new Error(ERROR_TOKEN_EMPTY);
  }
  let redirect = window.location.origin;
  if (hashResults.state) {
    try {
      const state = JSON.parse(atob(decodeURIComponent(hashResults.state)));
      if (state.redirect_uri) {
        redirect = state.redirect_uri;
      }
    } catch {
      // ... no action.
    }
  }
  window.location.href = redirect;
}

function recentlyTriedToLogin() {
  const lastAttempt = sessionStorage.getItem(LAST_UNAUTHORIZED_KEY);
  if (lastAttempt) {
    try {
      const lastAttemptTimestamp = Number(lastAttempt);
      return Date.now() - lastAttemptTimestamp < 30000;
    } catch (err) {
      // ... no op.
    }
  }
  return false;
}

export async function loggedInFetch(
  info: RequestInfo,
  { headers, ...init }: RequestInit = { headers: {} }
): Promise<Response> {
  const token = getIdToken();
  if (token) {
    const authorizedHeaders = new Headers(headers);
    authorizedHeaders.set('Authorization', `Bearer ${token}`);
    authorizedHeaders.append('Accept', 'application/json');
    const result = await fetch(info, {
      ...init,
      headers: authorizedHeaders
    });
    // don't return expired login tokens
    if (result.status !== 401) {
      return result;
    }
  }
  let msg: string;
  if (recentlyTriedToLogin()) {
    msg = 'There was a problem authenticating your account.';
  } else {
    // trigger a login
    msg = 'Redirecting to login...';
    sessionStorage.setItem(LAST_UNAUTHORIZED_KEY, Date.now().toString());
    login();
  }
  // return an error
  return new Response(undefined, {
    status: 401,
    statusText: msg
  });
}
