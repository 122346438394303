import Conditional from '@rategravity/frontend/components/conditional';
import { styled } from 'baseui';
import { Card } from 'baseui/card';
import { LABEL_PLACEMENT } from 'baseui/checkbox';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React from 'react';
import { Errorable } from '../../redux/offer-details/state';
import { CheckBox, Radio, StringInput, TextareaInput } from '../forms/input';
import { Select } from '../forms/select';
import { Label1 } from '../labels';

const cardProps = {
  overrides: {
    Root: {
      style: {
        backgroundColor: 'white',
        borderRadius: '3px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 3px -3px'
      }
    },
    Contents: {
      style: {
        height: 'fit-content'
      }
    }
  }
};

const AdditionalNotesWrapper = styled('div', {
  height: '140px'
});

const HubSpotInfoLabel = styled('div', {
  marginTop: '12px'
});

export const LockCard = ({
  rgTitle,
  loanId,
  attorney,
  attorneyRequired,
  provider,
  notes,
  onChange,
  showValidationError,
  dealIds,
  float,
  disableOUTitle
}: Errorable<{
  loanId: string;
  attorney: string | undefined;
  rgTitle: boolean | undefined;
}> & {
  attorneyRequired: boolean;
  provider?: string;
  notes: string;
  onChange: ({ id, value }: { id: string; value: string | boolean }) => void;
  showValidationError: boolean;
  dealIds: Array<{ value: string; label: string }>;
  float: boolean;
  disableOUTitle: boolean;
}) => (
  <Card {...cardProps}>
    <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale1000" flexGridRowGap="scale1000">
      <FlexGridItem>
        <Conditional show={dealIds.length >= 1}>
          <Select
            options={dealIds}
            label="Hubspot Deal ID *"
            onChange={v => onChange({ id: 'loanId', value: v || '' })}
            value={loanId.value}
            error={loanId.error && showValidationError}
          />
        </Conditional>
        <Conditional show={dealIds.length < 1}>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="16px">
            <StringInput
              label="Hubspot Deal ID *"
              onChange={v => onChange({ id: 'loanId', value: v || '' })}
              value={loanId.value}
              error={loanId.error && showValidationError}
            />
          </FlexGrid>
        </Conditional>
      </FlexGridItem>
      <FlexGridItem>
        <Radio
          title="Own Up recommended title provider"
          alignment="horizontal"
          disabled={disableOUTitle}
          value={rgTitle.value === undefined ? '' : rgTitle.value.toString()}
          buttons={[
            { label: 'Yes', buttonValue: 'true' },
            { label: 'No', buttonValue: 'false' }
          ]}
          onChange={v => onChange({ id: 'rgTitle', value: v === 'true' })}
          error={rgTitle.error && showValidationError}
        />
      </FlexGridItem>
      {rgTitle.value ? (
        attorneyRequired ? (
          <FlexGridItem>
            <Select
              label="Attorney"
              onChange={v => onChange({ id: 'attorney', value: v || '' })}
              options={[
                { value: 'Atlantic Title', label: 'Atlantic Title' },
                { value: 'Baker', label: 'Baker' },
                { value: 'Click N Close', label: 'Click N Close' },
                { value: 'Hudson', label: 'Hudson' },
                { value: 'KTS', label: 'KTS' }
              ]}
              defaultValue="Select attorney"
              value={attorney.value}
              error={attorney.error && showValidationError}
            />
          </FlexGridItem>
        ) : null
      ) : (
        <FlexGridItem>
          <StringInput
            label="Provider (optional)"
            onChange={v => onChange({ id: 'provider', value: v || '' })}
            value={provider}
          />
        </FlexGridItem>
      )}
    </FlexGrid>
    <AdditionalNotesWrapper>
      <TextareaInput
        label="Additional Notes"
        onChange={v => onChange({ id: 'notes', value: v || '' })}
        value={notes}
      />
    </AdditionalNotesWrapper>
    <CheckBox
      checked={float}
      labelPlacement={LABEL_PLACEMENT.right}
      onChange={value => onChange({ id: 'float', value })}
    >
      Set lock to float
    </CheckBox>
    <HubSpotInfoLabel>
      <Label1>
        * Not seeing your Deal? Move it to the Consult Complete stage in HubSpot for it to show in
        the Deal ID dropdown.
      </Label1>
    </HubSpotInfoLabel>
  </Card>
);
