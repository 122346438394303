import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { MerchandisingPayload } from '../modules/rate-quote/merchandising';
import { RateQuote } from '../modules/rate-quote/types';
import { ApplicationState } from '../redux';

export const offersSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) => rateQuote,
  rateQuote =>
    of(rateQuote)
      .map(({ offers }) => offers)
      .orElse(() => ({} as RateQuote['offers']))
);

export const merchandisingSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) => rateQuote,
  rateQuote =>
    of(rateQuote)
      .map(({ merchandising }) => merchandising)
      .orElse(() => [])
);

/**
 * Converts merchandising and offer data from current RateQuote into
 *   the `MerchandisingPayloadState` object for use in saving and storing.
 */
export const merchandisingPayloadSelector = createSelector(
  merchandisingSelector, // Select Merchandising from Rate Quote
  offersSelector, // Select Offers from Rate Quote
  // Convert to `MerchandisingPayloadState`
  (merchandising, offers): MerchandisingPayload => {
    // Extract `starred` status from Offers.
    const starSet = Object.entries(offers)
      .filter(([, { starred }]) => starred)
      .map(([offerId]) => offerId);
    return {
      merchandising,
      starred: starSet
    };
  }
);

export const merchandisedOffersSelector = createSelector(
  offersSelector,
  merchandisingSelector,
  (offers, merchandising) => merchandising.map(offerId => ({ ...offers[offerId], offerId }))
);
