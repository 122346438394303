import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ProductSetTable } from '../components/rate-quote-request-product-set';
import {
  isPurchaseRQR,
  Offer,
  ProductSet as ProductSetComponent
} from '../modules/rate-quote/types';
import { seedInitialOffer } from '../redux/offer-details/actions';
import { offersSelector } from '../selectors/offer';
import { rateQuoteRequestSelector } from '../selectors/rate-quote-request';
import { productSetSelector, purposeSelector } from '../selectors/rate-quote/rate-quote';

export const ProductSet = () => {
  const dispatch = useDispatch();

  const properties = useSelector(
    createSelector(
      productSetSelector,
      purposeSelector,
      offersSelector,
      (data, purpose, offers) => ({
        data,
        purpose,
        offers
      })
    )
  );

  const sellerConcessions = useSelector(
    createSelector(
      rateQuoteRequestSelector,
      rqr => rqr.map(rqr => (isPurchaseRQR(rqr) ? rqr.sellerConcessions : undefined)).value
    )
  );

  const openOfferDetails = (payload: { lenderId: string; productSet: ProductSetComponent }) => {
    // Seed initial pmi value from previous offers with PMI that match the new offers LTV and
    // product term
    const pmi = (
      (Object.values(properties.offers) as Offer[])
        .filter(
          ({ attributes, monthlyPayment }) =>
            attributes.ltv === payload.productSet.ltv &&
            attributes.product.term === payload.productSet.product.term &&
            monthlyPayment.pmi != null
        )
        .sort((a, b) => (a.lastModifiedTime > b.lastModifiedTime ? -1 : 1))[0] || {
        monthlyPayment: {}
      }
    ).monthlyPayment.pmi;
    dispatch(seedInitialOffer({ ...payload, pmi, sellerConcessions }));
  };

  return <ProductSetTable {...{ ...properties, openOfferDetails }} />;
};
