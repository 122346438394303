import Conditional from '@rategravity/frontend/components/conditional';
import { styled, withStyle } from 'baseui';
import { StyledLink } from 'baseui/link';
import React, { useState } from 'react';
import { StyletronComponent } from 'styletron-react';
import { Errorable } from '../../redux/offer-details/state';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { H1, H5 } from '../headers';
import { UnlockIcon } from '../icons';
import { ArrowIcon } from '../icons';
import { Label2 } from '../labels';
import { Modal } from '../modal';
import { Notice } from '../notice';
import { AttributesPanel, LockOfferAttributes } from './attributes-panel';
import { LockCard } from './lock-card';
import { UnlockScreen } from './unlock-screen';

interface LockModalProps {
  data: Errorable<{
    loanId: string;
    attorney: string | undefined;
    rgTitle: boolean | undefined;
  }> & {
    notes: string;
    provider?: string;
    float: boolean;
  };
  attorneyRequired: boolean;
  offerData: LockOfferAttributes;
  link: string | null;
  locked: boolean;
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  networkError: string | null;
  validationError: string;
  updateField: ({ id, value }: { id: string; value: string | boolean }) => void;
  lockOffer: () => void;
  deleteLock: () => void;
  editLock: () => void;
  copyToClipboard: (link: string) => void;
  dealIds: Array<{ value: string; label: string }>;
  dealsError: string | null;
  updatedHubspot: boolean;
  disableOUTitle: boolean;
}

const ModalWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start'
});

const CardContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '65vw'
});

const ButtonContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  marginTop: '30px',
  marginBottom: '20px'
});

const ArrowLink = withStyle(StyledLink as StyletronComponent<any>, ({ $theme }) => ({
  color: $theme.colors!.primary400,
  fontWeight: 'normal'
}));

const ModalHeader = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '90%',
  marginRight: '30px'
});

const Header = ({ link, show }: { link: string | null; show: boolean }) => (
  <ModalHeader>
    <H1>Rate lock</H1>
    <Conditional show={!!link && show}>
      <Label2>
        Published Lock Form:&nbsp;
        <ArrowLink href={link} target="_blank">
          Download PDF
        </ArrowLink>
      </Label2>
    </Conditional>
  </ModalHeader>
);

const LinkContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});

const UnlockWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  whiteSpace: 'nowrap',
  alignItems: 'center'
});

const EmptyElement = styled('div', {});

const NoPaddingSecondaryButton = styled(SecondaryButton, {
  padding: '0px'
});

const CopyButton = withStyle(NoPaddingSecondaryButton, {});

const IconWrapper = styled('div', {
  paddingTop: '12px',
  paddingBottom: '12px',
  paddingLeft: '15px',
  paddingRight: '15px'
});

const GoToLinkArrow = withStyle(StyledLink as StyletronComponent<any>, ({ $theme }) => ({
  borderLeft: `1px solid ${$theme.colors!.primary200}`
}));

export const LockModal = ({
  data: { rgTitle, loanId, attorney, notes, provider, float },
  attorneyRequired,
  offerData,
  locked,
  isOpen,
  onClose,
  loading,
  networkError,
  link,
  validationError,
  updateField,
  lockOffer,
  deleteLock,
  editLock,
  copyToClipboard,
  dealIds,
  dealsError,
  updatedHubspot,
  disableOUTitle
}: LockModalProps) => {
  const [showValidationError, setShowValidationError] = useState(false);
  const [modalScreen, setModalScreen] = useState('card');
  const modalScreenValue = networkError && modalScreen !== 'unlock' ? 'card' : modalScreen;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setModalScreen('card');
      }}
      title={<Header link={link} show={!loading && modalScreenValue === 'card'} />}
      loading={loading}
      link={link}
      layout={'Centered'}
      buttons={[
        loading || modalScreenValue !== 'link' || !link ? (
          <EmptyElement />
        ) : (
          <NoPaddingSecondaryButton width={'Long'} disabled={loading}>
            <CopyButton
              width={'Long'}
              borderColor={'mono100'}
              onClick={() => copyToClipboard(link || '')}
            >
              Copy Link
            </CopyButton>
            <GoToLinkArrow href={link} target="_blank">
              <IconWrapper>
                <ArrowIcon />
              </IconWrapper>
            </GoToLinkArrow>
          </NoPaddingSecondaryButton>
        )
      ]}
    >
      <Conditional show={modalScreenValue === 'link' && !!link}>
        <LinkContainer>
          <Conditional show={!!link}>
            {updatedHubspot ? (
              <Notice message={'Hubspot properties updated!'} type="info" />
            ) : (
              <Notice message={'Error updating Hubspot properties'} />
            )}
          </Conditional>
          <H5>{'Your Rate Lock document is ready to preview.'}</H5>
        </LinkContainer>
      </Conditional>

      <Conditional show={modalScreenValue === 'card'}>
        <ModalWrapper>
          <AttributesPanel {...offerData} />
          <CardContainer>
            <Conditional show={!!validationError && showValidationError}>
              <Notice message={validationError} />
            </Conditional>
            <Conditional show={networkError !== null}>
              {networkError === 'pdf' ? (
                <Notice message={'No Lock Form Generated'} />
              ) : (
                <Notice message={'Network Error. Contact Administrator'} />
              )}
            </Conditional>
            <Conditional show={dealsError !== null}>
              <Notice message={dealsError as string} />
            </Conditional>
            <LockCard
              rgTitle={rgTitle}
              loanId={loanId}
              attorney={attorney}
              attorneyRequired={attorneyRequired}
              provider={provider}
              notes={notes}
              onChange={updateField}
              dealIds={dealIds}
              showValidationError={showValidationError}
              float={float}
              disableOUTitle={disableOUTitle}
            />
            <ButtonContainer>
              <PrimaryButton
                onClick={() => {
                  setShowValidationError(true);
                  if (!validationError) {
                    locked ? editLock() : lockOffer();
                    setModalScreen('link');
                    setShowValidationError(false);
                  }
                }}
              >
                Save
              </PrimaryButton>
              <Conditional show={locked}>
                <SecondaryButton onClick={() => setModalScreen('unlock')}>
                  <UnlockWrapper>
                    <UnlockIcon />
                    &nbsp;Undo Lock
                  </UnlockWrapper>
                </SecondaryButton>
              </Conditional>
            </ButtonContainer>
          </CardContainer>
        </ModalWrapper>
      </Conditional>
      <Conditional show={modalScreenValue === 'unlock'}>
        <UnlockScreen
          deleteLock={() => {
            deleteLock();
            setModalScreen('card');
          }}
          cancel={() => setModalScreen('card')}
        />
      </Conditional>
    </Modal>
  );
};
