import { toaster } from 'baseui/toast';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { toasterPositive } from '../../components/toaster';
import { isSameOffer } from '../../modules/offer-table/is-same-offer';
import {
  cacheProductDetails,
  createOffer,
  CreateOfferResult,
  getAllQuotes as fetchAllQuotes,
  getProductDetails as fetchProductDetails,
  GetAllQuotesResult,
  GetProductDetailsStandardizedResponse
} from '../../modules/rate-quote';
import { RateQuote } from '../../modules/rate-quote/types';
import { allQuotesSelector } from '../../selectors/all-quotes';
import { offersSelector } from '../../selectors/offer';
import { rateQuoteIdSelector } from '../../selectors/rate-quote/rate-quote';
import { createOfferSuccess } from '../rate-quote/actions';
import {
  addOfferToRQ,
  addOfferToRQComplete,
  getAllQuotes,
  getAllQuotesComplete,
  getAllQuotesError,
  getAllQuotesSuccess,
  getProductDetails,
  getProductDetailsFailure,
  getProductDetailsSuccess,
  toggleSameOfferModal
} from './slice';
import { AllQuotesRates } from './state';

function* handleGetAllQuotes({
  payload: { rateQuoteRequestId, filterOptions }
}: ReturnType<typeof getAllQuotes>) {
  try {
    let done = false;
    let token;
    let retry = 0;
    while (!done) {
      const result: GetAllQuotesResult = yield call(fetchAllQuotes, {
        rateQuoteRequestId,
        filterOptions,
        token
      });
      if (result.success) {
        const offers = result.json.offers;
        yield put(getAllQuotesSuccess({ offers }));
        // log errors from rate quote stack and reassign done and token
        console.log(`get all quotes errors: ${result.json.errors}`);
        done = !result.json.token;
        token = result.json.token;
      } else {
        // if we can't get rates back, retry up to 3 times and record the error
        yield put(getAllQuotesError({ error: result.error }));
        retry += 1;
        if (retry > 3) {
          break;
        }
      }
    }
    yield put(getAllQuotesComplete());
  } catch (err) {
    yield put(getAllQuotesError(err.message));
  }
}

function* handleGetProductDetails({
  payload: { productId, searchId }
}: ReturnType<typeof getProductDetails>) {
  try {
    const result: GetProductDetailsStandardizedResponse = yield call(fetchProductDetails, {
      productId,
      searchId
    });
    if (result.success) {
      const key = `${productId}${searchId}`;
      yield put(getProductDetailsSuccess(result.json));
      yield call(cacheProductDetails, { key, details: result.json });
    }
  } catch (err) {
    console.error('Could not get product details:', err.message);
    yield put(getProductDetailsFailure());
  }
}

function* handleAddOfferToRQ({ payload: { offerId } }: ReturnType<typeof addOfferToRQ>) {
  try {
    const rateQuoteId: string = yield select(rateQuoteIdSelector);
    const allQuotes: AllQuotesRates = yield select(allQuotesSelector);
    const offer = allQuotes[offerId];
    const currentOffers: RateQuote['offers'] = yield select(offersSelector);

    if (isSameOffer(currentOffers, offer)) {
      yield put(toggleSameOfferModal({ isOpen: true }));
      yield put(addOfferToRQComplete());
      return;
    }

    const { success, json, error }: CreateOfferResult = yield call(createOffer, {
      rateQuoteId,
      offer
    });
    yield put(addOfferToRQComplete());
    if (success) {
      const updatedOffer = json!.rateQuote.offers[json!.offerId];
      const offerPermutations = json!.rateQuote.offerPermutations;
      toasterPositive('Successfully added offer to the publishable Rate Quote');
      yield put(createOfferSuccess(updatedOffer, offerPermutations));
    } else {
      toaster.negative(`Error adding offer to publishable Rate Quote: ${error}`, {
        closeable: true
      });
    }
  } catch (err) {
    console.error('Could not add offer to Rate Quote:', err.message);
  }
}

export const allQuotesSaga = function*() {
  yield all([
    takeEvery(getAllQuotes.type, handleGetAllQuotes),
    takeEvery(getProductDetails.type, handleGetProductDetails),
    takeEvery(addOfferToRQ.type, handleAddOfferToRQ)
  ]);
};
