import { EditableOffer } from '../state';

export const ValidateOfferDetailsFormActionType = 'OFFER_DETAILS__VALIDATE_FORM' as const;
export const validateOfferDetailsForm = () => ({
  type: ValidateOfferDetailsFormActionType
});
export type ValidateOfferDetailsFormAction = ReturnType<typeof validateOfferDetailsForm>;

export const CloseOfferDetailsActionType = 'OFFER_DETAILS__CLOSE_MODAL' as const;
export const closeOfferDetails = () => ({ type: CloseOfferDetailsActionType });
export type CloseOfferDetailsAction = ReturnType<typeof closeOfferDetails>;

export const CacheModalStateActionType = 'OFFER_DETAILS__CACHE_MODAL' as const;
export const cacheModalState = (payload: { offer: EditableOffer; allQuotes: boolean }) => ({
  type: CacheModalStateActionType,
  payload
});
export type CacheModalStateAction = ReturnType<typeof cacheModalState>;

export const RestoreModalStateCacheActionType = 'OFFER_DETAILS__RESTORE_CACHED_MODAL' as const;
export const restoreModalStateCache = (payload: { offer: EditableOffer; allQuotes: boolean }) => ({
  type: RestoreModalStateCacheActionType,
  payload
});
export type RestoreModalStateAction = ReturnType<typeof restoreModalStateCache>;

export const ClearModalStateCacheActionType = 'OFFER_DETAILS__CLEAR_CACHED_MODAL' as const;
export const clearModalStateCache = () => ({
  type: ClearModalStateCacheActionType
});
export type ClearModalStateCacheAction = ReturnType<typeof clearModalStateCache>;
