import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { DetailsModal } from '../components/offer-details/fee-modal';
import { toasterPositive } from '../components/toaster';
import { Offer } from '../modules/rate-quote/types';
import {
  closeOfferDetails,
  updateOfferDetailsFee,
  updateOfferDetailsMonthlyPayment,
  updateOfferDetailsRateInfo,
  updatePrice,
  validateOfferDetailsForm
} from '../redux/offer-details/actions';
import { Nullable } from '../redux/offer-details/state';
import {
  createOfferRequest,
  merchandiseOffer,
  updateOffer as updateOfferAction
} from '../redux/rate-quote/actions';
import { manualPmiHelpers } from '../redux/rate-quote/sagas/helper/manual-pmi-helpers';
import { merchandisedOffersSelector, offersSelector } from '../selectors/offer';
import {
  monthlyPaymentSelector,
  offerDetailsAttributesSelector,
  offerDetailsFeesSelector,
  offerDetailsRateInfoSelector,
  offerDetailsSellerConcessionsSelector,
  offerDetailsToOfferSelector,
  offerDetailsUISelector,
  offerDetailsValidFormSelector,
  showPMISelector
} from '../selectors/offer-details';
import { rateQuoteIdSelector } from '../selectors/rate-quote/rate-quote';

const offerDetailsSelector = createSelector(
  offerDetailsFeesSelector,
  offerDetailsToOfferSelector,
  offerDetailsUISelector,
  rateQuoteIdSelector,
  offerDetailsRateInfoSelector,
  offerDetailsAttributesSelector,
  monthlyPaymentSelector,
  offerDetailsValidFormSelector,
  merchandisedOffersSelector,
  offersSelector,
  showPMISelector,
  offerDetailsSellerConcessionsSelector,
  (
    fees,
    offer,
    formUIState,
    rateQuoteId,
    productDetails,
    fullAttributes,
    monthlyPaymentData,
    trueFormValid,
    merchandisedOffers,
    allOffers,
    showPMI,
    sellerConcessions
  ) => ({
    ...fees,
    ...formUIState,
    rateQuoteId,
    offer,
    productDetails,
    fullAttributes,
    validationError: formUIState.validateForm ? !trueFormValid : false,
    monthlyPaymentData,
    trueFormValid,
    merchandisedOffers,
    allOffers,
    showPMI,
    sellerConcessions
  })
);

export const OfferDetailsModal = () => {
  const dispatch = useDispatch();
  const {
    offer,
    rateQuoteId,
    trueFormValid,
    merchandisedOffers,
    allOffers,
    ...props
  } = useSelector(offerDetailsSelector);

  const [copyPMIValue, setCopyPMIValue] = useState(false);

  const numManualPMIOffers = useMemo(() => manualPmiHelpers(offer as Offer, allOffers).length, [
    offer,
    allOffers
  ]);

  const updateFee = useCallback(
    (payload: { id: string; value: number | null | undefined }) =>
      dispatch(updateOfferDetailsFee(payload)),
    [dispatch]
  );

  const updateRateInfo = useCallback(
    (payload: Partial<Nullable<Offer['productDetails']>>) =>
      dispatch(updateOfferDetailsRateInfo(payload)),
    [dispatch]
  );

  const updatePriceField = useCallback(
    (payload: { price: number | undefined }) => dispatch(updatePrice(payload)),
    [dispatch]
  );

  const updateMonthlyPayment = useCallback(
    (payload: Offer['monthlyPayment']) => dispatch(updateOfferDetailsMonthlyPayment(payload)),
    [dispatch]
  );

  const onClose = useCallback(() => {
    dispatch(closeOfferDetails());
    setCopyPMIValue(false);
  }, [dispatch]);

  const addOffer = useCallback((): boolean => {
    if (trueFormValid) {
      const offerIndex = Object.values(allOffers).findIndex(o => o.offerId === offer.offerId);
      if (offerIndex > -1) {
        dispatch(updateOfferAction(rateQuoteId, offer as Partial<Offer>, copyPMIValue));
        dispatch(merchandiseOffer(offer.offerId));
        return true;
      } else {
        dispatch(createOfferRequest({ rateQuoteId, offer: offer as Offer, copyPMIValue }));
        return true;
      }
    } else {
      dispatch(validateOfferDetailsForm());
      return false;
    }
  }, [trueFormValid, allOffers, offer, dispatch, rateQuoteId, copyPMIValue]);

  return (
    <DetailsModal
      {...{
        ...props,
        updateFee,
        updateRateInfo,
        updateMonthlyPayment,
        onClose,
        addOffer,
        canEdit: !offer.locked,
        updatePriceField,
        setCopyPMIValue: () => {
          if (!copyPMIValue) {
            toasterPositive(`PMI successfully copied to ${numManualPMIOffers} offer(s)`);
          } else {
            toasterPositive(`PMI change for ${numManualPMIOffers} offer(s) reverted`);
          }
          setCopyPMIValue(!copyPMIValue);
        },
        copyPMIValue,
        numManualPMIOffers
      }}
    />
  );
};
