import * as t from 'type-shift';

export interface Deal {
  dealId: string;
  properties?: Record<
    string,
    {
      value: string;
      timestamp: number;
      source: string | null;
      sourceId: null | unknown;
    }
  >;
}

const dealConverter = t.strict<Deal>({
  dealId: t.string,
  properties: t.optional(
    t.record(
      t.strict({
        value: t.string,
        timestamp: t.number,
        source: t.string.or(t.null),
        sourceId: t.null.or(t.unknown)
      })
    )
  )
});

export const dealsConverter = t.record(dealConverter);
