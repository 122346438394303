"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loan_1 = require("./loan");
/**
 * Regular MI / PMI computation. At the begining of each month
 * MI is charged if the ltv is below .78, this is a legal
 * requirement for Lenders, and generally you can get out of
 * MI before you hit 78% LTV.
 */
exports.regularMI = (loan, amortization) => computeRegularMI({
    propertyValue: loan_1.isRefinance(loan) ? loan.propertyValue : loan.purchasePrice,
    mortgageInsurance: loan.mortgageInsurance,
    upFrontMip: loan.upMip
}, amortization);
/**
 * Enhances an amortization schedule with PMI payments
 */
function computeRegularMI(inputs, amortization) {
    return amortization.map(amortizationPeriod => {
        // only include pmi if the starting balance is more than 78% of the loan
        // lenders are required to remove the pmi after you have 22% equity.
        return {
            ...amortizationPeriod,
            mortgageInsurance: (amortizationPeriod.balanceAtEndOfMonth + amortizationPeriod.principle) /
                inputs.propertyValue >
                0.78
                ? inputs.mortgageInsurance
                : 0
        };
    });
}
exports.computeRegularMI = computeRegularMI;
/**
 * FHA MIP computation, based on LTV apply MIP to the
 * Amortization Schedule
 */
exports.fhaMIP = (loan, amortizationSchedule) => computeFHAMip({
    propertyValue: loan_1.isRefinance(loan) ? loan.propertyValue : loan.purchasePrice,
    mortgageInsurance: loan.mortgageInsurance,
    upFrontMip: loan.upMip
}, amortizationSchedule);
/**
 * Enhances an amortization schedule with FHA MIP payments
 */
function computeFHAMip(inputs, amortization) {
    const loanValue = amortization[0].balanceAtEndOfMonth +
        amortization[0].interest +
        amortization[0].principle -
        (inputs.upFrontMip || 0);
    return loanValue / inputs.propertyValue > 0.9
        ? amortization.map(period => ({
            ...period,
            // more than 90% LTV you pay the mortgage insurance
            // every month
            mortgageInsurance: inputs.mortgageInsurance
        }))
        : amortization.map((period, index) => ({
            ...period,
            // Must pay mortgage insurance for 11 years.
            mortgageInsurance: index + 1 > 12 * 11 ? 0 : inputs.mortgageInsurance
        }));
}
exports.computeFHAMip = computeFHAMip;
function unwrapApplyMI(applyMI) {
    if (applyMI === exports.regularMI) {
        return computeRegularMI;
    }
    if (applyMI === exports.fhaMIP) {
        return computeFHAMip;
    }
    return (inputs, amortization) => applyMI({
        propertyValue: inputs.propertyValue,
        upMip: inputs.upFrontMip,
        mortgageInsurance: inputs.mortgageInsurance,
        remainingLoanBalance: amortization[0].balanceAtEndOfMonth +
            amortization[0].interest +
            amortization[0].principle -
            (inputs.upFrontMip || 0),
        cashOut: 0,
        rate: 0,
        product: {
            fixedTerm: 30,
            loanTerm: 30
        },
        pAndI: 0,
        fees: [],
        prepaids: []
    }, amortization);
}
exports.unwrapApplyMI = unwrapApplyMI;
