import { styled } from 'baseui';
import { ChevronDown } from 'baseui/icon';
import { OnChangeParams, Option, StatefulSelect } from 'baseui/select';
import React from 'react';
import { FieldType } from '../../../modules/types';
import { ErrorLabel2, Label2 } from '../../labels';

interface ExtendedOptions<V> extends Option {
  value: V;
}

interface SelectType<V> extends FieldType<V> {
  options: Array<ExtendedOptions<V>>;
  clearable?: boolean;
  getInitialState?: (
    o: Array<ExtendedOptions<V>>,
    v: V | undefined
  ) => { value: Array<ExtendedOptions<V>> };
}

const SelectContainer = styled('div', () => ({
  height: '40px',
  width: '100%'
}));

const ChevronIcon = styled(ChevronDown, () => ({
  cursor: 'pointer'
}));

const Chevron = ({ disabled }: { disabled: boolean }) =>
  disabled ? null : <ChevronIcon size={24} />;

export const Select = <V,>({
  label,
  options,
  disabled,
  error,
  value,
  clearable,
  defaultValue,
  onChange = () => null
}: SelectType<V>) => (
  <SelectContainer>
    {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
    <StatefulSelect
      disabled={disabled}
      options={options}
      valueKey="value"
      placeholder={defaultValue}
      clearable={clearable}
      onChange={(params: OnChangeParams) => {
        if (params!.value) {
          const val = params!.value[0] || {};
          onChange(val.value);
        }
      }}
      initialState={{ value: options.filter(({ value: selectValue }) => selectValue === value) }}
      overrides={{
        ControlContainer: {
          style: ({ $theme }) => ({
            opacity: disabled ? 0.6 : 1,
            backgroundColor: disabled ? $theme.colors!.mono200 : 'white',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: error ? $theme.colors!.negative400 : $theme.colors!.mono300
          })
        },
        Dropdown: {
          style: {
            backgroundColor: 'white',
            width: '100%'
          }
        },
        SelectArrow: {
          component: Chevron,
          props: { disabled },
          style: ({ $theme }) => ({
            color: $theme.colors!.primary400
          })
        }
      }}
    />
  </SelectContainer>
);
