import {
  all,
  AllEffect,
  call,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery
} from 'redux-saga/effects';
import {
  clearMerchandisingCacheState,
  storeMerchandisingCacheState
} from '../../../modules/offer-details/local-storage';
import { createOffer, CreateOfferResult } from '../../../modules/rate-quote';
import { updateOffer, UpdateOfferResult } from '../../../modules/rate-quote';
import { MerchandisingPayload } from '../../../modules/rate-quote/merchandising';
import { Offer } from '../../../modules/rate-quote/types';
import { merchandisingPayloadSelector } from '../../../selectors/offer';
import { rateQuoteIdSelector } from '../../../selectors/rate-quote/rate-quote';
import {
  ClearMerchandisingFromCacheAction,
  ClearMerchandisingFromCacheActionType,
  CreateOfferActionType,
  createOfferError,
  CreateOfferRequestAction,
  createOfferSuccess,
  CreateOfferSuccessAction,
  CreateOfferSuccessActionType,
  merchandiseOffer,
  MerchandiseOfferActionType,
  ReorderOffersActionType,
  saveMerchandising,
  storeOffer,
  StoreOfferActionType,
  synchronizeMerchandisingCache,
  SynchronizeMerchandisingCacheAction,
  SynchronizeMerchandisingCacheActionType,
  UnmerchandiseOfferActionType,
  UpdateOfferAction,
  UpdateOfferActionType,
  updateOfferError,
  updateOfferSuccess
} from '../actions';
import { manualPmiHelpers } from './helper/manual-pmi-helpers';

function* handleCreateOfferRequest({ payload }: CreateOfferRequestAction) {
  try {
    const { success, error, json }: CreateOfferResult = yield call(createOffer, payload);
    if (success) {
      const updatedOffer = json!.rateQuote.offers[json!.offerId];
      const offerPermutations = json!.rateQuote.offerPermutations;
      try {
        if (payload.copyPMIValue) {
          yield* handleUpdatePMIOffersManually(payload.rateQuoteId, updatedOffer);
        }
        yield put(createOfferSuccess(updatedOffer, offerPermutations));
      } catch (err) {
        yield put(createOfferError(err));
      }
    } else {
      yield put(createOfferError(error!));
    }
  } catch (err) {
    yield put(createOfferError(err.message));
  }
}

function* handleCreateOfferSuccess({
  payload: {
    offer: { offerId }
  }
}: CreateOfferSuccessAction) {
  yield put(merchandiseOffer(offerId));
}

function* updateMerchandisingStateCache() {
  yield put(synchronizeMerchandisingCache());
}

function* handleSynchronizeMerchandisingCache(_: SynchronizeMerchandisingCacheAction) {
  const rateQuoteId: string = yield select(rateQuoteIdSelector);
  const merchandising: MerchandisingPayload = yield select(merchandisingPayloadSelector);
  yield call(storeMerchandisingCacheState, rateQuoteId, merchandising);
}

function* handleClearMerchCache({ payload }: ClearMerchandisingFromCacheAction) {
  yield call(clearMerchandisingCacheState, payload);
}

export function* handleUpdateOfferAction({
  payload: { rateQuoteId, offerData, copyPMIValue }
}: UpdateOfferAction) {
  if (offerData.offerId) {
    try {
      const { success, error, json }: UpdateOfferResult = yield call(updateOffer, {
        offer: offerData,
        rateQuoteId
      });

      if (success) {
        try {
          if (copyPMIValue) {
            yield* handleUpdatePMIOffersManually(rateQuoteId, offerData);
          }
          yield put(storeOffer(offerData.offerId, json!.offers[offerData.offerId]));
          yield put(updateOfferSuccess());
        } catch (err) {
          yield put(updateOfferError(err));
        }
      } else {
        yield put(updateOfferError(error!));
      }
    } catch (err) {
      yield put(updateOfferError(err.message));
    }
  } else {
    yield put(saveMerchandising(true));
    yield put(updateOfferSuccess());
  }
}

export function* handleUpdatePMIOffersManually(
  rateQuoteId: string,
  updatedOffer: Partial<Offer>
): Generator<SelectEffect | PutEffect | AllEffect<any>> {
  const offers = yield select(s => s.rateQuote.rateQuote.offers);
  const offersToModify = manualPmiHelpers(updatedOffer, offers as Record<string, Offer>).filter(
    ({ monthlyPayment }) => monthlyPayment.pmi !== updatedOffer?.monthlyPayment?.pmi
  );

  yield all(
    offersToModify.map(offer =>
      handleUpdatePMIOffer(offer, rateQuoteId, updatedOffer?.monthlyPayment?.pmi || 0)
    )
  );
}

function* handleUpdatePMIOffer(offer: Offer, rateQuoteId: string, pmi: number) {
  const { success, error, json }: UpdateOfferResult = yield call(updateOffer, {
    offer: { ...offer, monthlyPayment: { ...offer.monthlyPayment, pmi } },
    rateQuoteId
  });

  if (success) {
    yield put(storeOffer(offer.offerId, json!.offers[offer.offerId]));
  } else {
    throw new Error(error || '');
  }
}

function* queueSaveMerchandising() {
  yield put(saveMerchandising(false));
}

export const offersSaga = function*() {
  yield all([
    takeEvery(CreateOfferActionType, handleCreateOfferRequest),
    takeEvery(CreateOfferSuccessActionType, handleCreateOfferSuccess),
    takeEvery(SynchronizeMerchandisingCacheActionType, handleSynchronizeMerchandisingCache),
    takeEvery(ClearMerchandisingFromCacheActionType, handleClearMerchCache),
    takeEvery(UpdateOfferActionType, handleUpdateOfferAction),
    // Actions that trigger updates to the merchandising array in the background.
    takeEvery(
      [
        StoreOfferActionType,
        ReorderOffersActionType,
        MerchandiseOfferActionType,
        UnmerchandiseOfferActionType
      ],
      queueSaveMerchandising
    ),
    // Merchandising cache sync actions
    takeEvery(
      [
        MerchandiseOfferActionType,
        UpdateOfferActionType,
        StoreOfferActionType,
        UnmerchandiseOfferActionType
      ],
      updateMerchandisingStateCache
    )
  ]);
};
