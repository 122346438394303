import Conditional from '@rategravity/frontend/components/conditional';
import { currency, rate as percent } from '@rategravity/frontend/modules/numbers';
import { styled } from 'baseui';
import React from 'react';
import { useConditionalColumns } from '../../hooks/use-conditional-columns';
import { rateLockDaysLabel } from '../../modules/offer-details/rate-lock-days-label';
import { Offer } from '../../modules/rate-quote/types';
import { FullStarIcon, StarIcon } from '../icons';
import { Link } from '../link';
import { SortableTable } from '../table-sortable';
import { NotificationGroup } from './notification-group';
import { StatusNotification } from './status-notification';
import { TimeAndButtons } from './time-and-buttons';

export interface OfferTableProps {
  offerId: string;
  starred: boolean;
  excluded: boolean;
  label: string;
  lender: {
    name: string;
    url: string;
  };
  product: string;
  rate: number;
  apr: number;
  interestOptions: string;
  pAndI?: number;
  mi: { value?: number; required: boolean };
  rateLockPeriod: number | null;
  closingCosts: number;
  lenderCredit: number;
  points: number;
  status: 'Stale' | 'Complete' | 'Incomplete';
  lastModifiedTime: number;
  totalLoanAmount: number;
  method: 'Manual' | 'Automated' | 'AllQuotes';
  expired: boolean;
  locked: boolean;
  dti: number;
  dtiStatus: string | null;
  conf: string;
}

const IconWrapper = styled('button', ({ $theme }) => ({
  color: $theme.colors!.mono500,
  backgroundColor: 'transparent',
  borderWidth: 0,
  outline: 'none',
  cursor: 'pointer'
}));

const LenderWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column'
});

const Lender = ({
  method,
  name,
  expired,
  url,
  dtiStatus
}: {
  method: string;
  name: string;
  expired: boolean;
  url: string;
  dtiStatus: string;
}) => (
  <LenderWrapper>
    <Link href={url}>{name}</Link>
    <NotificationGroup
      dtiStatus={dtiStatus}
      expired={expired}
      method={method !== 'Automated' ? method : undefined}
    />
  </LenderWrapper>
);

const columnsToCheck = {
  label: '' as OfferTableProps['label'],
  points: (p: OfferTableProps['points']) => p === 0,
  mi: ({ required }: OfferTableProps['mi']) => !required,
  conf: 'Conf.'
};

export const CheckableTable = ({
  data,
  showLock,
  validLock,
  storeOffer,
  editOffer,
  deleteOffer,
  includeOffer,
  merchandisedOffers,
  seedLock,
  getLockOffer
}: {
  data: OfferTableProps[];
  showLock: boolean;
  validLock: boolean;
  storeOffer: (offerId: string, payload: Partial<Offer>) => void;
  editOffer: (offerId: string) => void;
  deleteOffer: (offerId: string) => void;
  includeOffer: (offerId: string) => void;
  merchandisedOffers: string[];
  seedLock: (offerId: string) => void;
  getLockOffer: (offerId: string) => void;
}) => {
  const conditionalColumns = useConditionalColumns(columnsToCheck, data);
  const nameValue = (data[0] || {}).interestOptions === 'interestOnly' ? 'INTEREST' : 'P&I';
  return (
    <SortableTable
      data={data}
      columns={[
        {
          name: '',
          // eslint-disable-next-line react/display-name
          format: ({ starred, offerId }: any) => (
            <IconWrapper
              onClick={() => {
                storeOffer(offerId, { starred: !starred });
              }}
            >
              {starred ? <FullStarIcon /> : <StarIcon />}
            </IconWrapper>
          )
        },
        {
          name: 'LENDER',
          // eslint-disable-next-line react/display-name
          format: ({ lender, method, expired, dtiStatus }: any) => (
            <Lender
              method={method}
              dtiStatus={dtiStatus}
              name={lender.name}
              expired={expired}
              url={lender.url}
            />
          ),
          sort: (data1, data2) =>
            data1.lender.name.toLowerCase() > data2.lender.name.toLowerCase() ? 1 : -1
        },
        {
          name: 'LOAN',
          format: ({ totalLoanAmount }: any) => currency(totalLoanAmount, 0),
          selector: ({ totalLoanAmount }) => totalLoanAmount
        },
        {
          name: 'LOCK',
          format: ({ rateLockPeriod }: any) => rateLockDaysLabel(rateLockPeriod),
          selector: ({ rateLockPeriod }) => rateLockPeriod || NaN
        },
        {
          name: 'RATE',
          format: ({ rate }: any) => percent(rate, 3),
          selector: ({ rate }) => rate
        },
        {
          name: 'APR',
          format: ({ apr }: any) => percent(apr, 3),
          selector: ({ apr }) => apr
        },
        {
          name: nameValue,
          format: ({ pAndI }: any) => (pAndI ? currency(pAndI, 0) : '-'),
          selector: ({ pAndI }) => pAndI || NaN
        },
        {
          name: 'MI',
          format: ({ mi: { value } }) => (value ? currency(value, 0) : '-'),
          selector: ({ mi: { value } }) => value || NaN,
          exclude: conditionalColumns.mi
        },
        {
          name: 'LC',
          format: ({ lenderCredit }) => currency(lenderCredit, 0),
          selector: ({ lenderCredit }) => lenderCredit
        },
        {
          name: 'POINTS',
          format: ({ points }) => currency(points, 0),
          selector: ({ points }) => points,
          exclude: conditionalColumns.points
        },
        {
          name: 'CLOSING',
          format: ({ closingCosts }: any) => currency(closingCosts, 0),
          selector: ({ closingCosts }) => closingCosts
        },
        {
          name: 'CONF.',
          format: ({ conf }: any) => conf,
          selector: ({ conf }) => conf,
          exclude: conditionalColumns.conf
        },
        {
          name: 'LABEL',
          format: ({ label }: any) => label,
          exclude: conditionalColumns.label,
          columnStyle: { maxWidth: '120px' }
        },
        {
          name: 'DTI',
          format: ({ dti }) => percent(dti * 100, 1)
        },
        {
          name: 'STATUS',
          // eslint-disable-next-line react/display-name
          format: ({ status }: any, hover) => (
            <Conditional show={!hover}>{StatusNotification({ status })}</Conditional>
          )
        },
        {
          name: 'UPDATED',
          // eslint-disable-next-line react/display-name
          format: ({ lastModifiedTime, offerId, excluded, method, locked, status }: any, hover) => (
            <TimeAndButtons
              {...{
                offerId,
                lastModifiedTime,
                editOffer,
                deleteOffer,
                includeOffer,
                seedLock,
                getLockOffer,
                excluded,
                showButtons: hover,
                validLock,
                method,
                locked,
                showLock,
                status
              }}
            />
          ),
          columnStyle: { width: '130px', opacity: '1' }
        }
      ]}
      defaultSort={[
        ['RATE', 'ASC'],
        ['CLOSING', 'ASC'],
        [nameValue, 'ASC']
      ]}
      includeList={merchandisedOffers}
    />
  );
};
