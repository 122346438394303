import { RateQuote } from '../../modules/rate-quote/types';

export const CloseLinkModalActionType = 'LINK_MODAL__CLOSE_ACTION' as const;
export const closeLinkModal = () => ({ type: CloseLinkModalActionType });
export type CloseLinkModal = ReturnType<typeof closeLinkModal>;

// Publish

export const PublishRateQuoteActionType = 'RATE_QUOTE__PUBLISH_REQUEST_ACTION' as const;
export const publishRateQuoteRequest = (payload: string) => ({
  type: PublishRateQuoteActionType,
  payload
});
export type PublishRateQuoteRequestAction = ReturnType<typeof publishRateQuoteRequest>;

export const PublishRateQuoteSuccessActionType = 'RATE_QUOTE__PUBLISH_SUCCESS_ACTION' as const;
export const publishRateQuoteSuccess = (payload: { rateQuote: RateQuote; link: string }) => ({
  type: PublishRateQuoteSuccessActionType,
  payload
});
export type PublishRateQuoteSuccessAction = ReturnType<typeof publishRateQuoteSuccess>;

export const PublishRateQuoteFailureActionType = 'RATE_QUOTE__PUBLISH_FAILURE_ACTION' as const;
export const publishRateQuoteFailure = (payload: string) => ({
  type: PublishRateQuoteFailureActionType,
  payload
});
export type PublishRateQuoteFailureAction = ReturnType<typeof publishRateQuoteFailure>;

// Preview

export const PreviewRateQuoteActionType = 'RATE_QUOTE__PREVIEW_REQUEST_ACTION' as const;
export const previewRateQuoteRequest = (payload: string) => ({
  type: PreviewRateQuoteActionType,
  payload
});
export type PreviewRateQuoteAction = ReturnType<typeof previewRateQuoteRequest>;

export const PreviewRateQuoteSuccessActionType = 'RATE_QUOTE__PREVIEW_SUCCESS_ACTION' as const;
export const previewRateQuoteSuccess = (payload: string) => ({
  type: PreviewRateQuoteSuccessActionType,
  payload
});
export type PreviewRateQuoteSuccessAction = ReturnType<typeof previewRateQuoteSuccess>;

export const PreviewRateQuoteRequestFailureActionType = 'RATE_QUOTE__PREVIEW_FAILURE_ACTION' as const;
export const previewRateQuoteFailure = (payload: string) => ({
  type: PreviewRateQuoteRequestFailureActionType,
  payload
});
export type PreviewRateQuoteFailureAction = ReturnType<typeof previewRateQuoteFailure>;

// Submit

export const SubmitRateQuoteActionType = 'RATE_QUOTE__SUBMIT_REQUEST_ACTION' as const;
export const submitRateQuoteRequest = (payload: {
  rateQuoteId: string;
  rateQuoteRequestId: string;
}) => ({
  type: SubmitRateQuoteActionType,
  payload
});
export type SubmitRateQuoteAction = ReturnType<typeof submitRateQuoteRequest>;

export const SubmitRateQuoteSuccessActionType = 'RATE_QUOTE__SUBMIT_SUCCESS_ACTION' as const;
export const submitRateQuoteSuccess = () => ({
  type: SubmitRateQuoteSuccessActionType
});
export type SubmitRateQuoteSuccessAction = ReturnType<typeof submitRateQuoteSuccess>;

export const SubmitRateQuoteFailureActionType = 'RATE_QUOTE__SUBMIT_FAILURE_ACTION' as const;
export const submitRateQuoteFailure = (payload: string) => ({
  type: SubmitRateQuoteFailureActionType,
  payload
});
export type SubmitRateQuoteFailureAction = ReturnType<typeof submitRateQuoteFailure>;
