import { useTreatments } from '@splitsoftware/splitio-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { OwnUpHeader } from './components/ownup-header';
import { pickFromPath } from './modules/pickFromUrl';
import { fetchRateQuoteError, fetchRateQuoteRequest } from './redux/rate-quote/actions';
import { ProgressiveLoading } from './views/progressive-loading';
import { RateQuotePanel } from './views/rate-quote-panel';
import { SavePrompt } from './views/save-prompt';

export const App = () => {
  const dispatch = useDispatch();
  let { rateQuoteId } = useParams<{ rateQuoteId: string }>();
  const {
    all_quotes: { treatment }
  } = useTreatments(['all_quotes']);
  const showAllQuotes = treatment === 'on';

  if (!rateQuoteId) {
    rateQuoteId = pickFromPath(window.location.pathname);
  }

  rateQuoteId
    ? dispatch(fetchRateQuoteRequest(rateQuoteId, showAllQuotes))
    : dispatch(fetchRateQuoteError('Missing ID', 404));

  return (
    <React.Fragment>
      <ProgressiveLoading />
      <OwnUpHeader />
      <RateQuotePanel showAllQuotes={showAllQuotes} />
      <SavePrompt />
    </React.Fragment>
  );
};
