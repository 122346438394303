import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchDealsBySourceId, FetchDealsResult } from '../../modules/deals/api';
import {
  FetchDealsAction,
  FetchDealsActionType,
  fetchDealsError,
  fetchDealsSuccess
} from './actions';

export function* handleFetchDeals({ payload: accountId }: FetchDealsAction) {
  try {
    const { success, error, json }: FetchDealsResult = yield call(fetchDealsBySourceId, accountId);
    if (success && json) {
      yield put(fetchDealsSuccess(json));
    } else {
      yield put(fetchDealsError(error!));
    }
  } catch (err) {
    yield put(fetchDealsError(err.message));
  }
}

export function* dealsSaga() {
  yield all([takeEvery(FetchDealsActionType, handleFetchDeals)]);
}
