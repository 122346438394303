import { dollarMask, percentMask } from '@rategravity/frontend/modules/masks';
import { styled } from 'baseui';
import { Checkbox as CheckBoxButton } from 'baseui/checkbox';
import { Input as BaseInput, InputOverrides } from 'baseui/input';
import { ALIGN, Radio as RadioButton, RadioGroup } from 'baseui/radio';
import { Textarea } from 'baseui/textarea';
import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';
import { RecalculateIcon } from '../../icons';
import { ErrorLabel2, Label2 } from '../../labels';

const InputContainer = styled('div', {
  height: '40px',
  width: '100%'
});

const clean = (value: string, inputType?: string) => {
  switch (inputType) {
    case 'money':
    case 'percent':
      return value.replace(/[^\d.-]/g, '');
    default:
      return value;
  }
};

const format = (value: string, inputType: string) => {
  if (inputType === 'percent' && value) {
    return Number(value).toFixed(3) + '%';
  } else if (inputType === 'money' && value) {
    return '$' + Number(value).toFixed(0);
  }
  return value;
};

const commonOverrides: InputOverrides = {
  InputContainer: {
    style: ({ $theme, $disabled, $error }) => ({
      backgroundColor: $disabled ? $theme.colors!.mono100 : 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: $error ? $theme.colors!.negative400 : $theme.colors!.mono300,
      position: 'relative'
    })
  }
};

interface NumberInputProps {
  label: string;
  value?: number | null;
  disabled?: boolean;
  defaultValue?: number | null;
  error?: boolean;
  inputType?: string;
  onChange?: (event: { value: number | undefined; recalculated: boolean }) => void;
}

export const NumberInput = ({
  label,
  value,
  disabled,
  defaultValue,
  error = false,
  onChange = () => {
    // noop
  },
  inputType
}: NumberInputProps) => {
  const [beingEdited, setEditStatus] = useState(false);
  const [trailingValues, setTrailing] = useState('');

  const baseDefaultValue = defaultValue == null ? '' : defaultValue.toString();
  const baseValue =
    value === null ? baseDefaultValue : value === undefined ? '' : value + trailingValues;
  const maskedControlledValue = format(baseValue, inputType || '');
  const inputValue = beingEdited ? baseValue : maskedControlledValue;

  const recalculable = baseDefaultValue !== '';
  // if manualValue is null then you can recalculate
  const disableRecalculate = value === null || value === defaultValue;

  const recalculate = () => {
    onChange({ value: Number(baseDefaultValue) || 0, recalculated: true });
  };

  const setTrailingChars = (input: string) => {
    const trailingCharsRegEx = input.match(/\.$|\.0+$|(?<=\.\d)0+$/);
    const trailingChars = trailingCharsRegEx ? trailingCharsRegEx[0] : '';
    setTrailing(trailingChars);
  };

  const recalculableEnhancer = () => {
    if (recalculable) {
      return (
        <div
          onClick={recalculate}
          style={{
            cursor: disableRecalculate ? 'default' : 'pointer',
            position: 'absolute',
            opacity: disableRecalculate ? 0.25 : 1,
            paddingRight: '10px',
            paddingTop: '12px',
            right: 0
          }}
        >
          <RecalculateIcon />
        </div>
      );
    }
    return null;
  };

  const overrides = {
    ...commonOverrides,
    Input: {
      style: (overrideRecalculable: any) => ({
        width: overrideRecalculable ? '90%' : '100%'
      })
    },
    After: recalculableEnhancer
  };

  switch (inputType) {
    case 'money':
      return (
        <InputContainer>
          {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
          <MaskedInput
            mask={(rawInput: string) =>
              (['$'] as Array<string | RegExp>).concat(
                dollarMask(false, false)(clean(rawInput, 'money'))
              )
            }
            type="text"
            value={inputValue}
            disabled={disabled}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const cleanInput = clean(event.currentTarget.value, 'money');
              setTrailingChars(cleanInput);
              onChange({
                value: cleanInput === '' ? undefined : Number(cleanInput),
                recalculated: false
              });
            }}
            render={(ref: (inputElement: HTMLElement) => void, props: any) => (
              <BaseInput inputRef={ref} {...props} overrides={overrides} error={error} />
            )}
          />
        </InputContainer>
      );
    case 'percent':
      return (
        <InputContainer>
          {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
          <MaskedInput
            value={inputValue}
            mask={(rawInput: string) => {
              const decimals = rawInput.split('.')[1] || '';
              return percentMask(3)(rawInput).concat(decimals.search('%') > -1 ? ['%'] : []);
            }}
            type="text"
            disabled={disabled}
            render={(ref: (inputElement: HTMLElement) => void, props: any) => (
              <BaseInput
                inputRef={ref}
                {...props}
                error={error}
                overrides={overrides}
                onBlur={() => {
                  setEditStatus(false);
                }}
                onFocus={() => {
                  setEditStatus(true);
                }}
              />
            )}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const cleanInput = clean(event.currentTarget.value, 'percent');
              setTrailingChars(cleanInput);
              onChange({
                value: cleanInput === '' ? undefined : Number(cleanInput),
                recalculated: false
              });
            }}
          />
        </InputContainer>
      );
    default:
      return (
        <InputContainer>
          {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
          <BaseInput
            value={inputValue}
            onChange={(event: React.FormEvent<HTMLInputElement>) => {
              const cleanInput = event.currentTarget.value;
              setTrailingChars(cleanInput);
              onChange({
                value: cleanInput === '' ? undefined : Number(cleanInput),
                recalculated: false
              });
            }}
            disabled={disabled}
            overrides={overrides}
            error={error}
          />
        </InputContainer>
      );
  }
};

interface StringInputProps {
  label: string;
  value?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: (value: string | undefined) => void;
}

export const StringInput = ({
  label,
  value = '',
  disabled,
  error = false,
  onChange = () => {
    // noop
  }
}: StringInputProps) => (
  <InputContainer>
    {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
    <BaseInput
      value={value}
      overrides={{
        ...commonOverrides,
        Input: {
          style: overrideRecalculable => ({
            width: overrideRecalculable ? '90%' : '100%'
          })
        }
      }}
      disabled={disabled}
      error={error}
      onChange={event => {
        onChange(event.currentTarget.value);
      }}
    />
  </InputContainer>
);

interface TextareaInputProps {
  label: string;
  value?: string;
  error?: boolean;
  resizeable?: boolean;
  onChange?: (value: string | undefined) => void;
}

export const TextareaInput = ({
  label,
  value = '',
  error = false,
  resizeable = false,
  onChange = () => {
    // noop
  }
}: TextareaInputProps) => (
  <InputContainer>
    {error ? <ErrorLabel2>{label}</ErrorLabel2> : <Label2>{label}</Label2>}
    <Textarea
      value={value}
      overrides={{
        ...commonOverrides,
        Input: {
          style: {
            resize: resizeable ? 'both' : 'none'
          }
        }
      }}
      error={error}
      onChange={event => {
        onChange(event.currentTarget.value);
      }}
    />
  </InputContainer>
);

interface CheckBoxProps {
  checked: boolean; // if checkbox is an indeterminate, true - some or all checked; false - no checkboxes are checked
  isIndeterminate?: boolean; // leave empty if regular checkbox, if indeterminate true - some boxes checked; false - if all boxes checked
  labelPlacement?: 'right' | 'left' | 'top' | 'bottom';
  onChange?: (value: boolean) => void;
  children?: any;
}

export const CheckBox = ({
  checked,
  isIndeterminate = false,
  labelPlacement = 'right',
  onChange = () => {
    // noop
  },
  children
}: CheckBoxProps) => {
  return (
    <CheckBoxButton
      checked={checked}
      isIndeterminate={isIndeterminate}
      labelPlacement={labelPlacement}
      onChange={e => onChange(e.currentTarget.checked)}
      overrides={{
        Label: {
          style: ({ $theme }) => ({
            color: $theme.colors!.mono700,
            fontFamily: $theme.typography!.font100.fontFamily
          })
        },
        Checkmark: {
          style: ({ $theme }) => ({
            backgroundColor: checked ? $theme.colors!.mono700 : $theme.colors!.mono100,
            color: checked ? $theme.colors!.mono100 : $theme.colors!.mono700,
            borderStyle: 'solid'
          })
        },
        Toggle: {
          style: ({ $theme }) => ({
            outline: $theme.colors!.mono700,
            backgroundColor: $theme.colors!.mono100,
            borderStyle: 'solid'
          })
        }
      }}
    >
      {children}
    </CheckBoxButton>
  );
};

interface RadioProps {
  title: string;
  value: string | undefined;
  alignment: 'horizontal' | 'vertical';
  buttons: Array<{ label: string; buttonValue: string }>;
  error?: boolean;
  onChange?: (value: string | boolean) => void;
  disabled?: boolean;
}

export const Radio = ({
  title,
  value,
  alignment = 'horizontal',
  buttons,
  error,
  disabled,
  onChange = () => {
    // noop
  }
}: RadioProps) => {
  return (
    <InputContainer>
      {error ? <ErrorLabel2>{title}</ErrorLabel2> : <Label2>{title}</Label2>}
      <RadioGroup
        value={value}
        align={ALIGN[alignment]}
        onChange={e => onChange(e.currentTarget.value)}
        disabled={disabled}
      >
        {buttons.map(({ label, buttonValue }: { label: string; buttonValue: string }, index) => (
          <RadioButton
            key={index}
            value={buttonValue}
            overrides={{
              RadioMarkInner: {
                style: ({ $theme }) => ({
                  backgroundColor: disabled ? $theme.colors!.mono300 : $theme.colors!.mono100
                })
              },
              RadioMarkOuter: {
                style: ({ $theme }) => ({
                  backgroundColor: error
                    ? $theme.colors!.negative400
                    : disabled
                    ? $theme.colors!.mono400
                    : $theme.colors!.mono700
                })
              },
              Label: {
                style: ({ $theme }) => ({
                  color: error ? $theme.colors!.negative400 : $theme.colors!.mono700,
                  paddingRight: '10px',
                  fontFamily: $theme.typography.font200.fontFamily
                })
              }
            }}
          >
            {label}
          </RadioButton>
        ))}
        <RadioButton
          overrides={{
            RadioMarkOuter: {
              style: {
                display: 'none'
              }
            }
          }}
        />
      </RadioGroup>
    </InputContainer>
  );
};
