import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { LockModal } from '../../components/lock-modal';
import { useGetUserInfo } from '../../hooks/use-get-user';
import { copyToClipboard } from '../../modules/copyToClipboard';
import { ApplicationState } from '../../redux';
import {
  closeLockModal,
  deleteLockRequest,
  editLockRequest,
  lockOfferRequest,
  updateLockModalFields
} from '../../redux/lock-modal/actions';
import { dealIdsSelector, dealsSelector } from '../../selectors/deals';
import { offersSelector } from '../../selectors/offer';
import { rateQuoteIdSelector } from '../../selectors/rate-quote/rate-quote';
import { attorneyRequiredSelector } from './attorney-required-selector';
import { disableOUTitleSelector } from './disable-ou-title-selector';
import { lockDataSelector } from './lock-data-selector';
import { lockModalValidFormSelector } from './lock-modal-valid-form-selector';
import { offerDataSelector } from './offer-data-selector';
import { rqrSelector } from './rqr-selector';

const lockModalUISelector = createSelector(
  ({ lockModal: { modalLock, offer, ...formUI } }: ApplicationState) => formUI,
  dealsSelector,
  ({ loading, ...rest }, { loading: dealsLoading }) => ({
    ...rest,
    loading: loading || dealsLoading
  })
);

export const OfferLockModal = () => {
  const dispatch = useDispatch();
  const { isOpen, link, loading, networkError, updatedHubspot } = useSelector(lockModalUISelector);
  const modalLock = useSelector(lockDataSelector);
  const offerData = useSelector(offerDataSelector);
  const rateQuoteId = useSelector(rateQuoteIdSelector);
  const validationError = useSelector(lockModalValidFormSelector);
  const { borrowerScenarioId, sourceId } = useSelector(rqrSelector);
  const allOffers = useSelector(offersSelector);
  const dealIds = useSelector(dealIdsSelector);
  const { error: dealsError } = useSelector(dealsSelector);
  const attorneyRequired = useSelector(attorneyRequiredSelector);
  const disableOUTitle = useSelector(disableOUTitleSelector);

  const getOffer = useCallback(
    (offerId: string) => Object.values(allOffers).find(offer => offer.offerId === offerId)!,
    [allOffers]
  );
  const userInfo = useGetUserInfo();

  const updateField = useCallback(
    ({ id, value }: { id: string; value: string | boolean }) =>
      dispatch(updateLockModalFields({ id, value })),
    [dispatch]
  );
  const lockOffer = useCallback(() => {
    const { rgTitle, attorney, loanId, provider, notes, float } = modalLock;
    dispatch(
      lockOfferRequest({
        rateQuoteId,
        borrowerScenarioId,
        sourceId,
        offer: getOffer(offerData.offerId),
        offerId: offerData.offerId,
        rgTitle: rgTitle.value,
        loanId: loanId.value,
        attorney: rgTitle.value ? attorney.value : provider ? provider : 'Unknown',
        notes,
        userInfo,
        float
      })
    );
  }, [
    modalLock,
    dispatch,
    rateQuoteId,
    borrowerScenarioId,
    sourceId,
    getOffer,
    offerData.offerId,
    userInfo
  ]);

  const deleteLock = useCallback(() => {
    const { lockId } = modalLock;
    dispatch(
      deleteLockRequest({
        rateQuoteId,
        offerId: offerData.offerId,
        lockId,
        sourceId
      })
    );
  }, [modalLock, dispatch, rateQuoteId, offerData.offerId, sourceId]);

  const editLock = useCallback(() => {
    const { rgTitle, attorney, loanId, provider, notes, lockId, float } = modalLock;
    if (link) {
      dispatch(
        editLockRequest({
          rateQuoteId,
          borrowerScenarioId,
          sourceId,
          offer: getOffer(offerData.offerId),
          offerId: offerData.offerId,
          rgTitle: rgTitle.value,
          loanId: loanId.value,
          attorney: rgTitle.value ? attorney.value : provider ? provider : 'Unknown',
          notes,
          lockId,
          userInfo,
          link,
          float
        })
      );
    }
  }, [
    modalLock,
    link,
    dispatch,
    rateQuoteId,
    borrowerScenarioId,
    sourceId,
    getOffer,
    offerData.offerId,
    userInfo
  ]);

  const onClose = () => dispatch(closeLockModal());
  return (
    <LockModal
      {...{
        data: modalLock,
        offerData,
        locked: offerData.locked,
        isOpen,
        onClose,
        link,
        loading,
        networkError,
        validationError,
        updateField,
        lockOffer,
        deleteLock,
        editLock,
        copyToClipboard,
        dealIds,
        dealsError,
        updatedHubspot,
        attorneyRequired,
        disableOUTitle
      }}
    />
  );
};
