import { styled } from 'baseui';

export const BugReportButton = styled('button', ({ $theme }) => ({
  ':hover': {
    backgroundColor: $theme.colors!.primary300,
    textDecoration: 'underline'
  },
  ':focus': {
    backgroundColor: $theme.colors!.primary300,
    outline: 'none'
  },
  ':active': {
    backgroundColor: $theme.colors!.primary300
  },
  backgroundColor: $theme.colors!.primary300,
  color: $theme.colors!.mono100,
  border: 'none',
  fontFamily: $theme.typography!.font100.fontFamily,
  cursor: 'pointer'
}));
