import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { AllQuoteTableProps } from '../components/all-quotes-table';
import { getQuoteRate } from '../modules/compute-rate-quote-request-display/get-quote-rate';
import { generateDTIStatus } from '../modules/offer-table/generate-dti-status';
import { AllQuotesOffer } from '../modules/rate-quote/types';
import { ApplicationState } from '../redux';

const transformQuotes = ({
  productDetails: { rate, manualRateLockDays },
  computedProductDetails: { dti, apr },
  monthlyPayment,
  engineDetails,
  attributes: { closingCost, loanSize, product, price, conforming, loanType },
  ...rest
}: AllQuotesOffer) => ({
  lenderCreditOrPoints: ((100 - price) / 100) * loanSize, // will be negative for LC and positive for points
  rate,
  dti,
  apr,
  rateLockPeriod: manualRateLockDays,
  closingCosts: closingCost,
  totalLoanAmount: loanSize,
  dtiStatus: generateDTIStatus({ dti, loanType, conforming }),
  product,
  ...monthlyPayment,
  ...engineDetails,
  ...rest
});

interface AllQuotesDisplay {
  [productName: string]: AllQuoteTableProps[];
}

const allQuotesStateSelector = createSelector(
  ({ allQuotes }: ApplicationState) => allQuotes,
  allQuotes => of(allQuotes)
);

export const allQuotesTableSelector = createSelector(allQuotesStateSelector, (allQuotes): {
  result: AllQuotesDisplay;
  loading: boolean;
  error: string | null;
  includeOfferLoading: string | null;
  isSameOfferModalOpen: boolean;
} =>
  allQuotes
    .map(({ rates, loading, error, includeOfferLoading, isSameOfferModalOpen }) => ({
      result: rates,
      loading,
      error,
      includeOfferLoading,
      isSameOfferModalOpen
    }))
    .map(({ result, loading, error, includeOfferLoading, isSameOfferModalOpen }) => {
      if (!loading && !error) {
        try {
          const allQuotesDisplay = Object.values(result).map(o => transformQuotes(o));
          return {
            result: allQuotesDisplay.reduce((acc, quote) => {
              const productName = getQuoteRate(quote.product);
              return acc[productName]
                ? { ...acc, [productName]: [...acc[productName], quote] }
                : { ...acc, [productName]: [quote] };
            }, {} as AllQuotesDisplay),
            loading,
            error,
            includeOfferLoading,
            isSameOfferModalOpen
          };
        } catch (err) {
          return {
            result: {},
            loading,
            error: (err as object).toString(),
            includeOfferLoading: null,
            isSameOfferModalOpen: false
          };
        }
      }
      return { result: {}, loading, error, includeOfferLoading: null, isSameOfferModalOpen: false };
    })
    .orElse(() => ({
      result: {},
      loading: true,
      error: 'Error fetching from redux',
      includeOfferLoading: null,
      isSameOfferModalOpen: false
    }))
);

export const allQuotesSelector = createSelector(allQuotesStateSelector, allQuotes =>
  allQuotes.map(({ rates }) => rates).orElse(() => ({}))
);
