import { AllQuotesOffer, Offer, ProductSet } from '../../../modules/rate-quote/types';
import { Nullable } from '../state';

export const SeedInitialOfferActionType = 'OFFER_DETAILS__SEED_INITIAL_OFFER' as const;
export const seedInitialOffer = (payload: {
  lenderId: string;
  productSet: ProductSet;
  pmi: number | undefined;
  sellerConcessions?: number | null;
}) => ({
  type: SeedInitialOfferActionType,
  payload
});
export type SeedInitialOfferAction = ReturnType<typeof seedInitialOffer>;

export const SeedOfferActionType = 'OFFER_DETAILS__SEED_OFFER' as const;
export const seedOffer = (offer: Offer | AllQuotesOffer, allQuotes?: boolean) => ({
  type: SeedOfferActionType,
  payload: { offer, allQuotes }
});
export type SeedOfferAction = ReturnType<typeof seedOffer>;

export const UpdateOfferDetailsFeeActionType = 'OFFER_DETAILS__UPDATE_DETAILS_FEE' as const;
export const updateOfferDetailsFee = (payload: {
  id: string;
  value: number | null | undefined;
}) => ({
  type: UpdateOfferDetailsFeeActionType,
  payload
});
export type UpdateOfferDetailsFeeAction = ReturnType<typeof updateOfferDetailsFee>;

export const UpdateOfferDetailsRateInfoActionType = 'OFFER_DETAILS__UPDATE_RATE_INFO' as const;
export const updateOfferDetailsRateInfo = (
  payload: Partial<Nullable<Offer['productDetails']>>
) => ({
  type: UpdateOfferDetailsRateInfoActionType,
  payload
});
export type UpdateOfferDetailsRateInfoAction = ReturnType<typeof updateOfferDetailsRateInfo>;

export const UpdateOfferDetailsMonthlyPaymentActionType = 'OFFER_DETAILS__UPDATE_MONTHLY_DETAILS' as const;
export const updateOfferDetailsMonthlyPayment = (payload: Offer['monthlyPayment']) => ({
  type: UpdateOfferDetailsMonthlyPaymentActionType,
  payload
});
export type UpdateOfferDetailsMonthlyPaymentAction = ReturnType<
  typeof updateOfferDetailsMonthlyPayment
>;

export const UpdatePriceActionType = 'OFFER_DETAILS__UPDATE_PRICE' as const;
export const updatePrice = (payload: { price: number | undefined }) => ({
  type: UpdatePriceActionType,
  payload
});
export type UpdatePriceAction = ReturnType<typeof updatePrice>;
