import { all, Effect, takeEvery } from 'redux-saga/effects';
import { FetchRateQuoteActionType, FetchRateQuoteFailureActionType } from '../rate-quote/actions';
import { setRateQuoteContext } from './add-rate-quote-context';
import { fetchFailed } from './capture-fetch-failed';

export function* captureErrors(): Iterable<Effect> {
  yield all([
    takeEvery(FetchRateQuoteActionType, setRateQuoteContext),
    takeEvery(FetchRateQuoteFailureActionType, fetchFailed)
  ]);
}
