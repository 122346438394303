import { currency, rate } from '@rategravity/frontend/modules/numbers';
import React from 'react';
import { useConditionalColumns } from '../../hooks/use-conditional-columns';
import { getQuoteRate } from '../../modules/compute-rate-quote-request-display/get-quote-rate';
import { ExistingLoan } from '../../modules/rate-quote/types';
import {
  closingCostLabel,
  closingToCoverLabel,
  ProductSet,
  Purpose
} from '../../modules/rate-quote/types';
import { SortableTable } from '../table-sortable';
import { CreateNewOfferButton } from './new-offer-popover';

const columnsToCheck = {
  loanType: 'Standard',
  helocLine: 0,
  escrowOptions: 'include',
  interestOptions: 'principalAndInterest',
  conforming: true,
  closingToCover: null as string | null,
  existingLoans: (loans: ExistingLoan[]) =>
    loans.every(loan => {
      if (loan.existingLoanType === 'HELOC') {
        return loan.closed;
      } else {
        return loan.rolledIntoRefi;
      }
    }),
  irrlEligible: false
};

export const ProductSetTable = ({
  data,
  purpose,
  openOfferDetails
}: {
  data: ProductSet[];
  purpose: Purpose;
  openOfferDetails: (payload: { lenderId: string; productSet: ProductSet }) => void;
}) => {
  const conditionalColumns = useConditionalColumns(columnsToCheck, data);
  return (
    <SortableTable
      data={data}
      columns={[
        ...(purpose === 'refinance'
          ? [
              {
                name: 'TOTAL C/O',
                format: ({ totalCashOut }: any) => currency(totalCashOut, 0),
                selector: ({ totalCashOut }: any) => totalCashOut
              }
            ]
          : []),
        {
          name: 'PRODUCT',
          format: ({ product }) => getQuoteRate(product)
        },
        ...(purpose === 'refinance'
          ? [
              {
                name: 'IRRRL',
                format: ({ irrrlEligible }: any) => (irrrlEligible ? 'Yes' : 'No')
              }
            ]
          : []),
        ...(purpose === 'purchase'
          ? [
              {
                name: 'DOWN',
                format: ({ downPayment }: any) => currency(downPayment, 0),
                selector: ({ downPayment }: any) => downPayment
              }
            ]
          : []),
        ...(purpose === 'refinance'
          ? [
              {
                name: 'LTV / CLTV',
                format: ({ ltv, cltv }: any) => `${rate(ltv * 100, 2)} / \n ${rate(cltv * 100, 2)}`,
                selector: ({ ltv, cltv }: any) => (ltv > cltv ? ltv : cltv) // use greater number for sorting
              }
            ]
          : [
              {
                name: 'LTV',
                format: ({ ltv }: any) => rate(ltv * 100, 2),
                selector: ({ ltv }: any) => ltv
              }
            ]),
        {
          name: 'CLOSING',
          format: ({ closingCostOption }) => closingCostLabel(closingCostOption)
        },
        {
          name: 'TO COVER',
          exclude: conditionalColumns.closingToCover,
          format: ({ closingToCover }) => closingToCoverLabel(closingToCover)
        },
        {
          name: 'LOAN',
          format: ({ closingCostOption, loanAmount }) =>
            closingCostOption !== 'financeClosing' ? currency(loanAmount, 0) : 'Pick lender first',
          selector: ({ closingCostOption, loanAmount }) =>
            closingCostOption !== 'financeClosing' ? loanAmount : 0
        },
        ...(purpose === 'refinance'
          ? [
              {
                name: 'OPEN DRAWN',
                format: ({ existingLoans }: any) =>
                  existingLoans.some(({ rolledIntoRefi }: any) => !rolledIntoRefi)
                    ? existingLoans
                        .filter(({ rolledIntoRefi }: any) => !rolledIntoRefi)
                        .map(
                          ({ existingLoanType, drawnAmount, amount }: any) =>
                            `${existingLoanType} - ${currency(
                              existingLoanType === 'HELOC' ? drawnAmount : amount,
                              0
                            )}`
                        )
                        .join(', ')
                    : 'None',
                selector: ({ existingLoans }: any) =>
                  existingLoans.map(({ drawnAmount }: any) => drawnAmount),
                exclude: conditionalColumns.existingLoans
              }
            ]
          : []),
        ...(purpose === 'refinance'
          ? [
              {
                name: 'OPEN LINE',
                format: ({ existingLoans }: any) =>
                  existingLoans.some(
                    ({ existingLoanType, closed }: any) => existingLoanType === 'HELOC' && !closed
                  )
                    ? existingLoans
                        .filter(({ existingLoanType }: any) => existingLoanType === 'HELOC')
                        .map(
                          ({ existingLoanType, lineAmount }: any) =>
                            `${existingLoanType} - ${currency(lineAmount, 0)}`
                        )
                        .join(', ')
                    : 'None',
                selector: ({ existingLoans }: any) =>
                  existingLoans.map(({ lineAmount }: any) => lineAmount),
                exclude: conditionalColumns.existingLoans
              }
            ]
          : []),
        {
          name: 'CONF.',
          format: ({ conforming }) => (conforming ? 'Yes' : 'No'),
          exclude: conditionalColumns.conforming
        },
        {
          name: 'EST DTI',
          format: ({ dti }) => rate(dti * 100, 0),
          selector: ({ dti }) => dti
        },
        { name: 'TYPE', exclude: conditionalColumns.loanType, format: ({ loanType }) => loanType },
        {
          name: 'HELOC',
          format: ({ helocLine }) => currency(helocLine, 0),
          selector: ({ helocLine }) => helocLine,
          exclude: conditionalColumns.helocLine
        },
        {
          name: 'ESCROW',
          exclude: conditionalColumns.escrowOptions,
          format: ({ escrowOptions }) => (escrowOptions === 'include' ? 'Include' : 'Waive')
        },
        {
          name: 'INTEREST',
          exclude: conditionalColumns.interestOptions,
          format: ({ interestOptions }) => (interestOptions === 'interestOnly' ? 'I/O' : 'P&I')
        },
        {
          name: '',
          // eslint-disable-next-line react/display-name
          format: row => <CreateNewOfferButton {...{ purpose, openOfferDetails, data: row }} />,
          sortable: false
        }
      ]}
    />
  );
};
