"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const math_1 = require("./math");
const payments_1 = require("./payments");
const nearest8th = (rate) => {
    const eights = rate / 0.125;
    const rounded = Math.round(eights);
    const r = rounded * 0.125;
    return r;
};
function amortizationSchedule(loan) {
    const { rate, product: { loanTerm, fixedTerm, indexValue, margin, adjustmentPeriod, caps } } = {
        ...loan,
        product: {
            indexValue: 0,
            margin: 0,
            adjustmentPeriod: 1,
            caps: {
                inital: 0,
                periodic: 0,
                lifetime: 0
            },
            ...loan.product
        }
    };
    return computeAmortizationSchedule({
        initialRate: rate,
        presentValue: payments_1.loanValue(loan),
        fullyIndexedRate: indexValue + margin,
        loanTerm,
        fixedTerm,
        adjustmentPeriod,
        caps: {
            initial: caps.inital,
            periodic: caps.periodic,
            lifetime: caps.lifetime
        }
    });
}
exports.amortizationSchedule = amortizationSchedule;
/**
 * Computes a monthly amortization schedule
 * for a given loan. An amortization schedule
 * shows the amount of principle and interest
 * that is payed every month and what the resulting
 * balance of the loan is.
 */
function computeAmortizationSchedule({ initialRate, presentValue, fullyIndexedRate = initialRate, loanTerm, fixedTerm = loanTerm, adjustmentPeriod = 1, caps = { initial: 0, periodic: 0, lifetime: 0 } }) {
    const monthlyIndexedRate = nearest8th(fullyIndexedRate) / 1200;
    const maxRate = Math.min((initialRate + caps.lifetime) / 1200, monthlyIndexedRate);
    const minRate = Math.max((initialRate - caps.lifetime) / 1200, monthlyIndexedRate);
    const paymentPeriods = loanTerm * 12;
    const fixedPeriods = fixedTerm * 12;
    const periodsPerAdjustment = adjustmentPeriod * 12;
    let remainingBalance = presentValue;
    let r = initialRate / 1200;
    let p = math_1.PMT(r, paymentPeriods, remainingBalance, 0);
    const schedule = [];
    // iterate over the life of the loan, month by month
    for (let i = 0; i < paymentPeriods; i++) {
        // if we're beyond the fixed term
        if (i >= fixedPeriods) {
            // if we're at an adjustment
            if ((i - fixedPeriods) % periodsPerAdjustment === 0) {
                const cap = (i === fixedPeriods ? caps.initial : caps.periodic) / 1200;
                r = Math.max(minRate, Math.min(r + cap * (r < monthlyIndexedRate ? 1 : -1), maxRate));
                p = math_1.PMT(r, paymentPeriods - i, remainingBalance, 0);
            }
        }
        const interest = r * remainingBalance;
        const paid = Math.min(remainingBalance + interest, p);
        remainingBalance = Math.max(remainingBalance - (paid - interest), 0);
        schedule.push({
            principle: paid - interest,
            interest,
            balanceAtEndOfMonth: remainingBalance
        });
    }
    return schedule;
}
exports.computeAmortizationSchedule = computeAmortizationSchedule;
