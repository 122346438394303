export function monthlyPMT(
  rate: number,
  payments: number,
  presentValue: number,
  futureValue: number = 0
): number {
  return PMT(rate / 1200, payments * 12, presentValue, futureValue);
}

export function PMT(
  rate: number,
  payments: number,
  presentValue: number,
  futureValue: number
): number {
  const rExp = Math.pow(1 + rate, payments);
  return (rate * (futureValue + presentValue * rExp)) / (rExp - 1);
}

export function monthlyFV(
  rate: number,
  payments: number,
  presentValue: number,
  nper: number
): number {
  return FV(rate / 1200, payments, presentValue, nper * 12);
}

export function FV(rate: number, pmt: number, pv: number, nper: number): number {
  const pow = Math.pow(1 + rate, nper);
  if (rate) {
    return (pmt * (1 - pow)) / rate + pv * pow;
  }
  return pv - pmt * nper;
}
