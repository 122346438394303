import { createSelector } from 'reselect';
import { loanIdEmptySelector, loanIdInvalidDealStageSelector } from './loan-id-selector';
import { lockDataSelector } from './lock-data-selector';

const MissingFieldError = 'Please complete the highlighted fields below.';
const loanIdError = (loanId?: string) => {
  switch (loanId) {
    case 'invalid':
      return 'This HubSpot Deal is Withdrawn or Funded. Please create a new Hubspot Deal and enter that ID here to save the Lock.';
    case 'notReady':
      return 'This deal is missing fields in HubSpot required to lock. Please move it to the Consult Completed stage and fill in all required fields before locking.';
    case 'locked':
      return 'This deal has a lock in HubSpot. To lock in this offer, create a new Deal in HubSpot and move it to the Consult Completed stage.';
    default:
      return '';
  }
};
export const lockModalValidFormSelector = createSelector(
  lockDataSelector,
  loanIdEmptySelector,
  loanIdInvalidDealStageSelector,
  (modalLock, loanIdEmpty, loanIdStage) =>
    (modalLock.rgTitle.value && modalLock.attorney.error) || loanIdEmpty || modalLock.rgTitle.error
      ? MissingFieldError
      : loanIdError(loanIdStage)
);
