import { useMemo } from 'react';

type Predicate<I> = (i: I) => boolean;

const isPredicate = (v: unknown): v is Predicate<unknown> => typeof v === 'function';

type Data<M> = {
  [K in keyof M]?: M[K] extends Predicate<infer I> ? I : M[K];
};

export const toExclude = <N, T extends Array<Data<N>>>(excludeIf: N, data: T) =>
  (Object.keys(excludeIf) as Array<keyof N>).reduce((exclude, key) => {
    const v = excludeIf[key];

    const predicateCheck = (r: Data<N>) =>
      key in r ? (isPredicate(v) ? v(r[key]) : r[key] === v) : true;

    return {
      ...exclude,
      [key]: data.every(row => predicateCheck(row))
    };
  }, {} as { [K in keyof N]: boolean });

/**
 * Hook to evaulate whether or not a column should be excluded from a conditional
 *   column table.
 *
 * `data` and `excludeIf` should have the same fields. It will check each field in
 *   `data` against the criteria defined by `excludeIf`. If all the instances of
 *   `data` match the criteria in `excludeIf`, the column will be marked as excluded.
 *
 * For example:
 *  `excludeIf`: `{ a: 5, b: 3 }`
 *  `data`: `[{ a: 5, b: 2}, { a: 5, b: 3}]`
 *
 * Column `a` will be excluded because every object in `data` matched the exclusion
 *   criteria, but column `b` will not be excluded because some of the data did not
 *   match the exclusion criteria.
 *
 * `excludeIf` may also contain predicates, which are useful when exclusion criteria
 *    must be accessed by iterating over an array.
 *
 * @param excludeIf - Exclusion criteria.
 * @param data - Data set to evalute.
 */
export const useConditionalColumns = <N, T extends Array<Data<N>>>(excludeIf: N, data: T) => {
  return useMemo(() => toExclude(excludeIf, data), [excludeIf, data]);
};
