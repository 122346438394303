import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchAllInvestors, FetchAllInvestorsResult } from '../../modules/rate-quote';
import { getAllInvestors, getAllInvestorsFailed, getAllInvestorsSucceeded } from './slice';

export function* handleGetAllInvestors({
  payload: { rateQuoteRequestId }
}: ReturnType<typeof getAllInvestors>) {
  try {
    const result: FetchAllInvestorsResult = yield call(fetchAllInvestors, { rateQuoteRequestId });
    if (result.success) {
      yield put(getAllInvestorsSucceeded(result.json));
    } else {
      yield put(getAllInvestorsFailed({ error: result.error }));
    }
  } catch (err) {
    yield put(getAllInvestorsFailed({ error: err.message }));
  }
}

export const allInvestorsSaga = function*() {
  yield all([takeEvery(getAllInvestors.type, handleGetAllInvestors)]);
};
