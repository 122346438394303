import { styled, withStyle } from 'baseui';
import { Accordion } from 'baseui/accordion';
import { StyledLink } from 'baseui/link';
import React from 'react';
import { StyletronComponent } from 'styletron-react';
import { AllQuotesOfferDetailsModal } from '../../views/all-quotes-offer-details';
import { Details } from '../../views/details';
import { LinkModal } from '../../views/link-modal';
import { OfferLockModal } from '../../views/lock-modal';
import { OfferDetailsModal } from '../../views/offer-details';
import { ProductSet } from '../../views/product-set';
import { RateQuotePageTabs } from '../../views/rate-quote-tabs';
import { Container } from '../container';
import { ExternalLinkIcon } from '../icons';
import { Panel } from '../panel';
import { Spinner } from '../spinner';

export interface RateQuotePanelProps {
  loading: boolean;
  title?: string;
  rqrFields: {
    accountId: string;
    dealId: string;
  };
  purpose: 'purchase' | 'refinance';
  rqrId: string;
  showAllQuotes: boolean;
}
const DEFAULT_TITLE = 'Unknown Scenario';

const RateQuoteTitleHeader = styled('h1', () => ({
  'margin-top': '45px',
  display: 'flex',
  'justify-content': 'space-between'
}));

const AdvisorHubLink = withStyle(StyledLink as StyletronComponent<any>, ({ $theme }) => ({
  color: $theme.colors!.primary300,
  fontFamily: $theme.typography!.font300.fontFamily,
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'flex-end',
  textDecoration: 'none',
  paddingTop: '10px'
}));

export const RateQuotePanel = ({
  loading,
  title,
  rqrFields: { accountId, dealId },
  purpose,
  rqrId,
  showAllQuotes
}: RateQuotePanelProps) => {
  if (loading) {
    return <Spinner />;
  }

  const url = `${process.env.ADVISOR_HUB_BASE_URL}account/${accountId}/${
    purpose === 'purchase' ? 'purchaseDeal' : 'refinance'
  }/${dealId}/quotes/${rqrId}`;

  return (
    <Container>
      <Accordion accordion={false}>
        <RateQuoteTitleHeader>
          {title || DEFAULT_TITLE}
          <AdvisorHubLink href={url} target="_blank">
            {' '}
            <ExternalLinkIcon size={12} /> View on Advisor Hub{' '}
          </AdvisorHubLink>
        </RateQuoteTitleHeader>
        <Panel title={'Details'}>
          <Details />
        </Panel>
        <Panel title={'Product Set'}>
          <ProductSet />
        </Panel>
        <OfferDetailsModal />
        <AllQuotesOfferDetailsModal />
        <OfferLockModal />
        <LinkModal />
        <RateQuotePageTabs showAllQuotes={showAllQuotes} />
      </Accordion>
    </Container>
  );
};
