import {
  all,
  call,
  CallEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeEvery
} from 'redux-saga/effects';
import { ApplicationState } from '../..';
import {
  clearModalCacheState,
  getModalCacheState,
  storeModalCacheState
} from '../../../modules/offer-details/local-storage';
import { rateQuoteIdSelector } from '../../../selectors/rate-quote/rate-quote';
import {
  FetchRateQuoteSuccessAction,
  FetchRateQuoteSuccessActionType
} from '../../rate-quote/actions';
import {
  cacheModalState,
  CacheModalStateAction,
  CacheModalStateActionType,
  clearModalStateCache,
  ClearModalStateCacheAction,
  ClearModalStateCacheActionType,
  CloseOfferDetailsAction,
  CloseOfferDetailsActionType,
  restoreModalStateCache,
  SeedInitialOfferActionType,
  SeedOfferActionType,
  UpdateOfferDetailsFeeActionType,
  UpdateOfferDetailsRateInfoActionType
} from '../actions';
import { EditableOffer } from '../state';

/**
 * Caches the input ModalOffer for the current RateQuoteId
 *
 * @param payload - Offer Modal State
 */
function* handleModalCache({
  payload
}: CacheModalStateAction): Generator<CallEffect | SelectEffect> {
  const rateQuoteId = yield select(rateQuoteIdSelector);
  yield call(storeModalCacheState, rateQuoteId as string, payload);
}

/**
 * Raises a modal cache clear action.
 *
 * @param _ Not used
 */
function* handleModalClosed(_: CloseOfferDetailsAction) {
  yield put(clearModalStateCache());
}

/**
 * Checks to see if there's a WIP modal that hasn't been invalidated by new
 *   work on the network.
 *
 * @param payload Editing rate quote.
 */
function* handleFetchRateQuoteSuccess({
  payload
}: FetchRateQuoteSuccessAction): Generator<CallEffect | PutEffect> {
  const modalState = yield call(getModalCacheState, payload.rateQuoteId, payload.lastModifiedTime);
  if (modalState) {
    yield put(restoreModalStateCache(modalState as { offer: EditableOffer; allQuotes: boolean }));
  }
}

/**
 * Updates the modal state cache based on your current state:
 *   Triggers a cache write if a modal is open.
 *   Ensures the modal is cleared if no modal is open.
 */
function* updateModalStateCache(): Generator<SelectEffect | PutEffect> {
  const offer = yield select(({ offerDetails: { modalOffer } }: ApplicationState) => modalOffer);
  const allQuotes = yield select(
    ({ offerDetails: { isOpenAllQuotes } }: ApplicationState) => isOpenAllQuotes
  );
  if (offer) {
    yield put(
      cacheModalState({ offer, allQuotes } as {
        offer: EditableOffer;
        allQuotes: boolean;
      })
    );
  } else {
    yield put(clearModalStateCache());
  }
}

/**
 * Clears the modal cache.
 *
 * @param _ Not used
 */
function* handleClearModalCache(
  _: ClearModalStateCacheAction
): Generator<SelectEffect | CallEffect> {
  const rateQuoteId = yield select(rateQuoteIdSelector);
  yield call(clearModalCacheState, rateQuoteId as string);
}

export const modalSaga = function*() {
  yield all([
    takeEvery(CacheModalStateActionType, handleModalCache),
    takeEvery(CloseOfferDetailsActionType, handleModalClosed),
    takeEvery(FetchRateQuoteSuccessActionType, handleFetchRateQuoteSuccess),
    takeEvery(ClearModalStateCacheActionType, handleClearModalCache),
    // Modal Sync Actions
    takeEvery(SeedOfferActionType, updateModalStateCache),
    takeEvery(UpdateOfferDetailsFeeActionType, updateModalStateCache),
    takeEvery(UpdateOfferDetailsRateInfoActionType, updateModalStateCache),
    takeEvery(SeedInitialOfferActionType, updateModalStateCache)
  ]);
};
