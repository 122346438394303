import * as Sentry from '@sentry/browser';
import { FetchRateQuoteFailureAction } from '../rate-quote/actions';

export function* fetchFailed({ payload }: FetchRateQuoteFailureAction): Generator<void> {
  if (payload.code < 400 || payload.code >= 500) {
    Sentry.captureEvent({
      message: payload.message,
      level: Sentry.Severity.Error
    });
  }
}
