import fecha from 'fecha';
import { Borrowers } from '../../modules/value-types/borrower';
import { RateQuote } from '../rate-quote/types';
import { getFromAdditionalContext } from './get-from-additional-context';

export const aggregateFTHB = (borrowers: Borrowers) => {
  const OneDay = 86400000;
  const today = new Date().getTime();

  const fthb = borrowers.map(({ ownedHomePreviously, soldLastHome }) => {
    if (!ownedHomePreviously) {
      return true;
    }

    if (soldLastHome === null) {
      return false;
    }

    return today - soldLastHome >= OneDay * 365 * 3;
  });

  return fthb.some(status => status) ? 'Yes' : 'No';
};

export const aggregateCitizenship = (borrowers: Borrowers) => {
  const citizenshipMap = {
    USCitizen: { text: 'US Citizen' },
    USCitizenAbroad: { text: 'US Citizen Abroad' },
    PermanentResidentAlien: { text: 'Perm. Resident Alien' },
    NonPermanentResidentAlien: { text: 'Non-Perm. Resident Alien' }
  };

  const citizenships = new Set(
    borrowers.map(({ citizenship, visaType, expirationDate }) => {
      const formattedExpiration = expirationDate
        ? `exp ${fecha.format(new Date(expirationDate), 'MM/YY')}`
        : null;

      return citizenship === 'NonPermanentResidentAlien'
        ? [
            visaType || formattedExpiration ? null : 'Non-Perm. Resident Alien',
            visaType ? `${visaType} visa` : formattedExpiration ? 'unknown visa' : null,
            formattedExpiration
          ]
            .filter(e => e != null)
            .join(', ')
        : citizenshipMap[citizenship].text || '';
    })
  );

  return [...citizenships].join(';\n');
};

export const computeScenario = (rqr: RateQuote['rateQuoteRequest']) => {
  const { additionalContext, borrowers, minimumCreditScore, nonOccupantBorrower } = rqr;

  const scenarioName = getFromAdditionalContext(additionalContext, 'name');
  const primaryBorrower = getFromAdditionalContext(additionalContext, 'primaryBorrower');
  const coBorrowers = getFromAdditionalContext(additionalContext, 'coBorrowers');

  return [
    ['Scenario', scenarioName],
    ['Borrower', primaryBorrower],
    ['Coborrower', coBorrowers],
    ['Non-Occ CB', nonOccupantBorrower ? 'Yes' : 'No'],
    ['Min Credit', `${minimumCreditScore}`],
    ['Citizenship', aggregateCitizenship(borrowers)],
    ['FTHB', aggregateFTHB(borrowers)]
  ];
};
