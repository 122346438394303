import { styled } from 'baseui';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../components/buttons';
import { NumberInput } from '../../components/forms/input';
import { MultiSelect } from '../../components/forms/select/multi-select';
import { allInvestorSelector } from '../../redux/all-investors/selector';
import { getAllQuotes } from '../../redux/all-quotes/slice';
import { allQuotesTableSelector } from '../../selectors/all-quotes';
import { rateQuoteRequestIdSelector } from '../../selectors/rate-quote/rate-quote';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  margin: '32px 0',
  alignItems: 'flex-start',
  justifyContent: 'space-between'
});

const PriceRangeDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
});

const FiltersButton = styled(PrimaryButton, {
  margin: '27px 0 0 24px !important'
});

export const Filters = () => {
  // grab this from all investors selector later
  const allInvestors = useSelector(allInvestorSelector);
  const [investor, setInvestor] = useState<{ value: string }[]>([]);
  const [lockDays, setLockDays] = useState<number | null>(45);
  const [rate, setRate] = useState<number | null>(3.5);
  const [priceLow, setPriceLow] = useState<number | null>(99);
  const [priceHigh, setPriceHigh] = useState<number | null>(101);
  const rateQuoteRequestId = useSelector(rateQuoteRequestIdSelector);
  const { loading } = useSelector(allQuotesTableSelector);

  const dispatch = useDispatch();

  return (
    <Container>
      <FlexGrid flexGridColumnCount={4} flexGridColumnGap="scale800">
        <FlexGridItem>
          <MultiSelect
            options={allInvestors.investors}
            labelKey="value"
            multi
            onChange={({ value }) => setInvestor(value as Array<{ value: string }>)}
            value={investor}
            label="Investor"
            disabled={allInvestors.loading}
          />
        </FlexGridItem>
        <FlexGridItem>
          <NumberInput
            label="Min. Lock Period"
            onChange={({ value }) => setLockDays(value || null)}
            value={lockDays}
          />
        </FlexGridItem>
        <FlexGridItem>
          <NumberInput
            label="Max Rate"
            inputType="percent"
            onChange={({ value }) => setRate(value || null)}
            value={rate}
          />
        </FlexGridItem>
        <FlexGridItem>
          <FlexGrid flexGridColumnCount={2} flexGridColumnGap="scale100">
            <FlexGridItem>
              <NumberInput
                label="Price range"
                onChange={({ value }) => setPriceLow(value || null)}
                value={priceLow}
              />
            </FlexGridItem>
            <FlexGridItem {...{ marginTop: '24px' as const }}>
              <PriceRangeDiv>
                <span style={{ margin: '10px 4px 0 0' }}>-</span>
                <NumberInput
                  label=""
                  onChange={({ value }) => setPriceHigh(value || null)}
                  value={priceHigh}
                />
              </PriceRangeDiv>
            </FlexGridItem>
          </FlexGrid>
        </FlexGridItem>
      </FlexGrid>
      <FiltersButton
        onClick={() => {
          dispatch(
            getAllQuotes({
              rateQuoteRequestId,
              filterOptions: {
                investor: investor.map(({ value }) => value),
                price:
                  priceLow && priceHigh
                    ? {
                        low: priceLow,
                        high: priceHigh
                      }
                    : null,
                rate,
                lockDays
              }
            })
          );
        }}
        disabled={loading}
        width="Long"
      >
        Apply Filters
      </FiltersButton>
    </Container>
  );
};
