import Conditional from '@rategravity/frontend/components/conditional';
import { styled, useStyletron } from 'baseui';
import { Theme } from 'baseui/theme';
import React, { Fragment, useState } from 'react';
import { formatDate } from '../../modules/format-date';
import { OfferTableHoverButton } from '../buttons';
import { LockIcon } from '../icons';
import { Modal } from '../modal';

const HoverWrapper = styled('div', ({ $theme }) => ({
  display: 'flex',
  backgroundColor: $theme.colors.mono100,
  alignItems: 'center',
  justifyContent: 'flex-end'
}));

const TimeContainer = styled(
  'div',
  ({ excluded, $theme }: { excluded: boolean; $theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: $theme.colors!.mono400,
    paddingRight: '5px',
    opacity: excluded ? 0.5 : 1
  })
);

const LockWrapper = styled('div', {
  display: 'flex',
  height: 'min-content',
  paddingBottom: '2px'
});

export const TimeAndButtons = ({
  offerId,
  lastModifiedTime,
  editOffer,
  deleteOffer,
  includeOffer,
  seedLock,
  getLockOffer,
  excluded,
  showButtons = false,
  method,
  locked,
  showLock,
  validLock,
  status
}: {
  offerId: string;
  lastModifiedTime: number;
  editOffer: (offerId: string) => void;
  deleteOffer: (offerId: string) => void;
  includeOffer: (offerId: string) => void;
  seedLock: (offerId: string) => void;
  getLockOffer: (offerId: string) => void;
  excluded: boolean;
  showButtons?: boolean;
  method: string;
  locked: boolean;
  showLock: boolean;
  validLock: boolean;
  status: string;
}) => {
  const [, theme] = useStyletron();
  const [isLockValidationOpen, setLockValidationOpen] = useState(false);
  return (
    <Fragment>
      <LockValidationModal
        isOpen={isLockValidationOpen}
        onClose={() => setLockValidationOpen(false)}
      />
      <Conditional show={showButtons}>
        <HoverWrapper>
          {excluded ? (
            <OfferTableHoverButton onClick={() => includeOffer(offerId)}>
              Include
            </OfferTableHoverButton>
          ) : (
            <OfferTableHoverButton onClick={() => deleteOffer(offerId)} disabled={locked}>
              {method !== 'Automated' ? 'Remove' : 'Exclude'}
            </OfferTableHoverButton>
          )}
          <Conditional show={showLock || locked}>
            <OfferTableHoverButton
              onClick={() => {
                if (!validLock) {
                  setLockValidationOpen(true);
                } else {
                  return locked ? getLockOffer(offerId) : seedLock(offerId);
                }
              }}
              disabled={status === 'Incomplete'}
            >
              {locked ? 'Edit Lock' : 'Lock'}
            </OfferTableHoverButton>
          </Conditional>
          <OfferTableHoverButton onClick={() => editOffer(offerId)}>Edit</OfferTableHoverButton>
        </HoverWrapper>
      </Conditional>
      <Conditional show={!showButtons}>
        <TimeContainer excluded={excluded} $theme={theme}>
          {formatDate(lastModifiedTime)}
          <Conditional show={locked}>
            <LockWrapper>
              <LockIcon />
            </LockWrapper>
          </Conditional>
        </TimeContainer>
      </Conditional>
    </Fragment>
  );
};

interface LockValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ValidationText = styled('div', () => ({
  fontSize: '18px'
}));

const LockValidationModal = ({ isOpen, onClose }: LockValidationModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Missing lock fields" layout="Regular">
    <ValidationText>
      No lock / contingency date added. Please go back to the deal, add a close and contingency
      date, and refresh your rates before locking.
    </ValidationText>
  </Modal>
);
