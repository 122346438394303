import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  CloseLockModalActionType,
  DeleteLockActionType,
  DeleteLockFailureAction,
  DeleteLockFailureActionType,
  DeleteLockSuccessActionType,
  EditLockActionType,
  GetLockAction,
  GetLockActionType,
  GetLockFailureAction,
  GetLockFailureActionType,
  GetLockSuccessAction,
  GetLockSuccessActionType,
  LockOfferActionType,
  LockOfferFailureAction,
  LockOfferFailureActionType,
  LockOfferSuccessAction,
  LockOfferSuccessActionType,
  SeedLockModalAction,
  SeedLockModalActionType,
  UpdateLockModalFieldsAction,
  UpdateLockModalFieldsActionType
} from './actions';
import { DefaultLockModalState, LockModalState } from './state';

const handleCloseLockModalAction = (state: LockModalState): LockModalState => ({
  ...state,
  isOpen: false,
  offer: null,
  modalLock: null,
  networkError: null,
  link: null
});

const handleLockOfferAction = (state: LockModalState): LockModalState => ({
  ...state,
  loading: true,
  isOpen: true,
  networkError: null
});

const handleLockOfferSuccessAction = (
  state: LockModalState,
  { payload }: LockOfferSuccessAction
): LockModalState => ({
  ...state,
  loading: payload.pending,
  isOpen: true,
  networkError: null
});

const handleGetLockAction = (
  state: LockModalState,
  { payload: { offer } }: GetLockAction
): LockModalState => ({
  ...state,
  offer: {
    offerId: offer.offerId,
    product: offer.attributes.product,
    lender: offer.lender,
    rate: offer.productDetails.rate,
    locked: offer.locked,
    fees: offer.fees.filter(({ id }) => id === 'lenderCredit' || id === 'points')
  },
  loading: true,
  isOpen: true,
  networkError: null
});

const handleGetLockSuccessAction = (
  state: LockModalState,
  { payload }: GetLockSuccessAction
): LockModalState => ({
  ...state,
  modalLock: {
    ...state.modalLock!,
    loanId: payload.loanId,
    rgTitle: payload.rgTitle,
    attorney: payload.rgTitle ? payload.attorney : undefined,
    provider: payload.rgTitle ? undefined : payload.attorney,
    notes: payload.notes,
    lockId: payload.lockId,
    float: payload.float
  },
  loading: payload.pending,
  link: payload.link,
  isOpen: true,
  networkError: !payload.pending && !payload.link ? 'pdf' : null,
  updatedHubspot: payload.updatedHubspot || false
});

const handleDeleteLockAction = (state: LockModalState): LockModalState => ({
  ...state,
  loading: true,
  networkError: null,
  isOpen: true
});

const handleDeleteLockSuccessAction = (state: LockModalState): LockModalState => ({
  ...state,
  loading: false,
  networkError: null,
  link: null
});

const handleFailureAction = (
  state: LockModalState,
  { payload }: LockOfferFailureAction | GetLockFailureAction | DeleteLockFailureAction
): LockModalState => ({
  ...state,
  loading: false,
  networkError: payload
});

const handleUpdateLockModalFields = (
  state: LockModalState,
  { payload }: UpdateLockModalFieldsAction
): LockModalState => ({
  ...state,
  modalLock: {
    ...state.modalLock!,
    [payload.id]: payload.value
  }
});

const handleSeedLock = (
  state: LockModalState,
  { payload: { offer, modalLock } }: SeedLockModalAction
): LockModalState => ({
  ...state,
  modalLock,
  offer: {
    offerId: offer.offerId,
    product: offer.attributes.product,
    lender: offer.lender,
    rate: offer.productDetails.rate,
    locked: offer.locked,
    fees: offer.fees.filter(({ id }) => id === 'lenderCredit' || id === 'points')
  },
  isOpen: true,
  networkError: null
});

export const lockModalReducer = composeReducers(
  withDefault(DefaultLockModalState),
  ofType(CloseLockModalActionType, handleCloseLockModalAction),
  ofType([LockOfferActionType, EditLockActionType], handleLockOfferAction),
  ofType(LockOfferSuccessActionType, handleLockOfferSuccessAction),
  ofType(GetLockActionType, handleGetLockAction),
  ofType(GetLockSuccessActionType, handleGetLockSuccessAction),
  ofType(DeleteLockActionType, handleDeleteLockAction),
  ofType(DeleteLockSuccessActionType, handleDeleteLockSuccessAction),
  ofType(
    [GetLockFailureActionType, LockOfferFailureActionType, DeleteLockFailureActionType],
    handleFailureAction
  ),
  ofType(SeedLockModalActionType, handleSeedLock),
  ofType(UpdateLockModalFieldsActionType, handleUpdateLockModalFields)
);
