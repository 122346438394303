import { of } from '@rategravity/core-lib/optional';
import Conditional from '@rategravity/frontend/components/conditional';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Accordion } from '../../components/accordion';
import { CheckableTable } from '../../components/checkable-table';
import { OffersPanelHeader } from '../../components/offers-panel-header';
import { toExclude } from '../../hooks/use-conditional-columns';
import { getQuoteRate } from '../../modules/compute-rate-quote-request-display/get-quote-rate';
import { isStale } from '../../modules/offer-details/is-stale';
import { isIncomplete } from '../../modules/offer-details/is_incomplete';
import { generateDTIStatus } from '../../modules/offer-table/generate-dti-status';
import { highBalanceOrJumbo } from '../../modules/offer-table/high-balance-jumbo';
import { toShowPMI } from '../../modules/offer-table/show-pmi';
import { AutoQuoteStatus, Offer } from '../../modules/rate-quote/types';
import { isPurchaseRQR } from '../../modules/rate-quote/types';
import { OfferStatus } from '../../modules/types';
import { ApplicationState } from '../../redux';
import {
  previewRateQuoteRequest,
  publishRateQuoteRequest,
  submitRateQuoteRequest
} from '../../redux/link-modal/actions';
import { getLockRequest, seedLockModal } from '../../redux/lock-modal/actions';
import { seedOffer as seedOfferAction } from '../../redux/offer-details/actions';
import {
  merchandiseOffer as merchandiseOfferAction,
  storeOffer as storeOfferAction,
  unmerchandiseOffer as unmerchandiseOfferAction
} from '../../redux/rate-quote/actions';
import { dealIdsSelector } from '../../selectors/deals';
import { merchandisingSelector, offersSelector } from '../../selectors/offer';
import { offerDetailsUISelector } from '../../selectors/offer-details';
import { rateQuoteRequestSelector } from '../../selectors/rate-quote-request';
import {
  lastModifiedTimeSelector,
  lockedOfferIdSelector,
  productSetSelector,
  rateQuoteIdSelector,
  rateQuoteRequesterSelector,
  rateQuoteRequestIdSelector
} from '../../selectors/rate-quote/rate-quote';
import { disableOUTitleSelector } from '../lock-modal/disable-ou-title-selector';
import { formatAttributes } from './offer-permutations';

const offerDetailsSelector = createSelector(
  merchandisingSelector,
  offersSelector,
  rateQuoteRequestSelector,
  lastModifiedTimeSelector,
  (merchandisedOfferIds, offers, rqr, { lastModifiedTime: rqLastModifiedTime }) =>
    Object.values(offers)
      .map((offer: Offer) => {
        const {
          offerId,
          starred,
          attributes: {
            product,
            loanType,
            closingCostOption,
            loanSize,
            interestOptions,
            conforming,
            aboveNationalBaseline
          },
          monthlyPayment: { pAndI, mi },
          productDetails: { manualRateLockDays, rate, label },
          computedProductDetails: { rateLockDays, apr, dti },
          fees,
          lender: { name, url },
          lastModifiedTime: offerLastModifiedTime,
          method,
          expired,
          locked,
          sellerConcessions
        } = offer;

        const closingCosts =
          closingCostOption === 'financeClosing'
            ? 0
            : fees.reduce(
                (
                  acc,
                  { manualValue, computedValue, calculationAttributes: { credit, category } }
                ) => {
                  if (category !== 'ClosingCost') {
                    return acc;
                  }

                  const value =
                    typeof manualValue === 'number'
                      ? manualValue
                      : typeof computedValue === 'number' && manualValue === null
                      ? computedValue
                      : 0;
                  return credit ? acc - value : acc + value;
                },
                !!sellerConcessions ? sellerConcessions * -1 : 0
              );
        return {
          offerId,
          starred,
          excluded: !merchandisedOfferIds.includes(offerId),
          label,
          method,
          locked,
          apr,
          lender: { name, url },
          product: getQuoteRate(product),
          rateLockPeriod: manualRateLockDays === null ? rateLockDays : manualRateLockDays,
          rate,
          interestOptions,
          pAndI,
          mi: mi ? { required: mi.required, value: mi.value } : { required: false },
          closingCosts,
          lenderCredit: fees
            .filter(({ id }) => id === 'lenderCredit')
            .map(
              ({ computedValue, manualValue, calculationAttributes }) =>
                (manualValue != null
                  ? manualValue
                  : typeof computedValue === 'number'
                  ? computedValue
                  : 0) * (calculationAttributes.credit ? 1 : -1)
            )
            .reduce((l, r) => l + r, 0),
          points: fees
            .filter(({ id }) => id === 'points')
            .map(
              ({ computedValue, manualValue, calculationAttributes }) =>
                (manualValue != null
                  ? manualValue
                  : typeof computedValue === 'number'
                  ? computedValue
                  : 0) * (calculationAttributes.credit ? -1 : 1)
            )
            .reduce((l, r) => l + r, 0),
          status: isStale(method, offerLastModifiedTime, rqLastModifiedTime)
            ? 'Stale'
            : isIncomplete(toShowPMI(loanType, loanSize, rqr), mi?.value, fees)
            ? 'Incomplete'
            : ('Complete' as OfferStatus),
          lastModifiedTime: offerLastModifiedTime,
          totalLoanAmount: loanSize,
          expired,
          dti,
          dtiStatus: generateDTIStatus({ dti, loanType, conforming }),
          conf: highBalanceOrJumbo(loanType, conforming, aboveNationalBaseline)
        };
      })
      .filter(({ excluded, method }) => {
        return !excluded || method !== 'Manual';
      })
);

const offerStatusSelector = createSelector(
  offerDetailsSelector,
  merchandisingSelector,
  (details, merchandised) =>
    details.filter(offer => merchandised.includes(offer.offerId)).map(offer => offer.status)
);

const autoQuoteStatusSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) => rateQuote,
  rateQuote =>
    of(rateQuote)
      .map(({ autoQuoteStatus }) =>
        autoQuoteStatus.reduce((acc, curr) => {
          if (acc.find(({ type }) => type === curr.type)) {
            return [...acc];
          } else {
            return [...acc, curr];
          }
        }, [] as AutoQuoteStatus[])
      )
      .orElse(() => [])
);

export const offerPermutationsSelector = createSelector(
  ({ rateQuote: { rateQuote } }: ApplicationState) =>
    rateQuote ? rateQuote.offerPermutations : [],
  offerDetailsSelector,
  productSetSelector,
  (permutations, allOffers, productSet) =>
    permutations.map(p => {
      const offers = allOffers.filter(offer => p.offerIds.includes(offer.offerId));
      const name = !toExclude({ loanType: 'Standard' }, productSet).loanType
        ? p.name + ' - ' + p.attributes.loanType
        : p.name;
      return {
        offers,
        attributes: formatAttributes(p.attributes, productSet),
        name
      };
    })
);

const offerTableRateQuoteSelector = createSelector(
  rateQuoteIdSelector,
  lastModifiedTimeSelector,
  merchandisingSelector,
  offerStatusSelector,
  autoQuoteStatusSelector,
  lockedOfferIdSelector,
  (
    rateQuoteId,
    { lastModifiedTime },
    merchandisedOffers,
    offerStatuses,
    autoQuoteStatus,
    lockedOfferId
  ) => ({
    rateQuoteId,
    lastModifiedTime,
    merchandisedOffers,
    offerStatuses,
    autoQuoteStatus,
    lockedOfferId
  })
);

export const OfferTable = () => {
  const dispatch = useDispatch();
  const {
    rateQuoteId,
    lastModifiedTime,
    merchandisedOffers,
    offerStatuses,
    autoQuoteStatus,
    lockedOfferId
  } = useSelector(offerTableRateQuoteSelector);
  const data = useSelector(offerDetailsSelector);
  const rateQuoteRequestId = useSelector(rateQuoteRequestIdSelector);
  const { isOpen } = useSelector(offerDetailsUISelector);
  const allOffers = useSelector(offersSelector);
  const offerPermutations = useSelector(offerPermutationsSelector);
  const dealIds = useSelector(dealIdsSelector);
  const rateQuoteRequest = useSelector(rateQuoteRequestSelector);
  const { requester } = useSelector(rateQuoteRequesterSelector);
  const disableOUTitle = useSelector(disableOUTitleSelector);
  const propertyState = rateQuoteRequest.map(rqr => rqr.property.state);
  const sourceId = rateQuoteRequest.map(rqr => rqr.sourceId);
  const getOffer = (offerId: string) =>
    Object.values(allOffers).find(offer => offer.offerId === offerId)!;
  const storeOffer = (offerId: string, payload: Partial<Offer>) => {
    dispatch(storeOfferAction(offerId, payload));
  };
  const editOffer = (offerId: string) => {
    dispatch(seedOfferAction(getOffer(offerId)));
  };
  const deleteOffer = (offerId: string) => {
    dispatch(unmerchandiseOfferAction(offerId));
  };
  const includeOffer = (offerId: string) => {
    dispatch(merchandiseOfferAction(offerId));
  };
  const getLockOffer = (offerId: string) => {
    dispatch(getLockRequest({ rateQuoteId, offer: getOffer(offerId) }));
  };

  const seedLock = (offerId: string) => {
    dispatch(
      seedLockModal({
        modalLock: {
          loanId: dealIds.length === 1 ? dealIds[0].value : '',
          rgTitle: disableOUTitle ? false : propertyState.value === 'CA' ? true : undefined,
          attorney: undefined,
          notes: '',
          lockId: '',
          float: false
        },
        offer: getOffer(offerId)
      })
    );
  };

  const publishOffers = (payload: string) => dispatch(publishRateQuoteRequest(payload));
  const previewOffers = (payload: string) => dispatch(previewRateQuoteRequest(payload));
  const submitOffers = (payload: { rateQuoteId: string; rateQuoteRequestId: string }) =>
    dispatch(submitRateQuoteRequest(payload));

  // Scroll to Top of Table only when an offer is successfully added
  const numOffers = data.length;

  const tableRef = useRef<HTMLDivElement>(null);
  const [numPrevOffers, setNumPrevOffers] = useState(data.length || 0);

  useLayoutEffect(() => {
    if (!isOpen && numOffers > numPrevOffers && tableRef && tableRef.current) {
      window.scrollTo(0, tableRef.current.offsetTop);
      setNumPrevOffers(numOffers);
    }
  }, [isOpen, numOffers, numPrevOffers]);

  const arrowDisplay = (length: number) => (length > 1 ? 'offers' : 'offer');
  const showLock = !lockedOfferId;
  const validLock = useMemo(
    () =>
      rateQuoteRequest
        .map(rqr => {
          if (isPurchaseRQR(rqr)) {
            return !!(rqr.closeDate && rqr.contingencyDate);
          }
          return true;
        })
        .orElse(() => true),
    [rateQuoteRequest]
  );

  return (
    <div ref={tableRef}>
      <OffersPanelHeader
        {...{
          offerStatuses,
          lastModifiedTime,
          rateQuoteId,
          rateQuoteRequestId,
          autoQuoteStatus,
          publishOffers,
          previewOffers,
          submitOffers,
          requester,
          borrowers: rateQuoteRequest.value?.borrowers,
          numOffers,
          sourceId: sourceId.value
        }}
      />
      <Conditional show={numOffers > 0}>
        <React.Fragment>
          {offerPermutations.map(({ name, offers, attributes }, i) => (
            <Accordion
              name={name}
              arrowDisplay={`${offers.length} ${arrowDisplay(offers.length)}`}
              fields={attributes}
              key={i}
            >
              <CheckableTable
                {...{
                  data: offers,
                  validLock,
                  showLock,
                  storeOffer,
                  editOffer,
                  deleteOffer,
                  includeOffer,
                  merchandisedOffers,
                  seedLock,
                  getLockOffer
                }}
              />
            </Accordion>
          ))}
        </React.Fragment>
      </Conditional>
    </div>
  );
};
