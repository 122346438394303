import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from '../../components/accordion';
import { AllQuotesTable } from '../../components/all-quotes-table';
import { ErrorModal } from '../../components/error-modal';
import {
  addOfferToRQ,
  getProductDetails,
  toggleSameOfferModal
} from '../../redux/all-quotes/slice';
import { seedOffer } from '../../redux/offer-details/actions';
import { allQuotesSelector, allQuotesTableSelector } from '../../selectors/all-quotes';
import { Filters } from './filter-section';

export const AllQuotes = ({}) => {
  const dispatch = useDispatch();
  const { result, loading, error, includeOfferLoading, isSameOfferModalOpen } = useSelector(
    allQuotesTableSelector
  );
  const AllQuotes = useSelector(allQuotesSelector);
  const getOffer = (offerId: string) => AllQuotes[offerId];
  const viewDetails = ({
    productId,
    searchId,
    offerId
  }: {
    productId?: number;
    searchId?: string;
    offerId: string;
  }) => {
    if (!!productId && !!searchId) {
      dispatch(getProductDetails({ productId, searchId }));
    }
    dispatch(seedOffer(getOffer(offerId), true));
  };
  const includeOffer = (offerId: string) => {
    dispatch(addOfferToRQ({ offerId }));
  };

  return (
    <>
      <ErrorModal
        isOpen={isSameOfferModalOpen}
        message="This offer is already included in the Rate Quote."
        onClose={() => dispatch(toggleSameOfferModal({ isOpen: false }))}
      />
      <Filters />
      {error}
      {loading && <div>loading all rates...</div>}
      {Object.entries(result).map(([productName, quotes], i) => (
        <Accordion name={productName} key={i} arrowDisplay={`${quotes.length} offers`}>
          <AllQuotesTable
            data={quotes}
            viewDetails={viewDetails}
            includeOffer={includeOffer}
            includeOfferLoading={includeOfferLoading}
          />
        </Accordion>
      ))}
    </>
  );
};
