import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../redux';
import { invalidDealsSelector } from '../../selectors/deals';

export const loanIdSelector = createSelector(
  ({ lockModal: { modalLock } }: ApplicationState) => modalLock,
  modalLock =>
    of(modalLock)
      .map(({ loanId }) => loanId)
      .orElse(() => '')
);

export const loanIdEmptySelector = createSelector(loanIdSelector, loanId => !loanId);
export const loanIdInvalidSelector = createSelector(
  loanIdSelector,
  invalidDealsSelector,
  (loanId, invalidDealIds) => invalidDealIds.map(({ dealId }) => dealId).includes(loanId)
);

export const loanIdInvalidDealStageSelector = createSelector(
  loanIdSelector,
  invalidDealsSelector,
  (loanId, invalidDealIds) => invalidDealIds.filter(({ dealId }) => dealId === loanId)[0]?.stage
);

export const loanIdErrorSelector = (state: ApplicationState) =>
  loanIdEmptySelector(state) || loanIdInvalidSelector(state);
