import Conditional from '@rategravity/frontend/components/conditional';
import { styled } from 'baseui';
import React from 'react';
import { ArmCaps } from '../../../modules/default-fees/types';
import { Computable, EditableOffer, Errorable } from '../../../redux/offer-details/state';
import { NumberInput } from '../../forms/input';
import { Select } from '../../forms/select';
import { Label1, Label2 } from '../../labels';
import { BaseCard } from './shared';

const MarginDiv = styled('div', {
  height: '0px'
});

interface NonInputFieldProps<T> {
  label: React.ReactNode;
  value: T;
}

export const NonInputField = <T,>({ label, value }: NonInputFieldProps<T>) => {
  return (
    <React.Fragment>
      <MarginDiv>
        <Label2>{label}</Label2>
        <Label1>{value}</Label1>
      </MarginDiv>
    </React.Fragment>
  );
};

export type OfferDetailsRateInfo = Errorable<{
  rate: number | null;
  price?: number;
}> &
  Computable<{
    rateLockDays: number | null;
    armCaps: ArmCaps;
    indexType?: 'UST' | 'LIBOR' | 'SOFR' | null;
    margin?: number | null;
  }> & { investor?: string | null; method: 'Automated' | 'Manual' | 'AllQuotes' };

interface RateInfoCardParams {
  data: OfferDetailsRateInfo;
  onChange?: (payload: Partial<EditableOffer['productDetails']>) => void;
  isFixed: boolean;
  updatePriceField?: (payload: { price: number | undefined }) => void;
}

const armToString = (arm: ArmCaps | null | undefined) =>
  arm ? `${arm.initial}/${arm.periodic}/${arm.lifetime}` : null;
const armToObject = (armString: string | null) => {
  if (typeof armString === 'string') {
    const [initial, periodic, lifetime] = armString.split('/', 3).map(v => parseInt(v));
    return { initial, periodic, lifetime };
  }
  return null;
};

export const RateInfoCard = ({
  onChange,
  data: { rate, rateLockDays, armCaps, indexType, margin, investor, method, price },
  isFixed,
  updatePriceField
}: RateInfoCardParams) => (
  <BaseCard title="Rate info">
    <NumberInput
      label="Rate"
      inputType="percent"
      onChange={({ value }) => onChange && onChange({ rate: value })}
      value={rate.value}
      error={rate.error}
      disabled={!onChange}
    />
    <Conditional show={!isFixed}>
      <Select<'UST' | 'LIBOR' | 'SOFR' | null>
        disabled={!onChange}
        label="Index Type"
        clearable={false}
        options={[
          { value: 'UST', label: 'UST' },
          { value: 'LIBOR', label: 'LIBOR' },
          { value: 'SOFR', label: 'SOFR' }
        ]}
        onChange={v => {
          onChange && onChange({ indexType: v });
        }}
        value={indexType?.manualValue ? indexType.manualValue : indexType?.computedValue}
        error={indexType?.error}
      />
    </Conditional>
    <Conditional show={!isFixed}>
      <NumberInput
        disabled={!onChange}
        label="Margin"
        inputType="percent"
        defaultValue={margin?.computedValue}
        onChange={({ value, recalculated }) =>
          onChange && onChange({ margin: recalculated ? null : value })
        }
        value={margin?.manualValue}
        error={margin?.error}
      />
    </Conditional>
    <NumberInput
      label="Rate Lock Period"
      onChange={({ value }) => {
        onChange && onChange({ manualRateLockDays: value });
      }}
      defaultValue={rateLockDays.computedValue}
      value={rateLockDays.manualValue}
      error={rateLockDays.error}
      disabled={method !== 'Manual'}
    />
    <Conditional show={!isFixed}>
      <Select<string | null>
        disabled={!onChange}
        label="ARM Caps"
        clearable={false}
        options={[
          { value: '2/2/5', label: '2/2/5' },
          { value: '2/2/6', label: '2/2/6' },
          { value: '2/1/5', label: '2/1/5' },
          { value: '5/2/5', label: '5/2/5' },
          { value: '5/1/5', label: '5/1/5' }
        ]}
        onChange={v => {
          onChange && onChange({ armCaps: armToObject(v) });
        }}
        value={
          armCaps.manualValue !== null
            ? armToString(armCaps.manualValue)
            : armToString(armCaps.computedValue)
        }
        error={armCaps.error}
      />
    </Conditional>
    <NumberInput
      label="Price"
      value={price?.value}
      disabled={method !== 'Manual'}
      onChange={({ value }) => updatePriceField && updatePriceField({ price: value })}
      error={price?.error}
    />
    <Conditional show={method !== 'Manual'}>
      <NonInputField label="Investor Name" value={investor || 'N/A'} />
    </Conditional>
  </BaseCard>
);
