import React, { useLayoutEffect } from 'react';

const addClass = (element: HTMLElement, className: string) => {
  const classes = (element.getAttribute('class') || '').split(' ');
  element.setAttribute('class', [...classes, className].join(' '));
};

const removeClass = (element: HTMLElement, className: string) => {
  const classes = (element.getAttribute('class') || '').split(' ');
  element.setAttribute('class', classes.filter(c => c !== className).join(' '));
};

export const StylableBody = ({
  className,
  children
}: React.PropsWithChildren<{ className?: string }>) => {
  useLayoutEffect(() => {
    if (className !== undefined) {
      addClass(document.body, className || '');
      return () => removeClass(document.body, className);
    }
  }, [className]);
  return <React.Fragment>{children}</React.Fragment>;
};
