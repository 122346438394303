import * as t from 'type-shift';
import {
  armCaps,
  ArmCaps,
  feeCalculationAttributes,
  FeeCalculationAttributes,
  FeeValue,
  indexRate,
  IndexRate
} from '../../default-fees/types';
import { loanType, LoanType } from '../../value-types/loan-type';
import {
  ClosingCostOption,
  closingCostOption,
  ClosingToCover,
  closingToCover,
  EscrowOptions,
  escrowOptions,
  InterestOptions,
  interestOptions
} from '../../value-types/payment-options';
import { product, Product } from '../../value-types/quote';
import { ExistingLoan, existingLoanConverter } from './existing-loans';

interface CommonOffer {
  offerId: string;
  starred: boolean;
  monthlyPayment: Partial<{
    mi: {
      readonly: boolean;
      value?: number;
      required: boolean;
    };
    pmi: number;
    pAndI: number;
  }>;
  productDetails:
    | {
        label: string;
        rate: number;
        manualRateLockDays: number | null;
        armCaps: ArmCaps | null;
        indexType: 'UST' | 'LIBOR' | 'SOFR' | null;
        margin: number | null;
        indexRate: number;
      }
    | {
        label: string;
        rate: number;
        manualRateLockDays: number | null;
      };
  computedProductDetails:
    | {
        rateLockDays: number | null;
        armCaps: ArmCaps | null;
        indexRate: IndexRate;
        apr: number;
        prepaidInterest: number;
        dti: number;
      }
    | { rateLockDays: number | null; prepaidInterest: number; apr: number; dti: number };
  lender: {
    id: string;
    name: string;
    url: string;
    nmls: string;
  };
  fees: Array<{
    calculationAttributes: FeeCalculationAttributes;
    name: string;
    id: string;
    manualValue: number | null;
    computedValue: FeeValue;
  }>;
  manualFees: Array<{ id: string; value: number }>;
  lastModifiedTime: number;
  method: 'Manual' | 'Automated' | 'AllQuotes';
  investor?: string | null;
  expired: boolean;
  locked: boolean;
  sellerConcessions?: number | null;
}

const commonOffer = t.strict<CommonOffer>({
  offerId: t.string,
  starred: t.boolean,
  monthlyPayment: t.partial(
    t.strict({
      mi: t.strict({
        readonly: t.boolean,
        value: t.optional(t.number),
        required: t.boolean
      }),
      pmi: t.optional(t.number),
      pAndI: t.optional(t.number)
    })
  ),
  productDetails: t.select((type: any) => {
    return type.armCaps !== undefined
      ? t.strict({
          label: t.string,
          rate: t.number,
          manualRateLockDays: t.number.or(t.null),
          armCaps: armCaps.or(t.null),
          indexType: t.oneOf(['UST', 'LIBOR', 'SOFR', null]),
          margin: t.number.or(t.null),
          indexRate: t.number
        })
      : t.strict({
          label: t.string,
          rate: t.number,
          manualRateLockDays: t.number.or(t.null)
        });
  }),
  computedProductDetails: t.select((type: any) => {
    return type.armCaps !== undefined
      ? t.strict({
          rateLockDays: t.number.or(t.null),
          armCaps: armCaps.or(t.null),
          indexRate,
          apr: t.number,
          prepaidInterest: t.number,
          dti: t.number
        })
      : t.strict({
          rateLockDays: t.number.or(t.null),
          apr: t.number,
          prepaidInterest: t.number,
          dti: t.number
        });
  }),
  lender: t.strict({
    id: t.string,
    name: t.string,
    url: t.string,
    nmls: t.string
  }),
  fees: t.array(
    t.strict({
      calculationAttributes: feeCalculationAttributes,
      name: t.string,
      id: t.string,
      manualValue: t.number.or(t.null),
      computedValue: t.number.or(t.oneOf(['NotIncluded', 'ManualEntry']))
    })
  ),
  manualFees: t.array(t.strict({ id: t.string, value: t.number })),
  lastModifiedTime: t.number,
  method: t.oneOf(['Manual', 'Automated', 'AllQuotes']),
  investor: t.string.or(t.null).or(t.undefined),
  expired: t.boolean,
  locked: t.boolean.default(() => false),
  sellerConcessions: t.optional(t.number.or(t.null))
});

export interface BaseAttributes {
  product: Product;
  lender: string;
  loanType: LoanType;
  helocLine: number;
  closingCostOption: ClosingCostOption;
  closingToCover: ClosingToCover;
  interestOptions: InterestOptions;
  escrowOptions: EscrowOptions;
  amtToFinance: number;
  loanSize: number;
  ltv: number;
  upFrontMip?: number;
  price?: number;
  conforming: boolean;
  aboveNationalBaseline: boolean;
}

export const baseAttributes = t.strict<BaseAttributes>({
  product,
  lender: t.string,
  loanType,
  helocLine: t.number,
  closingCostOption,
  closingToCover,
  interestOptions,
  escrowOptions,
  amtToFinance: t.number,
  loanSize: t.number,
  ltv: t.number,
  upFrontMip: t.optional(t.number),
  price: t.number.or(t.null.pipe(() => 100)).default(() => 100),
  conforming: t.boolean,
  aboveNationalBaseline: t.boolean
});

interface PurchaseOfferAttributes extends BaseAttributes {
  downPayment: number;
}
interface RefinanceOfferAttributes extends BaseAttributes {
  cashOut: number;
  totalCashOut: number;
  existingLoans: ExistingLoan[];
  irrrlEligible: boolean;
}
export interface PurchaseOffer extends CommonOffer {
  type: 'purchase';
  attributes: PurchaseOfferAttributes;
}

export const purchaseOffer = t.strict<PurchaseOffer>({
  ...commonOffer.converters,
  type: t.literal('purchase'),
  attributes: t.strict({
    ...baseAttributes.converters,
    downPayment: t.number
  })
});

export interface RefinanceOffer extends CommonOffer {
  type: 'refinance';
  attributes: RefinanceOfferAttributes;
}

export const refinanceOffer = t.strict<RefinanceOffer>({
  ...commonOffer.converters,
  type: t.literal('refinance'),
  attributes: t.strict({
    ...baseAttributes.converters,
    cashOut: t.number,
    totalCashOut: t.number,
    existingLoans: t.array(existingLoanConverter),
    irrrlEligible: t.boolean
  })
});

export type Offer = PurchaseOffer | RefinanceOffer;
export type OfferAttributes = PurchaseOfferAttributes | RefinanceOfferAttributes;
export type Fees = Offer['fees'];
