import * as t from 'type-shift';

export interface PrimaryLoan {
  existingLoanType: 'Primary';
  amount: number;
  rolledIntoRefi: boolean;
  payoffAsCashOut: boolean;
  id: string;
}

interface SecondaryLoan {
  existingLoanType: 'Secondary';
  amount: number;
  rolledIntoRefi: boolean;
  monthlyPayment: number;
  concurrentPurchase: boolean;
  id: string;
  payoffAsCashOut: boolean;
}

interface HELOCLoan {
  existingLoanType: 'HELOC';
  lineAmount: number;
  drawnAmount: number;
  rolledIntoRefi: boolean;
  monthlyPayment: number;
  concurrentPurchase: boolean;
  lastDraw: number | null;
  id: string;
  payoffAsCashOut: boolean;
  closed: boolean;
  amount: number;
}

interface OtherLoan {
  existingLoanType: 'Other';
  amount: number;
  rolledIntoRefi: boolean;
  id: string;
  payoffAsCashOut: boolean;
  monthlyPayment: number;
  concurrentPurchase: boolean;
}

export type ExistingLoan = PrimaryLoan | SecondaryLoan | HELOCLoan | OtherLoan;

export const existingLoanConverter = t.taggedUnion<ExistingLoan>('existingLoanType', {
  Primary: t.shape<PrimaryLoan>({
    id: t.string,
    amount: t.number,
    rolledIntoRefi: t.boolean,
    existingLoanType: t.literal('Primary'),
    payoffAsCashOut: t.boolean.or(t.undefined.pipe(() => false))
  }),
  Secondary: t.shape<SecondaryLoan>({
    id: t.string,
    amount: t.number,
    rolledIntoRefi: t.boolean,
    existingLoanType: t.literal('Secondary'),
    monthlyPayment: t.number,
    concurrentPurchase: t.boolean,
    payoffAsCashOut: t.boolean.or(t.undefined.pipe(() => false))
  }),
  HELOC: t.shape<HELOCLoan>({
    id: t.string,
    rolledIntoRefi: t.boolean,
    existingLoanType: t.literal('HELOC'),
    closed: t.boolean,
    lineAmount: t.number,
    drawnAmount: t.number,
    monthlyPayment: t.number,
    concurrentPurchase: t.boolean,
    lastDraw: t.number.or(t.null),
    payoffAsCashOut: t.boolean.or(t.undefined.pipe(() => false)),
    amount: t.number
  }),
  Other: t.shape<OtherLoan>({
    id: t.string,
    amount: t.number,
    rolledIntoRefi: t.boolean,
    existingLoanType: t.literal('Other'),
    monthlyPayment: t.number,
    concurrentPurchase: t.boolean,
    payoffAsCashOut: t.boolean.or(t.undefined.pipe(() => false))
  })
});
