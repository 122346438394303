import { Advisors } from './state';

export const FetchAdvisorsSuccessActionType = 'FETCH_ADVISORS_SUCCESS_ACTION' as const;
export const fetchAdvisorsSuccess = (payload: Advisors) => ({
  type: FetchAdvisorsSuccessActionType,
  payload
});
export type FetchAdvisorsSuccessAction = ReturnType<typeof fetchAdvisorsSuccess>;

export const FetchAdvisorsFailureActionType = 'FETCH_ADIVSORS_FAILURE_ACTION' as const;
export const fetchAdvisorsFailure = (error: string) => ({
  type: FetchAdvisorsFailureActionType,
  error
});
export type FetchAdvisorsFailureAction = ReturnType<typeof fetchAdvisorsFailure>;
