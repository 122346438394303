"use strict";
// Optionals
// Optionals present an "array like" interface (map, filter, find, some) however
// they effectively have either 1 (Some) or 0 (None) elements.
// Optionals are highly useful to express the return type of functions that may not return.
// Specifically search / lookup operations work great with Optionals.
// The 2 advantages they have over using a type union with undefined is that.
// A) You don't need to check if a value is undefined when chaining operations.
// B) You can express a successful operation that returns undefined vs. a failure
// Combinators (all, coalesce) are provided to make it easier to work with Optionals.
// lazy constructors can be used to create Optionals that only evaluate when their data is needed.
// especially in combination with coalesce this allows defining an order of
// functions that should be executed to fetch a value. eg:
// coalesce([lazy(() => store.getA()), lazy(() => store.getB())]).map(v => ....);
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Check if the value is an Optional
 * @param value anything
 */
exports.isOptional = (value) => value != null &&
    typeof value === 'object' &&
    'value' in value &&
    'isSome' in value &&
    'orElse' in value &&
    'run' in value &&
    'some' in value &&
    'filter' in value &&
    'map' in value &&
    'flatMap' in value &&
    'flat' in value &&
    'find' in value;
/**
 * Given a value return a Some capturing that value.
 * If the value is undefined the result will be a Some<undefined>
 */
exports.some = (value) => Object.defineProperties({
    some: (predicate = () => true) => predicate(value),
    filter: (predicate) => (predicate(value) ? exports.some(value) : exports.none()),
    map: (selector) => exports.some(selector(value)),
    flatMap: (selector) => selector(value),
    flat: (depth = 1) => {
        let r = exports.some(value);
        for (let i = 0; i < depth; i++) {
            // none is always none
            if (!r.isSome()) {
                break;
            }
            // grab the value inside the some
            const v = r.find();
            // check if the value is an optional
            if (exports.isOptional(v)) {
                r = v;
            }
            else {
                // no further flattening
                break;
            }
        }
        return r;
    },
    orElse: (_) => value,
    find: (predicate = () => true) => (predicate(value) ? value : undefined),
    run: (code) => {
        code(value);
    },
    isSome: () => true,
    toString: () => `Some[${String(value)}]`
}, {
    value: {
        configurable: false,
        enumerable: true,
        value,
        writable: false
    }
});
// constant NONE since the behavior isn't type specific
const NONE = Object.defineProperties({
    some: () => false,
    filter: () => NONE,
    map: (_) => NONE,
    flatMap: (_) => NONE,
    flat: () => exports.none(),
    orElse: (defaultSelector) => defaultSelector(),
    find: () => undefined,
    run: () => {
        // noop
    },
    isSome: () => false,
    toString: () => 'None'
}, {
    value: {
        configurable: false,
        enumerable: true,
        value: undefined,
        writable: false
    }
});
/**
 * create a None, the function
 * returns a cached constant but is used
 * to enable the type of the Optional to be specified
 */
exports.none = () => NONE;
/**
 * Given an array of Optionals return an Optional of an array.
 * The returned optional will be Some if all the optionals are Some
 * The returned optional will be None if any of the optionals are None
 */
exports.all = ((options) => options.every(o => o.isSome())
    ? exports.some(options.map(o => o.find()))
    : exports.none()); // this doesn't typecheck cleanly but it's fine...
/**
 * Given an array of Optionals return a Optional with the first
 * present value. If all of the optionals are None then the returned value will be None
 * Otherwise the returned value will be the first Some
 */
exports.coalesce = (options) => options.find(o => o.isSome()) || exports.none();
exports.coallesce = exports.coalesce;
/**
 * Given a value that is potentially null or undefined return an Optional
 * @param value A value, possibly null or undefined
 * The returned Optional will be None if the value is null or undefined otherwise it will be Some
 */
exports.of = (value) => value === undefined || value === null ? exports.none() : exports.some(value);
/**
 * Given a generator function create an optional
 * @param generator A function to generate an Optional
 * The returned Optional chains operations until a terminal operation is invoked
 * then invokes the generator once to determine the result.
 * A Terminal operation is anything that doesn't return an Optional.
 */
exports.lazy = (generator) => {
    let cachingGenerator = () => {
        const v = generator();
        cachingGenerator = () => v;
        return v;
    };
    return Object.defineProperties({
        some: (predicate) => cachingGenerator().some(predicate),
        filter: (predicate) => exports.lazy(() => cachingGenerator().filter(predicate)),
        map: (selector) => exports.lazy(() => cachingGenerator().map(selector)),
        flatMap: (selector) => exports.lazy(() => cachingGenerator().flatMap(selector)),
        flat: (depth) => exports.lazy(() => cachingGenerator().flat(depth)),
        orElse: (defaultSelector) => cachingGenerator().orElse(defaultSelector),
        find: (predicate) => cachingGenerator().find(predicate),
        run: (code) => cachingGenerator().run(code),
        isSome: () => cachingGenerator().isSome(),
        toString: () => cachingGenerator().toString()
    }, {
        value: {
            configurable: false,
            enumerable: true,
            get: () => cachingGenerator().value
        }
    });
};
/**
 * Given a generator that may return null or undefined returns
 * a lazy Optional where the value will be None if the generator returned null or undefined.
 */
exports.lazyOf = (generator) => exports.lazy(() => exports.of(generator()));
