"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const loan_1 = require("./loan");
const math_1 = require("./math");
function loanValue(params) {
    const { upMip = 0, amtToFinance = 0 } = params;
    if ('cashOut' in params) {
        return params.remainingLoanBalance + params.cashOut + upMip + amtToFinance;
    }
    else {
        return params.purchasePrice + upMip + amtToFinance - params.downPayment;
    }
}
exports.loanValue = loanValue;
function payments(params) {
    return math_1.monthlyPMT(params.rate, params.loanTerm, loanValue(params));
}
exports.payments = payments;
function futureValue(params) {
    return math_1.monthlyFV(params.rate, params.pAndI, loanValue(params), params.loanTerm - params.remainingTerm);
}
exports.futureValue = futureValue;
function ltv(balance, loan) {
    return balance / (loan_1.isRefinance(loan) ? loan.propertyValue : loan.purchasePrice);
}
exports.ltv = ltv;
