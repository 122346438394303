import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../../redux';

export const offerDataSelector = createSelector(
  ({ lockModal: { offer } }: ApplicationState) => offer,
  offer =>
    of(offer)
      .map(({ offerId, product, rate, locked, lender, fees }) => ({
        offerId,
        product,
        rate,
        locked,
        fullLender: lender,
        lenderCredit: fees
          .filter(({ id }) => id === 'lenderCredit')
          .reduce(
            (acc, { computedValue, manualValue }) =>
              manualValue != null
                ? manualValue + acc
                : typeof computedValue === 'number'
                ? computedValue + acc
                : acc,
            0
          ),
        points: fees
          .filter(({ id }) => id === 'points')
          .reduce(
            (acc, { computedValue, manualValue }) =>
              manualValue != null
                ? manualValue + acc
                : typeof computedValue === 'number'
                ? computedValue + acc
                : acc,
            0
          )
      }))
      .orElse(() => ({
        offerId: '',
        product: {
          rate: 'fixed',
          term: 30
        },
        fullLender: {
          id: '',
          url: '',
          name: '',
          nmls: ''
        },
        locked: false,
        lenderCredit: 0,
        points: 0,
        rate: 0
      }))
);
