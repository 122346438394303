import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../redux';

export const linkModalStateSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ isOpen }) => isOpen)
      .orElse(() => false)
);

export const linkSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ link }) => link)
      .orElse(() => null)
);

export const linkModalTitleSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ title }) => title)
      .orElse(() => null)
);

export const linkModalMessageSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ message }) => message)
      .orElse(() => null)
);

export const linkModalLoadingSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ loading }) => loading)
      .orElse(() => false)
);

export const linkModalNetworkErrorSelector = createSelector(
  ({ linkModal }: ApplicationState) => linkModal,
  linkModal =>
    of(linkModal)
      .map(({ networkError }) => networkError)
      .orElse(() => null)
);
