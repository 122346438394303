import { Optional } from '@rategravity/core-lib/optional';
import { RateQuoteRequest } from '../rate-quote/types';
import { LoanType } from '../value-types/loan-type';

export const toShowPMI = (
  loanType: LoanType | undefined,
  loanSize: number,
  rqr: Optional<RateQuoteRequest>
) => {
  switch (loanType) {
    case 'VA':
      return false;
    case 'FHA':
    case 'FHA 203k':
      return true;
    default:
      return rqr
        .map(
          value =>
            loanSize / ('purchasePrice' in value ? value.purchasePrice : value.propertyValue) > 0.8
        )
        .orElse(() => false);
  }
};
